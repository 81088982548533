import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, Modal } from 'semantic-ui-react'
import restUtils from '../../utils/restUtils';
import { GET_SSO_GUID, GET_APP_LISTINFO, GET_SSO_GUID_PARTSCRIPTION, GET_PARTSCRIPTION_REDIRECT, GET_PARTSCRIPTION_AUTH_REDIRECT } from '../../urlConstants';
import { setBussinessToolMenu} from '../../components/NavBar/actions'
 import {switchBusinessTool} from '../../views/Login/actions'
import { utilities } from '../../utils/utilities';
import { bindActionCreators } from 'redux';
import history from '../../history';
import AuthLoader from './AuthLoader'
import _ from 'lodash';

class BusinessTools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isAuthenticationFail: false,
      searchVal: this.props.location?.search ? this.props.location?.search : null,
      bussinessToolMenu: props.businessToolsMenu,
      hasAccessPopUp : false,
      showTvDialog: false
    }
  }

  componentDidMount() {
   if(this.state.searchVal) {
        if(_.isEmpty(this.state.bussinessToolMenu)) {
          restUtils.postDataWithBearer(GET_APP_LISTINFO).then(res=>{
            const data = res.data;
            this.redirectComponent(data)
           this.props.setBussinessToolMenu(data)
         }).catch(err=>{
           console.log(err)
         })
        } 
        else {
          this.redirectComponent(this.state.bussinessToolMenu)
        }
      }
     
  }
  
  componentWillReceiveProps(nextProps) {
    this.setState({
      bussinessToolMenu: nextProps.businessToolsMenu
    })
  }

  redirectComponent = (data) => {
    let searchData = this.state.searchVal;
    let queryParam = '';
    // we check if any query params exists or not and assign the respective values
    if(this.state.searchVal.includes('&') && searchData.split('=').length > 2){
      searchData = this.state.searchVal.split('&')[0];
      queryParam = this.state.searchVal.split('&')[1];
    }
    const keyName = searchData.split('=');
    let appName = []
    if(keyName[0]==='?appName') {
      appName = _.filter(data, (ele=>{
        return ele.Name === decodeURIComponent(keyName[1])
      }))
    }else {
      appName = _.filter(data, (ele=>{
        return ele.ComponentGuid === keyName[1] // Redirect based on app Guid value
    }))
    }
    // partScr conditions are to handle partscription flow
    if(_.isEmpty(appName) && keyName[1] !== 'partScr') {
      this.setState({
        showTvDialog: true,
        hasAccessPopUp: true,
        loading: false
      })
      return
    }
    const  requestPayLoad = keyName[1] === 'partScr' ? {} : {
      appGuid: appName[0].ComponentGuid
    }
    const requestUrl = keyName[1] === 'partScr' ? GET_SSO_GUID_PARTSCRIPTION : GET_SSO_GUID;
    this.setState({ loading: true });
    if(keyName[1] !== 'partScr' && !_.includes(appName[0].Parameters, '{securityGuid}')) {
      const url = `${appName[0].RedirectUrl}${appName[0].Parameters}`;
      this.props.switchBusinessTool(url)
      return
    }
    restUtils
      .postDataWithBearer(requestUrl, requestPayLoad)
      .then(response => {
       if(response && response.data){
            let url = '';
            if(keyName[1] === 'partScr'){
              url = `${GET_PARTSCRIPTION_AUTH_REDIRECT}?truevalue=${this.props.storeId}&storeauthtoken=${response.data}`;
              if(response.data === '00000000-0000-0000-0000-000000000000'){
                url = GET_PARTSCRIPTION_REDIRECT;
              }
            } else {
              const processUrl = appName[0].Parameters.replace(/{securityGuid}/g, response.data)
              url = `${appName[0].RedirectUrl}${processUrl}`;
              // We add this to pass the query params if any
              if(queryParam){
                url = url + `&${queryParam}`
              }
              if(!appName[0].HasAccess) {
                if(appName[0].Description === 'True Value University'){
                  this.setState({
                    showTvDialog: true
                  })
                }
                this.setState({
                  hasAccessPopUp: true,
                  loading: false
                })
                return
              }
            }
            this.props.switchBusinessTool(url);
        } else {
          this.setState({ 
            isAuthenticationFail: true
          });
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({ 
          loading: false,
          isAuthenticationFail: true
        });
      });
  }
  closePopUp = () =>{
    this.setState({
      hasAccessPopUp: false
    })
    history.push('/home');
  }
  render() {
    return (
      <div >
        
        <Modal closeIcon
         className='changeStorePopup'
         onClose = {this.closePopUp}
         closeOnDimmerClick={false}
            open={this.state.hasAccessPopUp}>
             <Modal.Content className=''>
              { !this.state.showTvDialog && <>
                <p> You currently do not have permission to access this business tool and/or site.</p>
                <p>If you believe you should have access, please contact your Store Manager. For all other inquires please contact the Member Help Desk at 800-621-6025 option 3 or 
                <a href = "mailto: membersonlinehelp@truevalue.com"> membersonlinehelp@truevalue.com</a>.</p>
                </>
              }
              { this.state.showTvDialog && <>
                <p>{`TVU has become a fee-based subscription service. In order to access TVU online you need to opt-in. To opt-in go to Store Profiles & Access-->Store-profile. For additional help contact the Member Help Desk at 800-621-6025 option 3 or `}
                <a href = "mailto: membersonlinehelp@truevalue.com"> membersonlinehelp@truevalue.com</a>.</p>
                </>
              }
            </Modal.Content>
          </Modal>
        {this.state.loading &&
          <div>
          <AuthLoader />
          </div>
        }
        { this.state.isAuthenticationFail &&
          <p style={{textAlign:'center'}}>Unable to Authenticate</p>
          }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    storeId: state.SessionReducer.storeId,
    userguid: state.SessionReducer.userguid,
    storeguid: state.SessionReducer.storeguid,
    businessToolsMenu:state.navbar.businessToolsMenu,

  };
}
const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({setBussinessToolMenu, switchBusinessTool }, dispatch)
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(BusinessTools);