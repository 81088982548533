import React, { Component } from 'react';
import renderImages from '../common/RenderImages';
import { Link } from 'react-router-dom';
import { Responsive,Loader, Popup, Button} from 'semantic-ui-react';
import history from '../../history';
import './styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from 'react-dialog';
import Bin from '../../images/bin.svg';
import { addToFavorites, removeFromFavorites } from '../Favorites/actions';
import { updateQuantity, deleteCart, checkout, updateOr, notifyCarts,updateCloseOutCornerQuantity,setCurrentCart } from './actions';
import { restUtils } from '../../utils/restUtils';
import { CHECKOUT_VALIDATION } from '../../urlConstants';
import utilities, { shouldHideSUGG_RETAILForPreference, shouldHideCostForPreference,shouldHidePriceTotalPreference } from '../../utils/utilities';
import { TierIcon } from '../common/TierIcon';
import { quantityValidator, validateCartProductQuantity, closeModal } from '../common/productHandler';
import { MaskPrice } from '../common/MaskPrice';
import {getRaCategories} from '../../views/RetailAssortment/actions';
import { FLASH_SALES } from '../common/constants';
import { orderNames, isShoppingAreaCart, shoppingAreaFlowTypes } from './constants';
import { getHeaders, getRow } from '../ReunionEvent/AllReunion/viewCartFields';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import ExcelExport from "../common/ExcelExport";
import QuickViewDetails from '../common/QuickViewDetails';
import { exportExcel } from "../common/ComponentUtilities";
import no_img_avlbl from "../images/image_not_found.jfif";
import { getExcelExport, cartExcelExport } from "../ReunionEvent/AllReunion/viewCartFields";
import _, { flow } from 'lodash';
import { getRAMessage,findTotal, getPack, getDescription,getRedeemedModelNbr, getTierLevel, getTieredCost, getTieredCostNew, getTierLevelNew, checkCreditRedeemedByRefNum,  getAssortmentSavings, getAssortmentCreditValue, showOfferendDateMsg, totalCost } from './cartUtils';
import { getImageLink, isMaxQtyValid, getPdpParams } from '../ReunionEvent/AllReunion/itemListingUtilities';
import { REUNIONORDERING, EVENTS, EVENT_NAME, TRUE, ASSORTMENTS } from '../common/constants';

const RDCTYPE_H = 'H';
const HUB = 'HUB';
const WAREHOUSETYPE_S = 'S';
class CartProductsTable extends Component {
  constructor(props) {
    super(props);
    this.closeModal = closeModal.bind(this);
    const { cart } = this.props;
    const isCheckout = this.props.isCheckout ? this.props.isCheckout : false;
    const products = isCheckout ? this.props.products : cart.items;
    const isConfirmation = this.props.isConfirmation
                            ? this.props.isConfirmation
                            : false;
    const isRetailAssortment = cart.TypeId === 4;
    const isCtbAssortment = cart.TypeId === 44;
    const isEvents = cart.TypeId === 9;
    const isPlanogram = cart.TypeId === 5;
    const isIM = cart.TypeId === 7;
    const isMonthlySpecial = cart.TypeId === 10;
    const isDOM = cart.TypeId === orderNames["DOM"].infoType;
    const tableType = isCheckout
                  ? isRetailAssortment
                    ? 'ra-checkout'
                    : isCtbAssortment
                    ? 'ctb-checkout'
                    : isEvents || isMonthlySpecial
                    ? 'event-checkout'
                    : isPlanogram
                    ? 'pl-checkout'
                    : isIM
                    ? 'im-checkout'
                    : 'checkout'
                  : isConfirmation
                  ? isRetailAssortment
                    ? 'ra-confirmation'
                    : isCtbAssortment
                    ? 'ctb-confirmation'
                    : isMonthlySpecial
                    ? 'ms-confirmation'
                    : isDOM
                    ? 'dom-confirmation'
                    : 'confirmation'
                  : isRetailAssortment
                  ? 'ra-cart'
                  : isCtbAssortment 
                  ?  'ctb-cart'
                  : isIM
                  ? 'im-cart'
                  : isEvents || isMonthlySpecial
                  ? 'event-cart'
                  : 'viewCart';
    this.tierLevels = {
      1: false,
      2: false,
      3: false,
      4: false
    };

    const { cartInfoName } = cart;
    const isReunionShoppingAreaCart  = isShoppingAreaCart(cartInfoName);
    const flowType = shoppingAreaFlowTypes?.[cartInfoName];
    const shoppingAreaName = cart.shoppingAreaName;
    const shoppingAreaMetaId = cart.shoppingAreaMetaId;
    const isQtyEditable =
      ['viewCart','im-cart', 'event-cart', 'ra-cart', 'ctb-cart'].indexOf(tableType) !== -1;
    let quantities = {};
    let overrideretails = {};
    if (isQtyEditable) {
      if (isEvents || isMonthlySpecial || (flowType && (flowType===1 || flowType===3|| flowType===4 || flowType===44))) {
        products && products.forEach(product => {
          const key = `${product.item_nbr}-${product.rdcNum || product.rdcNum !== ""? product.rdcNum:this.props.rdc}${flowType===4 || flowType===44?'-'+product.model:''}`;
          quantities = {
            ...quantities,
            [key]: [
              parseInt(product.userQty|| product.itemQty),
              parseInt(product.userQty2 || product.itemQty1),
              parseInt(product.userQty3 || product.itemQty2),
              parseInt(product.userQty4 || product.itemQty3)
            ]
          };
          overrideretails = {
            ...overrideretails,
            [key]: utilities.emptyStringForZero(
              product.overrideRetail
            )
          };
        });
      } else if (isRetailAssortment || isCtbAssortment || (flowType && flowType ===2)) {
        products && products.forEach(product => {
          let number = product.number?product.number:product.item_nbr;
          quantities = 
         isReunionShoppingAreaCart ?
          {...quantities, [`${number}-${product.month?product.month:product.rdcNum}`]:parseInt(product.userQty?product.userQty:product.itemQty)}
          :
          {...quantities, [`${number}-${product.rdcNum}`]:parseInt(product.userQty?product.userQty:product.itemQty)}
           
          overrideretails = {
            ...overrideretails,
            [number]: utilities.emptyStringForZero(
              product.overrideRetail
            )
          };
        });
      } else if (flowType && flowType === 5){
        products && products.forEach(product => {
          quantities = 
          {...quantities, 
            [`${product.item_nbr}-${product.month?product.month:product.rdcNum}`]
            :parseInt(product.userQty?product.userQty:product.itemQty)};
          }
        );
      }else if(cart.TypeId === 33) {
        products && products.forEach(product => {
          quantities = {
            ...quantities,
            [`${product.item_nbr}`]: parseInt(product.itemQty)
          };
        });
      }
      else {
        products && products.forEach(product => {
          quantities = {
            ...quantities,
            [`${product.item_nbr}-${product.rdcNum}`]: parseInt(product.itemQty)
          };
          overrideretails = {
            ...overrideretails,
            [`${product.item_nbr}-${product.rdcNum}`]: utilities.emptyStringForZero(
              product.overrideRetail
            )
          };
        });
      }
    }
    this.state = {
      products,
      isLoading:false,
      visible: false,
      quickViewItem: '',
      tableType,
      isRetailAssortment,
      isCtbAssortment,
      isEvents,
      quantities,
      overrideretails,
      isReunionShoppingAreaCart,
      shoppingAreaName,
      shoppingAreaMetaId,
      flowType,
      errorList : false,
      isMaxQty: this.props.cart?.items?.length > 0 && this.props.cart.items.some(ele => parseInt(ele.maxQtyItemRestriction) > 0),
      itemMeassage: {},
      message:{}
    };
    this.redemmedModelNbr = '';
    this.itemRef = {};
    this.messageIndex = 0
    this.errorQty = []
    this.message = {}
  }
  componentDidMount() {
    this.props.products.map((product, index) => {
      if (!product) return null; 
      const id = product.item_nbr !== 0 ? product.item_nbr: product.number !==0 ?product.number : product.model
      this.message = {
        ...this.message,
        [id]:[]
      }
    })  
    /*if(this.state.tableType === 'viewCart' && this.props?.qtyFocus) {
      const items = this.props.products[0].item_nbr !== 0 ? this.props.products[0].item_nbr: this.props.products[0].number;
      const itemPos = `${this.props.cart.cartId+''+items+''+0}`
      console.log(itemPos)
      if(document.getElementById('qtyBox'+itemPos)) {
        document.getElementById('qtyBox'+itemPos).focus()
      }
    }*/
  }
  handleErrorMessage = (shipIndex, items, boxPos, message,value,fieldName) =>{
    this.props.updateQuantities(this.props.cart.cartId, items,value,fieldName,this.props.cart.TypeId)
    if(!isNaN(shipIndex)) {
      this.message[items].splice(shipIndex,1, message)
    }
    else {
      this.message[items]= [message]
    }
    this.messageIndex = boxPos
  }
  handleQtyChange = (
    e,
    product,
    index,
    shipIndex,
    relay_nbr,
    vendorId,
    otherQties
  ) => {
    const { value } = e.target;
    const { cart } = this.props;
    const { isEvents, tableType, flowType, isReunionShoppingAreaCart } = this.state;
    const items = product.item_nbr !== 0 ? product.item_nbr: product.number !==0 ?product.number : product.model
    const boxPos = shipIndex ? shipIndex : 0 
    const id = this.props.cart.cartId
    const itemPos = `${this.props.cart.cartId+''+items+''+boxPos}`
    let errorStyle = 'border-color:red;color:red;border-width:2px'
    let highlightError = false;
    if(!isNaN(shipIndex) && !this.message[items]?.length >0) {
      this.message[items] = ['','','','']
    }
    if (!quantityValidator(value, true)) {
      return false;
    }
    this.errorQty.push(itemPos)
    let fieldName = shipIndex
    ? shipIndex === 0
      ? 'ItemQty'
      : `ItemQty${shipIndex + 1}`
    : 'ItemQty';
    if(cart.TypeId === 3){
      if(e.target.value > product.Available){
        utilities.showToast("Quantity should be less than the available",false);
        return false;
      }
    }
    if (isReunionShoppingAreaCart && flowType === 5) {
      this.setState(prevState =>{
        let quantities = prevState.quantities;
        quantities[`${product.item_nbr ? product.item_nbr : product.number}-${product.month?product.month:product.rdcNum}`] = value;
        return { ...prevState, quantities };
      });
      if((value === '' || parseInt(value) === 0)){
       // return;
      }
    }else if(cart.TypeId === 33) {
      this.setState(prevState => {
        let quantities = prevState.quantities;
        quantities[`${product.item_nbr}`] = value;
        return { ...prevState, quantities };
      });
    }
    else if (!isEvents && tableType !== 'event-cart'&& (shipIndex === undefined || shipIndex === null)) {
      this.setState(prevState => {
        let quantities = prevState.quantities;
        tableType === 'ra-cart' && isReunionShoppingAreaCart ?
        quantities[`${product.item_nbr ? product.item_nbr : product.number}-${product.month?product.month:product.rdcNum}`] = value
        :
        quantities[`${product.item_nbr ? product.item_nbr : product.number}-${product.month?product.month:product.rdcNum}`] = value
        return { ...prevState, quantities };
      });
      if(!isReunionShoppingAreaCart && (value === '' || parseInt(value) === 0)){
        e.target.style=errorStyle
        this.props.updateQuantities(this.props.cart.cartId,items,e.target.value,fieldName,cart.TypeId)
        this.message[items]= [`Enter a valid quantity`]
        this.messageIndex = 0         
        return;
      }
    } 
    else if (tableType === 'event-cart' || (isReunionShoppingAreaCart && (flowType===1 || flowType === 4)) ) {
      this.setState(prevState => {
        let quantities = prevState.quantities;
        quantities[`${product.item_nbr ? product.item_nbr : product.number}-${product.rdcNum || product.rdcNum != "" ? product.rdcNum : this.props.rdc}${flowType===4 || flowType===44 ?'-'+product.model:''}`][shipIndex] = value;
        return { ...prevState, quantities };
      });
      if((value === '' || parseInt(value) === 0)){
        //return;
      }
    } else {
      this.setState(prevState => {
        let quantities = prevState.quantities;
        quantities[`${product.item_nbr}-${product.rdcNum}`][shipIndex] = value;
        return { ...prevState, quantities };
      });
    }

    if ((parseInt(value) !== 888 && product.s_pack_type === 'F' && value % product.s_pack_qty !==0) ) {
      e.target.style=errorStyle
      this.handleErrorMessage(shipIndex,items,boxPos,`Quantity should be a multiple of  ${product.s_pack_qty}`,e.target.value,fieldName)
      // Donot update if full pack validation is not succesfull
      highlightError = true
      //return true;
    } 
    else {
      this.message[items] = [];
    }
    if (!isReunionShoppingAreaCart && parseInt(value) === 888 ) {
      e.target.style=errorStyle
      this.handleErrorMessage(shipIndex,items,boxPos,`Bin and Price Ticket Request`)
      // Donot update if full pack validation is not succesfull
      highlightError = true
      //return true;
    }    
    if((tableType === 'event-cart' || isReunionShoppingAreaCart && (flowType === 1 || flowType === 4 || flowType === 3 )) && (value === '' || parseInt(value) === 0)) {
      this.props.updateQuantities(this.props.cart.cartId, items,e.target.value,fieldName,cart.TypeId)
      const qtyList = [product.userQty, product.userQty2, product.userQty3, product.userQty4]
      qtyList.splice(shipIndex,1, 0)
      const tolalQty = _.reduce(qtyList,(total,n)=>{
        return total + n
      })
      if(tolalQty === 0) {      
        this.message[items]= [`Enter a valid quantity`]
        this.messageIndex = 0    
        for(let i=0; i <=3 ; i++) {
          const itemPos = `${this.props.cart.cartId+''+items+''+i}`
          const qtyBox =document.getElementById('qtyBox'+itemPos)
          if(qtyBox) {
            qtyBox.style=errorStyle
          }
        } 
        highlightError = true
      }
    } 
    if(isReunionShoppingAreaCart && (flowType === 2 || flowType === 5 ) && (value === '' || parseInt(value) === 0)) {
      const getItems = this.props.cart.items.filter(item=> item.model === product.model).find(item=> item.month !== product.month)
      if(getItems) {
        if(parseInt(getItems.userQty) === 0) {
          const assortmentQty = document.querySelectorAll('.qtyBox'+`${id+''+items+''+0}`)
          assortmentQty.forEach((ele, index)=>{
          ele.style = errorStyle
          })
          this.message[items]= [`Enter a valid quantity`]
          highlightError = true
        }
      }
      else {
        e.target.style=errorStyle
        this.message[items]= [`Enter a valid quantity`]
        this.messageIndex = 0
        highlightError = true
      }
    }
    if(product.maxQtyItemRestriction && product.maxQtyItemRestriction > 0) {
      if(!highlightError && (parseInt(this.props.cart.shoppingAreaMetaId) !== 2 && parseInt(this.props.cart.shoppingAreaMetaId) !== 6 && parseInt(this.props.cart.shoppingAreaMetaId) !== 8) && !isMaxQtyValid(value,product.maxQtyItemRestriction,product.isMultiples,false)) {
        const message = product.isMultiples ? `Quantity should be a multiple of  ${product.maxQtyItemRestriction}` :`Maximum Order quantity is  ${product.maxQtyItemRestriction}`
        this.handleErrorMessage(shipIndex,items,boxPos,message,e.target.value,fieldName)
        e.target.style=errorStyle
        highlightError = true 
        //return
      }
      if(!highlightError &&(parseInt(this.props.cart.shoppingAreaMetaId) === 2 || parseInt(this.props.cart.shoppingAreaMetaId) === 6 || parseInt(this.props.cart.shoppingAreaMetaId) === 8) && product?.maxQtyPerReunion && product?.maxQtyPerReunion > 0 && !isMaxQtyValid(value,product?.maxQtyPerReunion,false,false)) {
        this.handleErrorMessage(shipIndex,items,boxPos,`Maximum Order quantity is  ${product.maxQtyPerReunion}`,e.target.value,fieldName)
        e.target.style=errorStyle
        highlightError = true
        //return;
      }
      if(!highlightError &&(parseInt(this.props.cart.shoppingAreaMetaId) === 2 || parseInt(this.props.cart.shoppingAreaMetaId) === 6 || parseInt(this.props.cart.shoppingAreaMetaId) === 8) &&!isMaxQtyValid(value,product.maxQtyItemRestriction,product.isMultiples,false)) {
        const message = product.isMultiples ? `Quantity should be a multiple of  ${product.maxQtyItemRestriction}` :`Maximum Order quantity is  ${product.maxQtyItemRestriction}`
        this.handleErrorMessage(shipIndex,items,boxPos,message,e.target.value,fieldName)
        e.target.style=errorStyle
        highlightError = true
        //return;
      }
    }
    if(!highlightError &&(tableType === 'event-cart' || isReunionShoppingAreaCart && (flowType === 1 || flowType === 4 || flowType === 3 )) && (value !== '' && parseInt(value) > 0)) {
      for(let i=0; i <=3 ; i++) {
        const itemPos = `${this.props.cart.cartId+''+items+''+i}`
        const qtyBox =document.getElementById('qtyBox'+itemPos)
        if(qtyBox) {
          qtyBox.style=''
        }
      }
    }else if(isReunionShoppingAreaCart && (flowType === 2 || flowType === 5 ) && (value !== '' && parseInt(value) > 0)) {
      const assortmentQty = document.querySelectorAll('.qtyBox'+`${id+''+items+''+0}`)
        assortmentQty.forEach((ele, index)=>{
        ele.style = ''
        })
       highlightError = false
    }
    
    if(!highlightError) {
      e.target.style=''
    }    
     if(this.message[items]) {
      if(!isNaN(shipIndex)) {
        if(!(isReunionShoppingAreaCart && (flowType === 2 || flowType === 5))) {
          if(!highlightError) {
            this.message[items].splice(shipIndex,1,'')
          } 
        }
          this.messageIndex = boxPos       
        if(this.message[items][0] ==='Enter a valid quantity') {
          if(!highlightError) {
          this.message[items]= []
          }
        }
      }
      else {
        if(!highlightError) {
          this.message[items]= []
        }
      }
     }
     this.errorQty = this.errorQty.filter(item => item !== itemPos)
    if(cart.TypeId === 3){      
      this.props.updateCloseOutCornerQuantity(
        this.props.cart.cartId,
        product.item_nbr === 0 ? product.number : product.item_nbr,
        e.target.value,
        product.rdcNum ? product.rdcNum : this.props.rdc,
        product.relay_nbr,
        product.vendorId,
        product.promoNbr,
        product.model,7,
        product.overrideRetail);
    }else{
      this.props.updateQuantity(
        this.props.cart.cartId,
        product.overrideRetail,
        items,
        e.target.value,
        product.rdcNum ? product.rdcNum : this.props.rdc,
        product.itemSource ? product.itemSource :'LR',
        fieldName,
        otherQties,
        relay_nbr,
        vendorId,
        product.model,
        this.props?.cart?.TypeId,
        product.month,
        this.props.cart,
        this.props.viewType,
        product?.Upc ? product?.Upc : null,
        product?.DsCost ?  product?.DsCost : null

      );
    }
  };

  qtyValidation = (e, product, currentQty, index, shipIndex) => {
    const { value } = e.target;
    let allowZero = this.state.isReunionShoppingAreaCart ? true : false
    if(allowZero && (parseInt(value)=== 0 || isNaN(parseInt(value))) ) {
        return
    }
  };

  getAssortmentLink = (product, flowType) =>{
    const assortment = {
      department: product.deptName || '',
      modelNbr: product.model,
      description:product.description,
      number: product.number,
      categoryName: product.categoryName,
      savings: product.savings,
      markdown_credit: product.markdown_credit,
      member_mssingCost: product.member_mssingCost,
      delivery: product.delivery,
      rebateAmount: product.creditValue,
      RAMarketEndDate : product.expireddate,
      billingDateTerms: product.billingDateTerms,
      regCost: product.member_cost,
      orderedModelNbr: product.orderedModelNbr,
      orderedDate: product.orderedDate,
      isFlashsales: product.deptName && product.deptName.trim() === FLASH_SALES,
      isReunionAssortment: flowType===44 || flowType === 4 ? false: this.props.marketFlag,
      ShipOptions: this.props.shipOptions,
      RAMarketFlag: !this.props.isRetailAssortmentsEnable && !this.props.isCtbAssortmentsEnable ? 'Y' : '',
      ...(flowType===44) ? {
        planoNum:product.ctb_pog_nbr,
        EnforceMissingAll:product.EnforceMissingAll,
        isCtbAssortment:true
      }: null
      
    }
    if (flowType === 2){
      history.push({
        pathname: REUNIONORDERING + encodeURIComponent(this.props?.cart?.DisplayName) + '/' +
          this.props?.cart?.shoppingAreaId +'/'+ this.state?.flowType + EVENTS + product.promoNbr +
          EVENT_NAME + encodeURIComponent(product.promoName) +'/'+ product.relay_nbr +'/'+ TRUE +
          ASSORTMENTS + product.number +'/'+ product.model,
      });
    } else {
      this.props.getRaCategories(assortment);
      history.push({
        pathname: '/assortment/' + product.number,
        state:{
          isCtbAssortment:flowType===44 ? true : false,
          ...(flowType===44) ? {missing: product?.isMissingSelected || product?.EnforceMissingAll === "2" ? '0': '1'} : null
        }
      })
    }
  }
  handleOrChange = (e, product) => {
    const { value } = e.target;
    if (!/^(\d{0,5}\.\d{0,2}|\d{1,5})$/.test(value) && value.trim() !== '') {
      return false;
    }
    this.setState(prevState => {
      let overrideretails = prevState.overrideretails;
      overrideretails[`${product.item_nbr}-${product.rdcNum || product.rdcNum !== "" ? product.rdcNum : this.props.rdc}`] = value;
      return { ...prevState, overrideretails };
    });
    this.props.updateOr(
      this.props.cart.cartId,
      product.itemQty,
      product.item_nbr,
      value,
      product.rdcNum,
      product.itemSource
    );
  };

  checkoutAll = (cartId) => {
    const { updatedCart : multiStoreCart } = this.props;
    let multiStoreCarts = multiStoreCart[cartId];
    this.checkoutMultiStore(multiStoreCarts, cartId)
  }

  checkoutSelected = (multiStoreCartId) => {
    const { updatedCart : multiStoreCart, getCartSelected : cartSelected } = this.props;
    let selectedStores = cartSelected[multiStoreCartId];
    if(selectedStores && selectedStores.length > 0){
      let multiStoreCartForCheckout = multiStoreCart[multiStoreCartId].filter(cart =>
                                            selectedStores.includes(cart.storeId))
      this.checkoutMultiStore(multiStoreCartForCheckout, multiStoreCartId);
    }else{
      utilities.showToast('Please select atleast one order to checkout',true);
    }
  }

  handleStoreCheck= (storeId, multiStoreCartId) => {
    const { getCartSelected : cartSelected } = this.props;
     if(cartSelected[multiStoreCartId])
     {
       let selectedStores = cartSelected[multiStoreCartId] 
       if(selectedStores.includes(storeId)){
        selectedStores = selectedStores.filter(item => item !== storeId)
       }else{
          selectedStores.push(storeId);
       }
       cartSelected[multiStoreCartId] = selectedStores;
     }else{
      cartSelected[multiStoreCartId] = [storeId];
     }
     this.props.setCartSelected(cartSelected);
  }
  handleQtyError = (item, index, cartId, highlight = true) => {
    const items = item.item_nbr !== 0 ? item.item_nbr: item.number !==0 ?item.number : item.model
    //const id = Object.keys(this.props?.childStoreList).length > 1 ? storeId : this.props.cart.cartId
    const itemPos = `${cartId+''+items+''+index}`
    document.getElementById('qtyBox'+itemPos).focus()
    if(document.getElementById('qtyBox'+itemPos) && highlight) {
      document.getElementById('qtyBox'+itemPos).style ="border-color:red;color:red;border-width:2px"
    }
  }
  checkoutMultiStore = (multiStoreCarts, cartId) => {
    const { address, mainRDCUnderEmergency, updatedCart } = this.props;
    const { isReunionShoppingAreaCart, flowType,quantities } = this.state;
      let hasErrors = false
      let totalQty = 0  
      multiStoreCarts.forEach(products => {
        products.items.forEach((item, index)=> {
        const fieldEnum = [
          item.userQty,
          item.userQty2,
          item.userQty3,
          item.userQty4
        ];
        item.maxQtyItemRestriction && item.maxQtyItemRestriction > 0 && fieldEnum.forEach((qty, index) => {
          totalQty += !isNaN(parseInt(qty)) ? parseInt(qty) : 0;
          if(!hasErrors) {
            if((parseInt(this.props.cart.shoppingAreaMetaId) !== 2 && parseInt(this.props.cart.shoppingAreaMetaId) !== 6 && parseInt(this.props.cart.shoppingAreaMetaId) !== 8) && !isMaxQtyValid(totalQty,item.maxQtyItemRestriction,item.isMultiples)) {
              this.handleQtyError(item, index, products.cartId)          
              hasErrors = true
              return;
              }
            if((parseInt(this.props.cart.shoppingAreaMetaId) === 2 || parseInt(this.props.cart.shoppingAreaMetaId) === 6 || parseInt(this.props.cart.shoppingAreaMetaId) === 8) && item?.maxQtyPerReunion && item?.maxQtyPerReunion > 0 && !isMaxQtyValid(qty,item?.maxQtyPerReunion,false)) {
              this.handleQtyError(item, index, products.cartId)          
              hasErrors = true
              return;
            }
            if((parseInt(this.props.cart.shoppingAreaMetaId) === 2 || parseInt(this.props.cart.shoppingAreaMetaId) === 6 || parseInt(this.props.cart.shoppingAreaMetaId) === 8) &&!isMaxQtyValid(qty,item.maxQtyItemRestriction,item.isMultiples)) {
              this.handleQtyError(item, index, products.cartId)          
              hasErrors = true
              return;
              }
          }
        });
        item.s_pack_type === 'F' && fieldEnum.forEach((qty, index) => {
          if(!hasErrors) {            
            if(qty && (parseInt(qty) !== 888) && !validateCartProductQuantity(item.s_pack_type, item.s_pack_qty, qty)) {
             this.handleQtyError(item, index, products.cartId)          
              hasErrors = true
              return;
            }
          }
        })
        fieldEnum.forEach((qty, index) => {
          totalQty += !isNaN(parseInt(qty)) ? parseInt(qty) : 0;
        })
        if(_.isEmpty(item?.month) && totalQty  <= 0) {
          if(isReunionShoppingAreaCart && (flowType === 1 || flowType === 4 || flowType === 3)) {
            this.handleQtyError(item, 0, products.cartId, false)
          }
          else {
            this.handleQtyError(item, 0, products.cartId, false)
          }
          hasErrors = true
          utilities.showToast('Quantity should be positive number!', true);
          return 
        }
        totalQty = 0
        })
        if(!_.isEmpty(products.items[0]?.month)) {
          const keyName = products.items[0].item_nbr !== 0 ? 'item_nbr': 'number'
          const uniques = _.uniq(_.map(products.items, keyName));
          uniques.forEach((number,index)=>{
            let totalQty = 0
            let errorProductNumber = false;
            products.items.map(item=>{
              if(item[keyName] === number) {
                totalQty += item.itemQty ? item.itemQty : item.userQty
                if(totalQty===0) {
                  errorProductNumber = item
                }
              }
            })
            if(totalQty  <= 0 && errorProductNumber) {
              hasErrors = true
              this.handleQtyError(errorProductNumber, 0, products.cartId, false)
              utilities.showToast('Quantity should be positive number!', true);
              return 
            }
            totalQty = 0
          })
        }
      });
      if(hasErrors === true){
        return;
      }
    
    const setCurrentCart = this.props.carts.find(item => item.parentId === multiStoreCarts[0].parentId)
    this.props.setCurrentCart(setCurrentCart)
    let cart =multiStoreCarts && multiStoreCarts.filter(msFilter=>{
      return msFilter.items.length > 0
    })
    if(cart[0].TypeId === 5){
      history.push({
        pathname: '/checkoutPlanograms/' + cart[0].cartId,
        state: { cart, multiStoreCarts, isMultiStore : true }
      });
    }else if(cart[0].TypeId === 4){
      history.push({
        pathname: '/checkoutRA/' + cart[0].cartId,
        state: { cart, multiStoreCarts, isMultiStore : true  }
      });
    }
    else if(cart[0].TypeId === 44){
      history.push({
        pathname: '/checkoutCTB/' + cart[0].cartId,
        state: { cart, multiStoreCarts, isMultiStore : true, isCtbAssortment: true }
      });
    }
    else if(cart[0].TypeId === 7){
      history.push({
        pathname: '/checkoutImpulsemerchandising/' + cart[0].cartId,
        state: { cart, multiStoreCarts, isMultiStore : true  }
      });
    }
    else if(cart[0].TypeId === 10){
      history.push({
        pathname: '/checkoutMonthlySpecials/' + cart[0].cartId,
        state: { cart, multiStoreCarts, isMultiStore : true  }
      });
    }
    else if(cart[0].TypeId === 2){
      history.push({
        pathname: '/checkoutRetailEvents/' + cart[0].cartId,
        state: { cart, multiStoreCarts, isMultiStore : true  }
      });
    }else if (isReunionShoppingAreaCart && (flowType===1 || flowType=== 3 || flowType=== 4 || flowType=== 5)){
      history.push({
        pathname: '/checkoutReunionCart/' + cart[0].cartId,
        state: { cart, multiStoreCarts, isReunionShoppingAreaCart, flowType,isMultiStore : true }
      });
    }else if(isReunionShoppingAreaCart && flowType === 2){
      history.push({
        pathname: '/checkoutAssortmentFlow/' + cart[0].cartId,
        state: { cart, multiStoreCarts , isReunionShoppingAreaCart, flowType ,isMultiStore : true}
      });
    }else{
      this.setState({
        isLoading:true
      })
      let postObj = multiStoreCarts.map(storecart => {
        if(storecart?.items?.length > 0){
          return {
            shoppingCartInfoId: storecart.cartId,
            membernbr: storecart.storeId,
            memberRDC: this.props.rdc,
            location:
              address.countryName + ',' + address.City + ',' + address.stateCode,
            shiptohome: '',
            aliasSKURetailerGrpID : this.props.multiStoreDetails?.aliasSKURetailerGrpID,
            emergency: mainRDCUnderEmergency        
          };
        }
      })
      postObj = postObj && postObj.filter(storeCartExist=>{
        return !utilities.isUndefinedOrNull(storeCartExist)
      })
      let poNumMultiStore = {}
      multiStoreCarts.forEach((cart)=>{
        if(cart.cartName !== 'Default') {
        poNumMultiStore[cart.storeId] = cart.cartName
        }
      })
      restUtils.postData(CHECKOUT_VALIDATION, postObj)
      .then(response => {
        this.setState({
          isLoading:false
        })
        const itemGrouping = response.data?.map(group => {return group.Items_grouping});
        this.props.checkout(postObj[0].shoppingCartInfoId, itemGrouping);
        history.push({
          pathname: '/checkout/' + cart[0].cartId,
          cart: cart,
          state: {
            itemGrouping,
            multiStoreCarts,
            isMultiStore : true,
            orderFromRetrofittedRDCs: response.data.orderFromRetrofittedRDCs,
            retrofittedRDCs : itemGrouping[0][0].retrofittedRDCs,
            poNum: cart.cartName !== 'Default' ? poNumMultiStore : {}
          }
        });
      })
      .catch(err => {
        this.setState({
          isLoading:false
        })
        let errorList = {}
        let toastMessage =
          err.response && err.response.data && err.response.data.length > 0
            ? err.response.data[0].Message +
              ' : ' +
              err.response.data[0].itemNumber
            : 'Checkout Failed!';
            if(err.response && err.response.data && err.response.data.length > 0) {
              err.response.data.forEach((item)=>{
                errorList[item.itemNumber] = item.Message;
              })
              this.setState({ errorList })
              utilities.showToast('Order cannot be submitted; please review your order for errors and make corrections as needed.', true);
              return
            }
        utilities.showToast(toastMessage, true);
      });
    }
  }

  checkout = () => {
    const { address, cart, mainRDCUnderEmergency,updatedCart } = this.props;
    const { isReunionShoppingAreaCart, flowType,quantities } = this.state;
    let multiStoreCarts = [cart];
    let hasErrors = false  
    let totalQty = 0 
    let allCartsCheckout = []
    Object.values(updatedCart).forEach(item => {
      item.map(ele=>{
        allCartsCheckout.push(ele)
      })      
    })
    const products = allCartsCheckout.find(item=>parseInt(item.cartId) === parseInt(cart.cartId) )
    products.items.forEach((item,index) => {
      let fieldEnum = [
        item.userQty,
        item.userQty2,
        item.userQty3,
        item.userQty4
      ];
      if(cart.TypeId === 33) {
        fieldEnum = [item.itemQty]
      }
      item.maxQtyItemRestriction && item.maxQtyItemRestriction > 0 && fieldEnum.forEach((qty, index) => {
        totalQty += !isNaN(parseInt(qty)) ? parseInt(qty) : 0;
        if(!hasErrors) {
        if((parseInt(this.props.cart.shoppingAreaMetaId) !== 2 && parseInt(this.props.cart.shoppingAreaMetaId) !== 6 && parseInt(this.props.cart.shoppingAreaMetaId) !== 8) && !isMaxQtyValid(totalQty,item.maxQtyItemRestriction,item.isMultiples)) {
          this.handleQtyError(item, index, cart.cartId)          
          hasErrors = true 
          return;
        }
        if((parseInt(this.props.cart.shoppingAreaMetaId) === 2 || parseInt(this.props.cart.shoppingAreaMetaId) === 6 || parseInt(this.props.cart.shoppingAreaMetaId) === 8) && item?.maxQtyPerReunion && item?.maxQtyPerReunion > 0 && !isMaxQtyValid(qty,item?.maxQtyPerReunion,false)) {
          this.handleQtyError(item, index, cart.cartId)
          hasErrors = true
          return;
        }
        if((parseInt(this.props.cart.shoppingAreaMetaId) === 2 || parseInt(this.props.cart.shoppingAreaMetaId) === 6 || parseInt(this.props.cart.shoppingAreaMetaId) === 8) &&!isMaxQtyValid(qty,item.maxQtyItemRestriction,item.isMultiples)) {
          this.handleQtyError(item, index, cart.cartId)
          hasErrors = true
          return;
        }
      }
      });
      item.s_pack_type === 'F' && fieldEnum.forEach((qty, index) => {
        if(!hasErrors) {
          if(qty && (parseInt(qty) !== 888) && !validateCartProductQuantity(item.s_pack_type, item.s_pack_qty, qty)) {
            this.handleQtyError(item, index, cart.cartId)
            hasErrors = true
            return
          }
       }
      })
      fieldEnum.forEach((qty, index) => {
        totalQty += !isNaN(parseInt(qty)) ? parseInt(qty) : 0;
      })
      if(cart.TypeId !== 33 && _.isEmpty(item?.month) && totalQty  <= 0) {
        if(isReunionShoppingAreaCart && (flowType === 1 || flowType === 4 || flowType === 3)) {
          this.handleQtyError(item, 0, cart.cartId)
        }
        else {
          this.handleQtyError(item, 0, cart.cartId)
        }
        hasErrors = true
        utilities.showToast('Quantity should be positive number!', true);
        return 
      }
      if(cart.TypeId === 33 && totalQty  <= 0) {
        this.handleQtyError(item, 0, cart.cartId)
        hasErrors = true
        utilities.showToast('Quantity should be positive number!', true);
        return 
      }
      totalQty = 0 
    })
    if(!_.isEmpty(products.items[0]?.month)) {
      const keyName = products.items[0].item_nbr !== 0 ? 'item_nbr': 'number'
      const uniques = _.uniq(_.map(products.items, keyName));
      uniques.forEach((number,index)=>{
        let totalQty = 0
        let errorProductNumber = false;
        products.items.map(item=>{
          if(item[keyName] === number) {
            totalQty += item.itemQty ? item.itemQty : item.userQty
            if(totalQty===0) {
              errorProductNumber = item
            }
          }
        })
        if(totalQty  <= 0 && errorProductNumber) {
          hasErrors = true
          this.handleQtyError(errorProductNumber, 0, cart.cartId)
          utilities.showToast('Quantity should be positive number!', true);
          return 
        }
        totalQty = 0
      })
    }
    if(hasErrors === true){
      return;
    }
    const setCurrentCart = this.props.carts.find(item => item.parentId === cart.parentId)
    this.props.setCurrentCart(setCurrentCart)
    if( cart.TypeId === 33) {
      history.push({
        pathname: '/checkoutDirectShip/' + this.props.cart.cartId,
        state: { cart, multiStoreCarts }
      });
    }
    else if(cart.TypeId === 5){
      history.push({
        pathname: '/checkoutPlanograms/' + this.props.cart.cartId,
        state: { cart, multiStoreCarts }
      });
    }else if(cart.TypeId === 4){
      history.push({
        pathname: '/checkoutRA/' + this.props.cart.cartId,
        state: { cart, multiStoreCarts }
      });
    }else if(cart.TypeId === 44){
      history.push({
        pathname: '/checkoutCTB/' + this.props.cart.cartId,
        state: { cart, multiStoreCarts,isCtbAssortment: true  }
      });
    }
    else if(cart.TypeId === 7){
      history.push({
        pathname: '/checkoutImpulsemerchandising/' + this.props.cart.cartId,
        state: { cart, multiStoreCarts }
      });
    }
    else if(cart.TypeId === 10){
      history.push({
        pathname: '/checkoutMonthlySpecials/' + this.props.cart.cartId,
        state: { cart, multiStoreCarts }
      });
    }
    else if(cart.TypeId === 2){
      history.push({
        pathname: '/checkoutRetailEvents/' + this.props.cart.cartId,
        state: { cart, multiStoreCarts }
      });
    }else if (isReunionShoppingAreaCart && (flowType===1 || flowType===3 || flowType===4 || flowType===5)){
      history.push({
        pathname: '/checkoutReunionCart/' + this.props.cart.cartId,
        state: { cart, multiStoreCarts, isReunionShoppingAreaCart, flowType }
      });
    }else if(isReunionShoppingAreaCart && flowType === 2){
      history.push({
        pathname: '/checkoutAssortmentFlow/' + this.props.cart.cartId,
        state: { cart ,multiStoreCarts, isReunionShoppingAreaCart, flowType}
      });
    }
    else{
      this.setState({
        isLoading:true
      })
      const postObj = {
        shoppingCartInfoId: cart.cartId,
        membernbr: Object.keys(this.props?.childStoreList).length > 1 ? cart.storeId : this.props.storeId,
        memberRDC: this.props.rdc,
        location:
          address.countryName + ',' + address.City + ',' + address.stateCode,
        shiptohome: '',
        aliasSKURetailerGrpID : this.props.multiStoreDetails && this.props.multiStoreDetails.aliasSKURetailerGrpID,
        emergency: mainRDCUnderEmergency        
      };
      restUtils.postData(CHECKOUT_VALIDATION, [postObj])
        .then(response => {
          this.setState({
            isLoading:false
          })
          const itemGrouping = response.data?.map(group => {return group.Items_grouping});
          this.props.checkout(cart.cartId, itemGrouping);
          history.push({
            pathname: '/checkout/' + this.props.cart.cartId,
            cart: this.props.cart,
            multiStoreCarts,
            state: {
              itemGrouping,
              orderFromRetrofittedRDCs: response.data.orderFromRetrofittedRDCs,
              retrofittedRDCs : itemGrouping[0][0].retrofittedRDCs,
              poNum: cart.cartName !== 'Default' ? { [cart.storeId]: cart.cartName } : {}
            }
          });
        })
        .catch(err => {
          this.setState({
            isLoading:false
          })
          let errorList = {}
          let toastMessage =
            err.response && err.response.data && err.response.data.length > 0
              ? err.response.data[0].Message +
                ' : ' +
                err.response.data[0].itemNumber
              : 'Checkout Failed!';
              if(err.response && err.response.data && err.response.data.length > 0) {
                err.response.data.forEach((item)=>{
                  errorList[item.itemNumber] = item.Message;
                })
                this.setState({ errorList })
                utilities.showToast('Order cannot be submitted; please review your order for errors and make corrections as needed.', true);
                return
              }
          utilities.showToast(toastMessage, true);
        });
    }
  };

  removeItem = (item_nbr, rdcNum, month, model, vendorId, cartTypeId,cart) => {
    this.props.removeItem(this.props.cart.cartId, item_nbr, rdcNum, month, model, vendorId, cartTypeId,cart);
  };

  toggleFavorite = (item_nbr, isFavorite) => {
    isFavorite
      ? this.props.removeFromFavorites([item_nbr])
      : this.props.addToFavorites(item_nbr);
  };

  cancelOrder = () => {
    history.push('/cart');
    this.props.deleteCart(this.props.cart.cartId);
    this.props.notifyCarts();
  };

  getSavings = (savings) => {
    return !utilities.isEmptyOrNullString(savings) ? (
      parseFloat(savings) * 100 + ' %'
    ) : '';    
  }

  imcartLabel=()=>{
    return [
      { label: '' },
      { label: '' },
      { label: 'Item #' },
      utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
        { label: 'Alternate SKU#' }),
      { label: 'UPC #' },
      { label: 'Model #' },
      { label: 'Item Description' },
      { label: 'Pack' },
      { label: 'Available' },
      { label: 'Cost' },
      { label: 'Qty' },
      { label: 'Ext. Cost' },
      { label: '' }
    ];
  }

  imcheckoutLabel=()=>{
    return [
      { label: 'Item #' },
      utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
        { label: 'Alternate SKU#' }),
      { label: 'UPC #' },
      { label: 'Model #' },
      { label: 'Item Description' },
      { label: 'Pack' },
      { label: 'Available' },
      { label: 'Cost' },
      { label: 'Qty' },
      { label: 'Ext. Cost' }
    ];
  }
  getHeaders = (tableType) => {
    const { cart } = this.props;
    const isMonthlySpecial = cart.TypeId === 10;
    const getPrefer = this.props.getprefer &&
    this.props.getprefer.customerView
    ? this.props.getprefer.customerView
    : 'c1'
    let shipDates = [];
    if(!_.isEmpty(cart.items)){
      const item = cart.items[0];
      if(!utilities.isEmptyOrNullString(item.ShipDate1)){
        shipDates.push("Qty1");
      }
      if(!utilities.isEmptyOrNullString(item.ShipDate2)){
        shipDates.push("Qty2");
      }
      if(!utilities.isEmptyOrNullString(item.ShipDate3)){
        shipDates.push("Qty3");
      }
      if(!utilities.isEmptyOrNullString(item.ShipDate4)){
        shipDates.push("Qty4");
      }
    }
    switch (tableType) {
      case 'viewCart':
        if(cart.TypeId === 33) {
          let labelNames = [
            { label: '' },
            { label: 'Item #' },
            { label: 'UPC #' },
            { label: 'Model #' },
            { label: 'Item Description' },
            { label: 'Pack' },
            { label: 'DS Cost' },
            { label: 'Sugg. Retail' },
            { label: 'Qty' },
            { label: 'Ext.Cost' },
            { label: '' },
          ]
          return labelNames
        }
        let labelNames = [
          { label: '' },
          { label: '' },
          { label: 'Item #' }]
        if(utilities.isAliasSkuRequired(this.props.multiStoreDetails)){
          labelNames = [...labelNames,{ label: 'Alternate SKU#' }]
        }
        labelNames = [...labelNames,
          { label: 'UPC #' },
          { label: 'Model #' },
          { label: 'Item Description' },
          { label: 'RDC #' },
          { label: 'Pack' },
          { label: 'Available' }]
        if(this.state.isMaxQty){
          labelNames = [...labelNames, {label: 'Max Qty'}]
        } 
        if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
          labelNames = [...labelNames]
        }  else if(this.props.viewType !== MEMBER_VIEW && getPrefer === "c1" )  {
          labelNames = [...labelNames, {label: 'MC'}]
        }else {
          labelNames = [...labelNames,{ label: utilities.getLabelsByType(this.props.viewType,"COST", getPrefer) }]
       }
        if(cart.TypeId === 1){
          if(this.props.viewType !== MEMBER_VIEW && getPrefer === "c3"){
            labelNames = [...labelNames]
          } else {
            labelNames = [...labelNames,{ label: utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL", getPrefer) }]
          }    
          
        }    
        labelNames = [...labelNames,
          { label: 'Qty' },
          { label: 'Override Retail' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelNames = [...labelNames]
          } else {
            labelNames = [...labelNames,{ label: utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer) }]
          }
          labelNames = [...labelNames,
          { label: '' }];
        return labelNames
      case 'dom-cart':
        let domCartLabelNames = [{ label: '' },
            { label: '' },
            { label: 'Item #' }]
            if(utilities.isAliasSkuRequired(this.props.multiStoreDetails)){
              domCartLabelNames = [...domCartLabelNames,{ label: 'Alternate SKU#' }]
            }
            domCartLabelNames = [...domCartLabelNames,{ label: 'UPC #' },
            { label: 'Model #' },
            { label: 'Item Description' },
            { label: 'RDC #' },
            { label: 'Pack' },
            { label: 'Available' }]
            if(this.state.isMaxQty){
              domCartLabelNames = [...domCartLabelNames, {label: 'Max Qty'}]
            }
            domCartLabelNames = [...domCartLabelNames,  { label: 'Promo Cost' },
            { label: 'Qty' },
            { label: 'Override Retail' },
            { label: 'Ext. Cost' },
            { label: '' }];
            return domCartLabelNames;
      case 'im-cart':
        return this.imcartLabel();
      case 'checkout':
        let labelNamesCheckout = [{ label: 'Item #' }]
        if(utilities.isAliasSkuRequired(this.props.multiStoreDetails)){
          labelNamesCheckout = [...labelNamesCheckout,
            { label: 'Alternate SKU#' }]
        }
        if(cart.TypeId === 33) {
          return labelNamesCheckout = [
            { label: 'Item #' },
            { label: 'UPC #' },
            { label: 'Model #' },
            { label: 'Item Description' },
            { label: 'Pack' },
            { label: 'DS Cost' },
            { label: 'Sugg. Retail' },
            { label: 'Qty' },
            { label: 'Ext.Cost' },
          ]
        }
        labelNamesCheckout = [...labelNamesCheckout,
          { label: 'UPC #' },
          { label: 'Model #' },
          { label: 'Item Description' },
          { label: 'RDC #' },
          { label: 'Pack' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelNamesCheckout = [...labelNamesCheckout]
          }  else if(this.props.viewType !== MEMBER_VIEW && getPrefer === "c1" )  {
            labelNamesCheckout = [...labelNamesCheckout, {label: 'MC'}]
          }else {
            labelNamesCheckout = [...labelNamesCheckout,{ label: utilities.getLabelsByType(this.props.viewType,"COST", getPrefer) }]
         }
          if(cart.TypeId === 1){
            if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3")){
              labelNamesCheckout = [...labelNamesCheckout]
            } else {
              labelNamesCheckout = [...labelNamesCheckout,{ label: utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL", getPrefer) }]
            }    
            
          }  
        labelNamesCheckout = [...labelNamesCheckout,
          { label: 'Qty' },
          { label: 'Override Retail' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelNamesCheckout = [...labelNamesCheckout]
          } else { 
            labelNamesCheckout = [...labelNamesCheckout, { label: utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer) }]
          }
          labelNamesCheckout = [...labelNamesCheckout,
          { label: 'Ext. Weight' }];
        return labelNamesCheckout
      case 'dom-confirmation':
      case 'dom-checkout':
        return [
          { label: 'Item #' },
          utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
            { label: 'Alternate SKU#' }),
          { label: 'UPC #' },
          { label: 'Model #' },
          { label: 'Item Description' },
          { label: 'RDC #' },
          { label: 'Pack' },
          { label: 'Promo Cost' },
          { label: 'Override Retail' },
          { label: 'Qty' },
          { label: 'Ext. Cost' }
        ];
      case 'confirmation':
        let labelNamesConfirmation = [{ label: 'Item #' }]
        if(cart.TypeId === 33) {
          return labelNamesCheckout = [
            { label: 'Item #' },
            { label: 'UPC #' },
            { label: 'Model #' },
            { label: 'Item Description' },
            { label: 'Pack' },
            { label: 'DS Cost' },
            { label: 'Sugg. Retail' },
            { label: 'Qty' },
            { label: 'Ext.Cost' },
          ]
        }
        if(utilities.isAliasSkuRequired(this.props.multiStoreDetails)){
          labelNamesConfirmation = [...labelNamesConfirmation, { label: 'Alternate SKU#' }]
        }
        labelNamesConfirmation = [...labelNamesConfirmation,
          { label: 'UPC #' },
          { label: 'Model #' },
          { label: 'Item Description' },
          { label: 'RDC #' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelNamesConfirmation = [...labelNamesConfirmation]
          }  else if(this.props.viewType !== MEMBER_VIEW && getPrefer === "c1" )  {
            labelNamesConfirmation = [...labelNamesConfirmation, {label: 'MC'}]
          }else {
            labelNamesConfirmation = [...labelNamesConfirmation,{ label: utilities.getLabelsByType(this.props.viewType,"COST") }]
         }
         if(cart.cartType === 1){
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3")){
            labelNamesConfirmation = [...labelNamesConfirmation]
          } else {
            labelNamesConfirmation = [...labelNamesConfirmation,{ label: utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL", getPrefer) }]
          }    
          
        } 
        labelNamesConfirmation = [...labelNamesConfirmation,
          { label: 'Override Retail' },
          { label: 'Qty' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelNamesConfirmation = [...labelNamesConfirmation]
          }  else {
            labelNamesConfirmation = [...labelNamesConfirmation,{ label: utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer) }]
          }
          labelNamesConfirmation = [...labelNamesConfirmation,
          { label: 'Status' }];
        return labelNamesConfirmation
      case 'ra-cart':
        let labelRaCart = [
          { label: 'Department Name' },
          { label: 'Model #' },
          { label: 'Item Description' }]
          if(this.props.viewType === MEMBER_VIEW ){
            labelRaCart = [...labelRaCart,
              { label: 'Savings' }]
          } else {
            labelRaCart = [...labelRaCart]
          }
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelRaCart = [...labelRaCart]
          } else {
            labelRaCart = [...labelRaCart,
              { label: utilities.getLabelsByType(this.props.viewType,"CREDIT_VALUE", getPrefer) }]
          }
          labelRaCart = [...labelRaCart,
          { label: 'Delivery'},
          { label: 'Qty' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
          labelRaCart = [...labelRaCart]
          } else {
            labelRaCart = [...labelRaCart,
              { label: utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer) }]
          }
          labelRaCart = [...labelRaCart,
          { label: '' }
        ];
        return labelRaCart
      case 'ctb-cart':
        return [
          { label: 'Department Name' },
          { label: 'POG Description' },
          { label: 'POG' },
          { label: 'Savings' },
          { label: 'Markdown Credit' },
          { label: 'POG Selection'},
          { label: 'Ship Month' },
          { label: 'Ext. Cost' },
          { label: '' }
        ];
      case 'ra-checkout':
        let labelRaCheckout = [
          { label: 'Department Name' },
          { label: 'Model #' },
          { label: 'Description' }]
          if(this.props.viewType === MEMBER_VIEW ){
            labelRaCheckout = [...labelRaCheckout,
              { label: 'Savings' }]
          } else {
            labelRaCheckout = [...labelRaCheckout]
          }
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelRaCheckout = [...labelRaCheckout]
          } else {
            labelRaCheckout = [...labelRaCheckout,
              { label: utilities.getLabelsByType(this.props.viewType,"CREDIT_VALUE", getPrefer) }]
          }
          labelRaCheckout = [...labelRaCheckout,
          { label: 'Delivery'},
          { label: 'Qty' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelRaCheckout = [...labelRaCheckout]
            } else {
              labelRaCheckout = [...labelRaCheckout,
          { label: utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer)}
        ]}
        return labelRaCheckout;
      case 'ctb-checkout':
        return [
          { label: 'Department Name' },
          { label: 'POG Description' },
          { label: 'POG' },
          { label: 'Savings' },
          { label: 'Markdown Credit' },
          { label: 'POG Selection'},
          { label: 'Ship Month'},
          { label: 'Ext. Cost' }
        ];
      case 'ra-confirmation':
        let labelRaConfirmation = [
          { label: 'Department Name' },
          { label: 'Model #' },
          { label: 'Item Description' }]
          if(this.props.viewType === MEMBER_VIEW ){
            labelRaConfirmation = [...labelRaConfirmation,
              { label: 'Savings' }]
          } else {
            labelRaConfirmation = [...labelRaConfirmation]
          }
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelRaConfirmation = [...labelRaConfirmation]
          } else {
            labelRaConfirmation = [...labelRaConfirmation,
              { label: utilities.getLabelsByType(this.props.viewType,"CREDIT_VALUE", getPrefer) }]
          }
          labelRaConfirmation = [...labelRaConfirmation,
          { label: 'Delivery'},
          { label: 'Qty' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelRaConfirmation = [...labelRaConfirmation]
          } else {
          labelRaConfirmation = [...labelRaConfirmation, 
          { label: utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer) }]
          }
          labelRaConfirmation = [...labelRaConfirmation,
          { label: 'Status' }
        ];
        return labelRaConfirmation;
      case 'ctb-confirmation':
        return [
          { label: 'Department Name' },
          { label: 'POG Description' },
          { label: 'POG' },
          { label: 'Savings' },
          { label: 'Markdown Credit' },
          { label: 'POG Selection'},
          { label: 'Ship Month'},
          { label: 'Ext. Cost' },
          { label: 'Status' }
        ];
      case 'event-cart':
        let labelevent_cart = [
          { label: '' },
          { label: '' },
          { label: 'Item #' },
          utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
            { label: 'Alternate SKU#' }),
          { label: 'UPC #' },
          { label: 'Model #' },
          { label: 'Item Description' },
          { label: 'Pack' },
          this.state.isMaxQty && ({label: 'Max Qty'}),]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelevent_cart = [...labelevent_cart]
          } else {
            labelevent_cart = [...labelevent_cart,
              { label:  isMonthlySpecial ? utilities.getLabelsByType(this.props.viewType,"EVENT_COST", getPrefer) : 'Promo Cost' }]
          }
          labelevent_cart = [...labelevent_cart,
          ...utilities.isEmptyOrNullString(shipDates[0])?[]:[{ label:shipDates[0]}],
          ...utilities.isEmptyOrNullString(shipDates[1])?[]:[{ label: shipDates[1]}],
          ...utilities.isEmptyOrNullString(shipDates[2])?[]:[{ label: shipDates[2]}],
          ...utilities.isEmptyOrNullString(shipDates[3])?[]:[{ label: shipDates[3]}],
          { label: 'Override Retail' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            labelevent_cart = [...labelevent_cart]
          } else {
            labelevent_cart = [...labelevent_cart,
              { label:  utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer) }]
          }
          labelevent_cart = [...labelevent_cart,
          { label: '' }
        ];
        return labelevent_cart;
      case 'ms-confirmation':
      case 'event-checkout':
        let eventCheckout = [
        
          { label: 'Item #' },
          utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
            { label: 'Alternate SKU#' }),
          { label: 'UPC #' },
          { label: 'Model #' },
          { label: 'Item Description' },
          { label: 'Pack' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            eventCheckout = [...eventCheckout]
          } else {
            eventCheckout = [...eventCheckout,
              { label:  isMonthlySpecial ? utilities.getLabelsByType(this.props.viewType,"EVENT_COST", getPrefer) : 'Cost' }]
          }
          eventCheckout = [...eventCheckout,{ label: 'Override Retail' },
          ...utilities.isEmptyOrNullString(shipDates[0])?[]:[{ label: shipDates[0]}],
          ...utilities.isEmptyOrNullString(shipDates[1])?[]:[{ label: shipDates[1]}],
          ...utilities.isEmptyOrNullString(shipDates[2])?[]:[{ label: shipDates[2]}],
          ...utilities.isEmptyOrNullString(shipDates[3])?[]:[{ label: shipDates[3]}]]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            eventCheckout = [...eventCheckout]
          } else {
            eventCheckout = [...eventCheckout,
              { label:  utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer) }]
          }
        return eventCheckout;
      case 'im-checkout':
        return this.imcheckoutLabel();
      case 'pl-checkout':
        let Pl_labelNames = [
          { label: 'Item #' },
          utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
            { label: 'Alternate SKU#' }),
          { label: 'UPC #' },
          { label: 'Model #' },
          { label: 'Item Description' },
          { label: 'Pack' },
          { label: 'Available' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            Pl_labelNames = [...Pl_labelNames]
          }  else if(this.props.viewType !== MEMBER_VIEW && getPrefer === "c1" )  {
            Pl_labelNames = [...Pl_labelNames, {label: 'MC'}]
          } else {
            Pl_labelNames = [...Pl_labelNames,{ label: utilities.getLabelsByType(this.props.viewType,"COST") }]
         }
          // Pl_labelNames = [...Pl_labelNames,{ label: utilities.getLabelsByType(this.props.viewType,"COST") }]
          Pl_labelNames = [...Pl_labelNames,{ label: 'Override Retail' },
          { label: 'Suggested Qty' },
          { label: 'Qty' }]
          if(this.props.viewType !== MEMBER_VIEW && (getPrefer === "c3" || getPrefer === "c4"))  {
            Pl_labelNames = [...Pl_labelNames]
          } else {
            Pl_labelNames = [...Pl_labelNames, { label: utilities.getLabelsByType(this.props.viewType,"EXTENDED_COST", getPrefer) }]
          }
          
          return Pl_labelNames;
      default:
        break;
    }
  };

  getRDCContent = (product, isPlanogram) =>{
    return <>
            {isPlanogram ? product.rdcNum : 
            `${utilities.rdcBasedonCentralship(product.rdctype, product.CsRdc, product.rdcNum)}`}
            {product && product.primaryWarehouseType === 'S' && product.rdctype === 'H' &&
            <span className='hnsInd'>{HUB}
            </span>
            }
          </>
  }
  getMaxQty = (product) => {
    return <>
    {product.maxQtyItemRestriction}
    {
      <span className='maxQtyLabel'>
          {'Max Qty'} 
      </span>}
    </>
  }
  getAvailableContent = product =>{
    return <>
            {product.Available}
            {product && product.primaryWarehouseType === 'S' && product.IsHubPlusSpokeQty && 
                <span className='hnsInd'>
                    {'H&S'} 
                </span>}
          </>
  }

  getQuantity = (tableType, product, index, shipIndex) => {
    const fieldEnum = [
      product.userQty,
      product.userQty2,
      product.userQty3,
      product.userQty4
    ];
    const shipDatesEnum = [
      product.ShipDate1,
      product.ShipDate2,
      product.ShipDate3,
      product.ShipDate4
    ];
    const shipDate = utilities.emptyForNull(
      shipDatesEnum[shipIndex]
    );
    const {cart} = this.props;
    const { flowType } = this.state;
    const isCloseOutCorner = cart.TypeId === 3;
    const items = product.item_nbr !== 0 ? product.item_nbr: product.number !==0 ?product.number : product.model
    const boxPos = shipIndex ? shipIndex : 0 
    const id = Object.keys(this.props?.childStoreList).length > 1 ? this.props.cart.storeId : this.props.cart.cartId
    const itemPos = `${this.props.cart.cartId+''+items+''+boxPos}`
    this.itemRef[itemPos] = React.createRef()
    const errorStyle =  {
      borderColor:"red",color:"red",borderWidth:"2px"
    }
    let value = '';
    let errorFind = false;
    const errorhandle = (value, quantities) => {
      if(tableType !== 'event-cart' && !this.state.isReunionShoppingAreaCart && (value === '' || parseInt(value) === 0)){
        this.message[items] = [`Enter a valid quantity`]
        errorFind = true
      }
      if (!this.state.isReunionShoppingAreaCart && parseInt(value) === 888 ) {
        this.message[items] = [`Bin and Price Ticket Request`]
        errorFind = true
        //return true;
      }    
      if(!errorFind && parseInt(value) !== 888 && product.s_pack_type === 'F' && value % product.s_pack_qty) {
        this.message[items] = [`Quantity should be a multiple of ${product.s_pack_qty}`]
        errorFind = true
      }
      if(!errorFind && product.maxQtyItemRestriction && product.maxQtyItemRestriction > 0) {
        if(!errorFind && (parseInt(this.props.cart.shoppingAreaMetaId) !== 2 && parseInt(this.props.cart.shoppingAreaMetaId) !== 6 && parseInt(this.props.cart.shoppingAreaMetaId) !== 8) && !isMaxQtyValid(value,product.maxQtyItemRestriction,product.isMultiples,false)) {
          this.message[items] = product.isMultiples ? [`Quantity should be a multiple of  ${product.maxQtyItemRestriction}`] :[`Maximum Order quantity is  ${product.maxQtyItemRestriction}`]
          errorFind = true
        }
        if(!errorFind &&(parseInt(this.props.cart.shoppingAreaMetaId) === 2 || parseInt(this.props.cart.shoppingAreaMetaId) === 6 || parseInt(this.props.cart.shoppingAreaMetaId) === 8) && product?.maxQtyPerReunion && product?.maxQtyPerReunion > 0 && !isMaxQtyValid(value,product?.maxQtyPerReunion,false,false)) {
          this.message[items] = [`Maximum Order quantity is  ${product.maxQtyPerReunion}`]
          errorFind = true
        }
        if(!errorFind &&(parseInt(this.props.cart.shoppingAreaMetaId) === 2 || parseInt(this.props.cart.shoppingAreaMetaId) === 6 || parseInt(this.props.cart.shoppingAreaMetaId) === 8) &&!isMaxQtyValid(value,product.maxQtyItemRestriction,product.isMultiples,false)) {
          this.message[items] =product.isMultiples ? [`Quantity should be a multiple of  ${product.maxQtyItemRestriction}`] :[`Maximum Order quantity is  ${product.maxQtyItemRestriction}`]
          errorFind = true
        }
      }
      if(!errorFind && (tableType === 'event-cart' || tableType === '1' || tableType === '3' || tableType === '4')) {
        const changeQty = quantities.map(ele=> parseInt(ele) ? parseInt(ele) : 0)
        const totalQty = changeQty.reduce((total,val)=> total+ val)
        if(totalQty === 0) {
          this.message[items] = [`Enter a valid quantity`]
          errorFind = true
        }
      }
      if(!errorFind && (tableType === '2' || tableType === '5')) {
        let getItem = this.props.cart.items.filter(item=> item.model === product.model).map(ele => ele.userQty)
        const totalQty = getItem.reduce((total,val)=> total+ parseInt(val))
        if(totalQty === 0) {
          this.message[items] = [`Enter a valid quantity`]
          errorFind = true
        }
        else {
          this.message[items] = []
        }
      }
    }
    switch (tableType) {
      case 'ra-checkout':
      case 'ra-confirmation':
        return product.userQty;
      case 'event-cart':
      case "1":
      case "3":
      // case "44":
      case "4":
        let otherQties = {};
        fieldEnum.forEach((qty, index) => {
          if (index !== shipIndex) {
            const fieldname = index === 0 ? 'ItemQty' : `ItemQty${index + 1}`;
            otherQties = { ...otherQties, [fieldname]: qty ? qty : 0 };
          }
        });
          value = this.state.quantities[`${product.item_nbr}-${product.rdcNum || product.rdcNum !== ""? product.rdcNum : this.props.rdc}${flowType===4 || flowType===44 ?'-'+product.model:''}`][
          shipIndex
        ];
        errorhandle(value, this.state.quantities[`${product.item_nbr}-${product.rdcNum || product.rdcNum !== ""? product.rdcNum : this.props.rdc}${flowType===4 || flowType===44 ?'-'+product.model:''}`])
        return (
          <>
            <input
              className={errorFind ? `invalidBox qty-box reunionOfferingBox` : `qty-box reunionOfferingBox`}
              id={'qtyBox'+itemPos}
              ref={this.itemRef[itemPos]}
              style = {this.errorQty.includes(itemPos) ? errorStyle: null}
              key = {itemPos}
              value={(isNaN(value) || value === 0)  ? '' : value}
              disabled={tableType === "4"  ? shipDate : !shipDate}
              onBlur={e => {
                this.qtyValidation(
                  e,
                  product,
                  fieldEnum[shipIndex],
                  index,
                  shipIndex
                );
              }}
              onChange={e =>
                this.handleQtyChange(
                  e,
                  product,
                  index,
                  shipIndex,
                  product.relay_nbr,
                  product.vendorId,
                  otherQties
                )
              }
            ></input>
            { tableType === "4"  ?
              (<span className='addedBy displayBlock'>(Contact Vendor<br/> for Ship Dates)</span>)
              :
              (<span className='addedBy displayBlock'>(Ship date: {utilities.getDateFromTimeStamp(shipDate)})</span>)
            }
          </>
        );
      case 'ra-cart':
      case "2":
        value = this.state.isReunionShoppingAreaCart ? this.state.quantities[`${product.number}-${product.month?product.month:product.rdcNum}`]:
        this.state.quantities[`${product.number}-${product.rdcNum}`];
        errorhandle(value)
        return (
          <>
          <input
            className={errorFind ? `invalidBox qty-box qtyBox${itemPos}`:`qty-box qtyBox${itemPos}`}
            id={'qtyBox'+itemPos}
            key = {itemPos}
            ref={this.itemRef[itemPos]}
            onBlur={e => this.qtyValidation(e, product, product.userQty, index)}
            value={isNaN(value) ? '' : value}
            onChange={e => this.handleQtyChange(e, product, index,null,product.relay_nbr)}
          ></input>
          </>          
        );
      case "5":
        value = this.state.quantities[`${product.item_nbr}-${product.month?product.month:product.rdcNum}`]
        errorhandle(value)
        return (
          <>
          <input
            className={errorFind ? `invalidBox qty-box qtyBox${itemPos}`:`qty-box qtyBox${itemPos}`}
            id={'qtyBox'+itemPos}
            key = {itemPos}
            ref={this.itemRef[itemPos]}
            onBlur={e => this.qtyValidation(e, product, product.userQty, index)}
            value={isNaN(value) ? '' : value}
            onChange={e => this.handleQtyChange(e, product, index, null, product.relay_nbr, product.vendorId)}
          ></input>
          </>
        );
      default:
        value = this.state.quantities[`${product.item_nbr}-${product.rdcNum}`];  
        if(cart.TypeId === 33) {
          value = this.state.quantities[`${product.item_nbr}`];  
        }
        errorhandle(value) 
        return (
          <>
          <input
            className= {errorFind ? 'invalidBox qty-box': 'qty-box'}
            key = {itemPos}
            ref={this.itemRef[itemPos]}
            id={'qtyBox'+itemPos}
            style = {this.errorQty.includes(itemPos) ? errorStyle: null}
            value={isNaN(value) ? '' : value}
            disabled={ isCloseOutCorner ? this.checkInventoryAvailable([product]) : false}
            onBlur={e => this.qtyValidation(e, product, product.itemQty, index)}
            onChange={e => this.handleQtyChange(e, product, index)}
          ></input>
          </>
        );
    }
  }; 

  impCartviewVal=(product,viewType,tieredCost,tierLevel,favIcon,itemImg,tableType,index)=>{
    return[
      { cellContent: favIcon },
          { cellContent: itemImg },
          { cellContent: this.getItemNbrLink(product) },
          utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
            {cellContent: product.aliasSku }),
          { cellContent: product.upcNum },
          { cellContent: product.model, cellClass: 't-left' },
          {
            cellContent: getDescription(product),
            cellClass: 'title-column'
          },
          { cellContent: getPack(product) },
          {
            cellContent: this.getAvailableContent(product),
            cellClass:
              product.Available &&
              parseInt(product.Available) > 0 &&
              product.itemQty <= product.Available
                ? 'color-green'
                : 'color-red'
          },
          {
            cellContent: (
              this.props.multiStoreDetails.access !== 1  && 
              <MaskPrice
                field='Member Cost black' 
                getprefer={this.props.getprefer &&
                this.props.getprefer.customerView
                ? this.props.getprefer.customerView
                : 'c1'
              }
              viewType={'Member View'} 
              fieldVal={tieredCost} />
            ),
            isTiered: product.tiered,
            tierLevel
          },
          { cellContent: this.getQuantity(tableType, product, index) },
          {
            cellContent: (
              this.props.multiStoreDetails.access !== 1  &&
              <MaskPrice
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={tieredCost?product.itemQty*tieredCost:''}
              />
            )
          },
          {
            cellContent: (
              <span
                className='op-clear-label'
                onClick={() =>
                  this.removeItem(product.item_nbr, product.rdcNum)
                }
              >
                <Popup
                  trigger={<Button icon='trash alternate outline' />}
                  content="Remove"
                  size='mini'
                  position='top right'
                  basic
                />
              </span>
            )
          }
    ]
  }

  impCartcheckoutVal=(product,viewType,tieredCost,tierLevel)=>{
    return [
          { cellContent: (product.item_nbr || product.ItemNum) },
          utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
            {cellContent: product.aliasSku }),
          { cellContent: (product.upcNum  ||  product.Upc)},
          { cellContent: product.model, cellClass: 't-left' },
          {
            cellContent: getDescription(product,false,true),
            cellClass: 'title-column'
          },
          { cellContent: getPack(product) },
          { cellContent: product.Available },
          {
            cellContent: (
              this.props.multiStoreDetails.access !== 1  && 
              <MaskPrice 
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType} 
                fieldVal={tieredCost} />
            ),
            isTiered: product.tiered,
            tierLevel
          },
          { cellContent: (product.userQty || product.Qty) },
          {
            cellContent: (
              this.props.multiStoreDetails.access !== 1  && 
              <MaskPrice
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={(product.userQty || product.Qty)  * tieredCost}
              />
            )
          }
        ]; 
  }

  getItemNbrLink = (product, isMonthlySpecial, savingwithPer) => {
    return (<Link to={{ pathname: '/product/' + product.item_nbr,
            state: {
              source: [{ text: 'CART', link: '/cart' }],
              assortmentType: '',
              isMonthlyListing: isMonthlySpecial,
              isWow:product.isReqFromWow,
              MonthlySpecialSaving: isMonthlySpecial ? savingwithPer : '',
              MonthlyEventCost: isMonthlySpecial ?product.promoCost : '',
              MonthlyRegularCost: isMonthlySpecial ? product.regCost : '',
              MonthlyWHCost: isMonthlySpecial ? product.regCost > 0 ? product.regCost : product.cost : '',
              monthlyStock: isMonthlySpecial ? product.MSstocked : '',
              monthlyRelayNbr: isMonthlySpecial ? product.relay_nbr : '',
              monthlyPromoNbr: isMonthlySpecial ? product.MSPromoNbr : '',
              monthlyAssmentNbr: isMonthlySpecial ? product.AsstmtID : ''
            }
      }}>{product.item_nbr}</Link>);
  }

  
  isProductFavorite = (item_nbr) =>{
    return this.props.favorites.indexOf(item_nbr) > -1;
  }
  getFavIcon = (item_nbr) =>{
    let isFavorite = this.isProductFavorite(item_nbr);
    return (
      <i id={'fav' + item_nbr}
        onClick={() => this.toggleFavorite(item_nbr, isFavorite)}
        className={isFavorite ? 'heart icon' : 'heart outline icon'}
      ></i>
    );
  }
  getOverrideRetailField = (product)=>{
    const {cart} = this.props;
    const isCloseOutCorner = cart.TypeId === 3;
    return (
      <span className='override-retail-span'>
        {' '}
        $
        <input
          className='override-retail-input'
          disabled={isCloseOutCorner ? this.checkInventoryAvailable([product]) : false}
          pattern='[0-9]*([\.?][0-9]*)?'
          value={
            this.state.overrideretails[`${product.item_nbr}-${product.rdcNum || product.rdcNum !== "" ? product.rdcNum : this.props.rdc}`]
          }
          onChange={e => this.handleOrChange(e, product)}
        />
      </span>
    )
  }
  getRemoveLink = (product, cart) =>{
    return (
            <span
              className='op-clear-label'
              onClick={ cart && cart.TypeId === 201 ?
                () => this.removeItem(product.number, product.rdcNum, product.month, product.model, product.vendorId, cart.TypeId,cart) :
                () => this.removeItem(product.item_nbr, product.rdcNum, product.month, product.model, product.vendorId, cart.TypeId,cart )
              }
            >
            <Popup
              trigger={<Button icon='trash alternate outline' />}
              content="Remove"
              size='mini'
              position='top right'
              basic
            />
            </span>
    )
  }

  openModal(data) {
    this.setState({
      visible: true,
      quickViewItem: data
    });
    this.props.blurfun && this.props.blurfun(true);
  };

  getItemImage = (product,isPlanogram, isMonthlySpecial, cartType = 0) =>{
    let savingwithPer = '';
    if(isMonthlySpecial){
      let savings = (product.MSFuturecost !==0 ? ((product.MSFuturecost - product.promoCost) / product.MSFuturecost) * 100 :
                                      ((product.cost - product.promoCost) / product.cost) * 100).toFixed(0);
      savingwithPer = savings ? savings+ '%': '';
    }
    const { isReunionShoppingAreaCart,flowType, shoppingAreaName, shoppingAreaMetaId } = this.state;
    const pdpParams = isReunionShoppingAreaCart?getPdpParams(flowType.toString(),product)
                        :{
                          source: [{ text: 'CART', link: '/cart' }],
                          assortmentType: isPlanogram ? 'Planograms' : '',
                          isWow:product.isReqFromWow,
                          isMonthlyListing:isMonthlySpecial,
                          MonthlySpecialSaving: isMonthlySpecial ? savingwithPer : '',
                          MonthlyEventCost: isMonthlySpecial ?product.promoCost : '',
                          MonthlyRegularCost: isMonthlySpecial ? product.regCost : '',
                          MonthlyWHCost: isMonthlySpecial ? product.regCost > 0 ? product.regCost : product.cost : '',
                          monthlyStock: isMonthlySpecial ? product.MSstocked : '',
                          monthlyRelayNbr: isMonthlySpecial ? product.relay_nbr : '',
                          monthlyPromoNbr: isMonthlySpecial ? product.MSPromoNbr : '',
                          monthlyAssmentNbr: isMonthlySpecial ? product.AsstmtID : ''
                        }
    let isReunionListing = isReunionShoppingAreaCart ? true : false;
    return (
      cartType === 1 ?
      <>
        <tr> 
        <Link to={{ pathname: '/product/' + product.item_nbr,
              state: {...pdpParams}
        }}>
          <img className ='cart-product-img' alt ="Image" src={
            product.item_nbr ? `
            https://images.truevalue.com/getimage.asp?id=${product.item_nbr}&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2`
            : no_img_avlbl}
          />
        </Link> 
        </tr>
        <tr>
        <span
          className='view_button-compact-visible'
          onClick={() => {
            this.openModal(product.item_nbr);
          }}
        >
          Quick View
        </span> 
        </tr>
      </>
      :
      <Link to={{ pathname: '/product/' + product.item_nbr,
            state: {...pdpParams, isReunionListing,shoppingAreaName,shoppingAreaMetaId}
      }}>
        <img className ='cart-product-img' alt ="Image" src={
           product.item_nbr ? `
           https://images.truevalue.com/getimage.asp?id=${product.item_nbr}&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2`
           : no_img_avlbl}
         />
      </Link>
    )
  }
  getRows = (tableType, product, index, isPlanogram, isIM, isMonthlySpecial, cart) => {
    let savingwithPer = '';
    if(isMonthlySpecial){
      let savings = (product.MSFuturecost !==0 ? ((product.MSFuturecost - product.promoCost) / product.MSFuturecost) * 100 :
                                      ((product.cost - product.promoCost) / product.cost) * 100).toFixed(0);
      savingwithPer = savings ? savings+ '%': '';
    }
    let imgSrc = product
      ? 'https://images.truevalue.com/getimage.asp?id=' +
        product.item_nbr +
        '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2'
      : '';
    const favIcon = this.getFavIcon(product.item_nbr);
    const itemImg = (
      this.getItemImage(product,isPlanogram,isMonthlySpecial,cart.TypeId)
    );
    const or = this.getOverrideRetailField(product);
    let tableCells = [];
    const tieredCost = getTieredCost(product);
    const tieredCostNew = getTieredCostNew(product);
    const tierLevel = getTierLevel(product);
    const tierLevelNew = getTierLevelNew(product);
    if (tierLevel && !this.tierLevels[tierLevel]) {
      this.tierLevels[tierLevel] = true;
    }
    const viewType = this.props.viewType;
    const getPrefer = this.props.getprefer &&
    this.props.getprefer.customerView
    ? this.props.getprefer.customerView
    : 'c1'
    const showMemberCostAndLbl =  shouldHideCostForPreference(viewType, getPrefer);
    const displaySRPCost = shouldHideSUGG_RETAILForPreference(viewType, getPrefer);    
    const products = (this.props.isCheckout || this.props.isConfirmation) ? this.props.products 
                          : this.props.cart && this.props.cart.items;
    const isPalletQty =  this.props.cart?.items?.length > 0 &&  this.props.cart.items.some(ele => ele.isMultiples)
    const checkoutOverride = product.OverRideDetails || product.overrideRetail ? parseFloat(product.OverRideDetails || product.overrideRetail) : ''
    const srpCost = 
            <MaskPrice
              viewType={viewType}
              getprefer={getPrefer}
              fieldVal={product.srCost || '0'}
              field = {"Suggested Retail"}
          />
    switch (tableType) {
      case 'viewCart':
      case 'dom-cart':
        {
          const carttierLevel = tableType === 'dom-cart' ? '' : viewType === MEMBER_VIEW && ( cart.TypeId === 1 ? tierLevelNew : tierLevel );
          if(cart.TypeId === 33) {
            tableCells = [
              { cellContent: 
                  renderImages.getImage({
                    src: 'https://images.truevalue.com/getimage.asp?id=' +
                        product.item_nbr +
                        '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2',
                    alt: 'product',
                    className: 'cart-product-img',
                 })
              },
              { cellContent:  product?.IsStocked ? product.item_nbr : '-'},
              { cellContent: product.Upc},
              { cellContent: product.Model},
              { cellContent: product.ShortDescription},
              { cellContent: getPack(product)},
              { cellContent: <MaskPrice
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={product.DsCost }
              />},
              { cellContent:  <MaskPrice
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={product.SrpCost }
              />},
              { cellContent: this.getQuantity(tableType, product, index) },
              {cellContent: utilities.isBinLabel(product.userQty) ? '-' :(                
                this.props.multiStoreDetails.access !== 1  &&
                <MaskPrice
                  field = 'Member Cost black'
                  getprefer={this.props.getprefer &&
                    this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : 'c1'
                  }
                  viewType={viewType}
                  fieldVal={product.itemQty * product.DsCost }
                />
              )
            },
            {cellContent: (
              <span
                className='op-clear-label'
                onClick={() =>
                  this.removeItem(product.item_nbr, product.DsCost, product.Upc, product.Model, null, cart.TypeId, cart)
                }
              >
               <Popup
                trigger={<Button icon='trash alternate outline' />}
                content="Remove"
                size='mini'
                position='top right'
                basic
              />
              </span>
            )}
            ]
            return tableCells
          }
          tableCells = [
            { cellContent: favIcon },
            { cellContent: itemImg },
            { cellContent: this.getItemNbrLink(product) },
          ]
          if(utilities.isAliasSkuRequired(this.props.multiStoreDetails)){
            tableCells = [ ...tableCells,
              {cellContent: product.aliasSku }]
          }
          tableCells = [ ...tableCells,
            {cellContent: product.upcNum },
            { cellContent: product.model, cellClass: 't-left' },
            {
              cellContent: getDescription(product),
              cellClass: 'title-column'
            },
            { cellContent: this.getRDCContent(product, isPlanogram)},
            { cellContent: getPack(product) },
            {
              cellContent: this.getAvailableContent(product),
              cellClass:
                product.Available &&
                parseInt(product.Available) > 0 &&
                product.itemQty <= product.Available
                  ? 'color-green'
                  : 'color-red'
            }]
          if(this.state.isMaxQty){
            tableCells = [ ...tableCells,
              {
                cellContent: product.maxQtyItemRestriction ? this.getMaxQty(product): '',
                cellClass : product.maxQtyItemRestriction > 0 ? 't-center' :'hide-max-qty t-center'
              }]
          }
          if(tableType === 'viewCart' && viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [ ...tableCells,
            {
              cellContent: utilities.isBinLabel(product.userQty) ? '-' :(
                this.props.multiStoreDetails.access !== 1 && tableType !=='dom-cart' ? showMemberCostAndLbl && 
                <MaskPrice
                  field='Member Cost black'
                  getprefer={getPrefer}
                  viewType={tableType==='dom-cart' ? 'Member View' : viewType}
                  fieldVal={tableType==='dom-cart' ? product.promoCost : (cart.TypeId === 1 ? tieredCostNew : tieredCost)} />
                  :
                <MaskPrice
                  field='Member Cost black'
                  getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={'Member View'}
                fieldVal={tableType==='dom-cart' ? product.promoCost : (cart.TypeId === 1 ? tieredCostNew : tieredCost)} />
              ),
              ...!utilities.isBinLabel(product.userQty) && {
              isTiered: tableType === 'dom-cart' ? '' : viewType === MEMBER_VIEW ? product.tiered : '',
              tierLevel : carttierLevel,
              }
            }]
          }
          if(tableType === 'viewCart' && cart.TypeId === 1){
            if(viewType !== MEMBER_VIEW && getPrefer === "c3"){
              tableCells = [ ...tableCells]
            } else {
            tableCells = [ ...tableCells,
              { cellContent: displaySRPCost ? srpCost : '' }]

            }
          }
          tableCells = [ ...tableCells,
            { cellContent: this.getQuantity(tableType, product, index) },
            { cellContent: or }]
          if(tableType === 'viewCart' && viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          }
          else {
          tableCells = [ ...tableCells,
            {
              cellContent: utilities.isBinLabel(product.userQty) ? '-' :(                
                this.props.multiStoreDetails.access !== 1  &&
                <MaskPrice
                  field = 'Member Cost black'
                  getprefer={this.props.getprefer &&
                    this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : 'c1'
                  }
                  viewType={tableType==='dom-cart' ? 'Member View' : viewType}
                  fieldVal={tableType==='dom-cart' ? product.itemQty * product.promoCost : (cart.TypeId === 1 ? (tieredCostNew ? product.itemQty * tieredCostNew : ''): (tieredCost ? product.itemQty * tieredCost:''))}
                />
              )
            }]
          }
            tableCells = [ ...tableCells,
            {
              cellContent: (
                this.getRemoveLink(product, cart)
              )
            }]
         break;
        }
      case 'im-cart': {
        tableCells =  this.impCartviewVal(product,viewType,tieredCost,tierLevel,favIcon,itemImg,tableType,index);
        break;
      }
      case 'checkout': {
        tableCells = [{ cellContent: product.ItemNum }]
        if(utilities.isAliasSkuRequired(this.props.multiStoreDetails)){
          tableCells = [ ...tableCells, {cellContent: product.aliasSku }]
        }
        if(cart.TypeId === 33) {
          return tableCells = [
            { cellContent: product.item_nbr},
              { cellContent: product.Upc},
              { cellContent: product.Model},
              { cellContent: product.ShortDescription},
              { cellContent: getPack(product) },
              { cellContent: <MaskPrice
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={product.DsCost }
              />},
              { cellContent:  <MaskPrice
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={product.SrpCost }
              />},
              { cellContent:product.itemQty},
              {cellContent: utilities.isBinLabel(product.userQty) ? '-' :(                
                this.props.multiStoreDetails.access !== 1  &&
                <MaskPrice
                  field = 'Member Cost black'
                  getprefer={this.props.getprefer &&
                    this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : 'c1'
                  }
                  viewType={viewType}
                  fieldVal={product.itemQty * product.DsCost }
                />
              )
            },
          ]
        }
        tableCells = [ ...tableCells,
          { cellContent: product.Upc },
          { cellContent: product.model, cellClass: 't-left' },
          {
            cellContent: getDescription(product,false,true),
            cellClass: 'title-column'
          },
          { cellContent: `${utilities.rdcBasedonCentralship(product.rdcType, product.centralShipRDCNum, product.RDC)} 
                          ${this.props.multiStoreDetails.PrimaryWarehouseType === WAREHOUSETYPE_S && product.rdcType === RDCTYPE_H 
                            ? HUB : ''}` },
          { cellContent: getPack(product) }
           ]
           if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
            tableCells = [ ...tableCells,{
            cellContent: utilities.isBinLabel(product.itemQty?product.itemQty:product.Qty) ? '-' :(
            this.props.multiStoreDetails.access !== 1  ? showMemberCostAndLbl && 
            <MaskPrice 
              field = 'Member Cost black'
              getprefer={getPrefer}
              viewType={viewType} 
              fieldVal={tieredCostNew} />
            :
            <MaskPrice 
              field = 'Member Cost black'
              getprefer={this.props.getprefer &&
                this.props.getprefer.customerView
                ? this.props.getprefer.customerView
                : 'c1'
              }
              viewType={'Member View'} 
              fieldVal={cart.TypeId === 1 ? tieredCostNew : tieredCost} />
            ),
            ...!utilities.isBinLabel( product.itemQty?product.itemQty:product.Qty) && {
            isTiered: viewType === MEMBER_VIEW ? product.tiered : '',
            tierLevel: cart.TypeId === 1 ? tierLevelNew : tierLevel
            }
          }]
          }
          if(cart.TypeId === 1){
            if(viewType !== MEMBER_VIEW && getPrefer === "c3"){
              tableCells = [ ...tableCells]
            } else {
            tableCells = [ ...tableCells,
              { cellContent: displaySRPCost ? srpCost : '' }]
            }
          }
          tableCells = [ ...tableCells,
          { cellContent: product.itemQty?product.itemQty:product.Qty },
          {
            cellContent: (
              <span>
                {product.OverRideDetails === '0.00'
                  ? ''
                  : '$' + product.OverRideDetails}
              </span>
            )
          }]
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          }
          else {
          tableCells = [ ...tableCells,
          {
            cellContent: utilities.isBinLabel(product.itemQty?product.itemQty:product.Qty) ? '-' :(
              this.props.multiStoreDetails.access !== 1 && <MaskPrice
                viewType={viewType}
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                fieldVal={cart.TypeId === 1  ? (tieredCostNew?product.Qty*tieredCostNew:'') : (tieredCost?product.Qty*tieredCost:'')}
              />
            )
          }]
        }
          tableCells = [ ...tableCells,
          { cellContent: utilities.isBinLabel( product.itemQty?product.itemQty:product.Qty) ? '-':parseFloat(product.Ext_weight).toFixed(2) + ' lbs' }
        ];
        break;
      }
      case 'ms-confirmation':
      case 'event-checkout': {
        const fieldEnum = [
          product.userQty,
          product.userQty2,
          product.userQty3,
          product.userQty4
        ];
        tableCells = [
          { cellContent: product.item_nbr },
          utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
            {cellContent: product.aliasSku }),
          { cellContent: product.upcNum },
          { cellContent: product.model, cellClass: 't-left' },
          {
            cellContent: isMonthlySpecial ? getDescription(product,false,false,false,true):getDescription(product),
            cellClass: 'title-column'
          },
          { cellContent: getPack(product) }]
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
            tableCells = [ ...tableCells,
          {
            cellContent: (
              this.props.multiStoreDetails.access !== 1  && 
              <MaskPrice 
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType} 
                fieldVal={product.promoCost ? product.promoCost : 0} />
            )
          }]
        }
        tableCells = [ ...tableCells,
          { cellContent: parseInt(product.OverRideDetails || product.overrideRetail) === 0 || isNaN(parseInt(product.OverRideDetails || product.overrideRetail)) ? '':  '$'+ (product.OverRideDetails || product.overrideRetail)},
          ...!utilities.isEmptyOrNullString(product.ShipDate1)?[{ cellContent: product.userQty}]:[],
          ...!utilities.isEmptyOrNullString(product.ShipDate2)?[{ cellContent: product.userQty2}]:[],
          ...!utilities.isEmptyOrNullString(product.ShipDate3)?[{ cellContent: product.userQty3}]:[],
          ...!utilities.isEmptyOrNullString(product.ShipDate4)?[{ cellContent: product.userQty4}]:[],
        ]
        if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
          tableCells = [ ...tableCells]
        } else {
          tableCells = [ ...tableCells,        
          {
            cellContent:  utilities.isBinLabel(product.Qty) ? '-' :(
              this.props.multiStoreDetails.access !== 1  &&  
              <MaskPrice
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={findTotal(fieldEnum) * (product.promoCost ? product.promoCost : 0)}
              />
            )
          }
        ];
      }
        break;
      }
      case 'im-checkout':{
        tableCells =  this.impCartcheckoutVal(product,viewType,tieredCost,tierLevel);
        break;
      }
      case 'pl-checkout': {
        tableCells = [
          { cellContent: (product.item_nbr || product.ItemNum) },
          utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
            {cellContent: product.aliasSku }),
          { cellContent: (product.upcNum  ||  product.Upc)},
          { cellContent: product.model, cellClass: 't-left' },
          {
            cellContent: getDescription(product),
            cellClass: 'title-column'
          },
          { cellContent: getPack(product) },
          { cellContent: product.Available }
        ]
        if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
          tableCells = [ ...tableCells]
        } else {
        tableCells = [...tableCells,
          {
            cellContent: utilities.isBinLabel(product.userQty || product.Qty) ? '-' :(
              this.props.multiStoreDetails.access !== 1  ? showMemberCostAndLbl && 
            <MaskPrice 
              field = 'Member Cost black'
              getprefer={getPrefer}
              viewType={viewType} 
              fieldVal={tieredCostNew} />
            :
            <MaskPrice 
              field = 'Member Cost black'
              getprefer={this.props.getprefer &&
                this.props.getprefer.customerView
                ? this.props.getprefer.customerView
                : 'c1'
              }
              viewType={'Member View'} 
              fieldVal={cart.TypeId === 1 ? tieredCostNew : tieredCost} />
            ),
            ...!utilities.isBinLabel(product.userQty || product.Qty) && {
            isTiered: viewType === MEMBER_VIEW ? product.tiered : '',
            tierLevel: viewType === MEMBER_VIEW ? tierLevel : ''
            }
          }]
        }
          tableCells = [ ...tableCells,
          {
            cellContent: (
              <span>
                {product.OverRideDetails || product.overrideRetail === '0.00'
                  ? ''
                  : '$' + (checkoutOverride ? Number(checkoutOverride).toFixed(2) : '')}
              </span>
            )
          },
          { cellContent: utilities.emptyForNull(product.recQty) },
          { cellContent: (product.userQty || product.Qty) }]
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [...tableCells,
          {
            cellContent:utilities.isBinLabel(product.userQty || product.Qty) ? '-': (
              this.props.multiStoreDetails.access !== 1  && 
              <MaskPrice
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={(product.userQty || product.Qty)  * tieredCost}
              />
            )
          }
        ];
        }
        break;
      }
      case 'dom-checkout':
      case 'dom-confirmation': {
          tableCells = [
            { cellContent: product.item_nbr },
            utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
              {cellContent: product.aliasSku }),
            { cellContent: product.upc },
            { cellContent: product.model, cellClass: 't-left' },
            {
              cellContent: getDescription(product),
              cellClass: 'title-column'
            },
            { cellContent: this.getRDCContent(product) },
            { cellContent: getPack(product) },
            { cellContent: utilities.isBinLabel(product.itemQty) ? '-':(
              this.props.multiStoreDetails.access !== 1  && <MaskPrice 
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={'Member View'} 
                fieldVal={product.promoCost ? product.promoCost: 0} />
              ),
            },
            {
              cellContent: (
                <span>
                  {product.overrideRetail === '0.00'
                    ? ''
                    : '$' + product.overrideRetail}
                </span>
              )
            },
            { cellContent: product.itemQty },
            {
              cellContent: utilities.isBinLabel(product.itemQty) ? '-' :(
                this.props.multiStoreDetails.access !== 1 && <MaskPrice
                  viewType={'Member View'}
                  field = 'Member Cost black'
                  getprefer={this.props.getprefer &&
                    this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : 'c1'
                  }
                  fieldVal={(product.promoCost ? product.promoCost: 0)*product.itemQty}
                />
              )
            }
          ];
          break;
        }
      case 'confirmation':
        if(cart.TypeId === 33) {
          return tableCells = [
            { cellContent: product.item_nbr},
              { cellContent: product.Upc},
              { cellContent: product.Model},
              { cellContent: product.ShortDescription},
              { cellContent: getPack(product) },
              { cellContent: <MaskPrice
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={product.DsCost }
              />},
              { cellContent:  <MaskPrice
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={product.SrpCost }
              />},
              { cellContent:product.itemQty},
              {cellContent: utilities.isBinLabel(product.userQty) ? '-' :(                
                this.props.multiStoreDetails.access !== 1  &&
                <MaskPrice
                  field = 'Member Cost black'
                  getprefer={this.props.getprefer &&
                    this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : 'c1'
                  }
                  viewType={viewType}
                  fieldVal={product.itemQty * product.DsCost }
                />
              )
            },
          ]
        }
        tableCells = [
          { cellContent: product.ItemNum || product.item_nbr }]
        if(utilities.isAliasSkuRequired(this.props.multiStoreDetails)){
          tableCells = [ ...tableCells,
            {cellContent: product.aliasSku }]
        }
        tableCells = [ ...tableCells,
          { cellContent: product.Upc || product.upcNum },
          { cellContent: product.model, cellClass: 't-left' },
          {
            cellContent: isMonthlySpecial ? getDescription(product,false,false,false,true):getDescription(product),
            cellClass: 'title-column'
          },
          { cellContent: (isPlanogram || isIM || isMonthlySpecial) ? product.rdcNum : `${utilities.rdcBasedonCentralship(product.rdcType, product.centralShipRDCNum, product.RDC)} 
                          ${this.props.multiStoreDetails.PrimaryWarehouseType === WAREHOUSETYPE_S && product.rdcType ===RDCTYPE_H 
                            ? HUB : ''}` }
          ]
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [ ...tableCells, {
            cellContent: utilities.isBinLabel(isPlanogram ? product.userQty :product.itemQty ? product.itemQty : product.Qty && product.Qty !== 0 ? product.Qty : product.userQty) ? '-' :(
              this.props.multiStoreDetails.access !== 1  &&
              cart.cartType === 1 ? showMemberCostAndLbl && 
              <MaskPrice 
                field = 'Member Cost black'
                getprefer={getPrefer}
                viewType={viewType} 
                fieldVal={tieredCost} />
              :
              <MaskPrice 
                field = 'Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType} 
                fieldVal={tieredCostNew} />
            ),
            ...!utilities.isBinLabel(isPlanogram ? product.userQty :product.itemQty ? product.itemQty : product.Qty && product.Qty !== 0 ? product.Qty : product.userQty) && {
              isTiered: viewType === MEMBER_VIEW ? product.tiered : '',
              tierLevel: tierLevelNew
            }
          }]
        }
          if(cart?.cartType === 1){
            if(viewType !== MEMBER_VIEW && getPrefer === "c3"){
              tableCells = [ ...tableCells]
            } else {
            tableCells = [ ...tableCells,
              { cellContent: displaySRPCost ? srpCost : '' }]
            }
          }
          tableCells = [ ...tableCells,
          { cellContent: parseInt(product.OverRideDetails || product.overrideRetail) === 0 || isNaN(parseInt(product.OverRideDetails || product.overrideRetail)) 
              ? '':  
              '$'+ (product.OverRideDetails || product.overrideRetail ? parseFloat(product.OverRideDetails || product.overrideRetail).toFixed(2) : '')},
          { cellContent: isPlanogram ? product.userQty :product.itemQty ? product.itemQty : product.Qty && product.Qty !== 0 ? product.Qty : product.userQty }]
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [ ...tableCells,
          
          {
            cellContent: utilities.isBinLabel(isPlanogram ? product.userQty :product.itemQty ? product.itemQty : product.Qty && product.Qty !== 0 ? product.Qty : product.userQty) ? '-' : (
              this.props.multiStoreDetails.access !== 1  && 
              <MaskPrice
                field ='Member Cost black'
                getprefer={this.props.getprefer &&
                  this.props.getprefer.customerView
                  ? this.props.getprefer.customerView
                  : 'c1'
                }
                viewType={viewType}
                fieldVal={ tieredCostNew && utilities.isValidQuantity(product.userQty || product.Qty) ? (product.userQty || product.Qty) * tieredCostNew : ''}
              />
            )
          }]}
          tableCells = [ ...tableCells,
          { cellContent: this.props.status || 'Success' }
        ];
        break;
      case 'ra-cart':
        tableCells = [
          { cellContent: product.deptName || '' },
          {
            cellContent: (
              <span  className='checkoutPointer'  
                onClick={() => this.getAssortmentLink(product,4)}>
                {utilities.emptyForNull(product.model)}
              </span>
            )
          },
          { cellContent: getDescription(product,true) }]
          if(viewType === MEMBER_VIEW){
            tableCells = [ ...tableCells,
              { cellContent: getAssortmentSavings(product,products)}];
          } else {
            tableCells = [ ...tableCells]
          }
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [ ...tableCells,
            { cellContent: getAssortmentCreditValue(product,products, viewType, getPrefer )}];
          }
          tableCells = [ ...tableCells,
          { cellContent: product.delivery? product.delivery: '-' },
          { cellContent: this.getQuantity(tableType, product, index) }]
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [ ...tableCells,
          { cellContent: utilities.isBinLabel(product.userQty) ? '-' : <MaskPrice 
                          field = 'Member Cost black'
                          getprefer={this.props.getprefer &&
                            this.props.getprefer.customerView
                            ? this.props.getprefer.customerView
                            : 'c1'
                          }
                          viewType={viewType} 
                          fieldVal={ product.cost * product.userQty} /> }
        ];
      }
        break;
      case 'ctb-cart':
        tableCells = [
          { cellContent: product.deptName || '' },
          { cellContent: getDescription(product,true,null,44)},
          {
            cellContent: (
              <span  className={product?.change_ship_month === "Y" ? 'pogNumberHighlight' : 'checkoutPointer'  }
                onClick={() => this.getAssortmentLink(product,44)}>
                {utilities.emptyForNull(product.ctb_pog_nbr)}
              </span>
            )
          },          
          { cellContent: utilities.hyphenForZeroPercentage(product.savings)},
          { cellContent:_.isEmpty(product.creditValue) || parseInt(product.creditValue) === 0 ? '-' : `$${product.creditValue}`},
          { cellContent: product.isMissingSelected? "Missing": 'All' },
          { cellContent: (<span  className={product?.change_ship_month === "Y" ? 'pogNumberHighlight' : ''  }> { product.delivery  }</span>)},
          // { cellContent: this.getQuantity(tableType, product, index) },
          { cellContent: <MaskPrice 
                          field = 'Member Cost black'
                          getprefer={this.props.getprefer &&
                            this.props.getprefer.customerView
                            ? this.props.getprefer.customerView
                            : 'c1'
                          }
                          viewType={'Member View'} 
                          fieldVal={ product.cost * product.userQty} /> }
        ];
        break;
      case 'ra-checkout':
        tableCells = [
          { cellContent: product.deptName || '' },
          {
            cellContent: (
              <span  className='checkoutPointer'  
                onClick={() => this.getAssortmentLink(product,4)}>
                {utilities.emptyForNull(product.model)}
              </span>
            )
          },
          { cellContent: getDescription(product,true,true) }]
          if(viewType === MEMBER_VIEW){
            tableCells = [ ...tableCells,
              { cellContent: getAssortmentSavings(product,products)}];
          } else {
            tableCells = [ ...tableCells]
          }
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [ ...tableCells,
            {cellContent: getRAMessage(product,products,this.props.marketFlag, true, true, viewType, getPrefer)}]
          }
          tableCells = [ ...tableCells,
          { cellContent: product.delivery? product.delivery: '-' },
          { cellContent: this.getQuantity(tableType, product, index) }]
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [ ...tableCells,
          { cellContent:  utilities.isBinLabel(product.userQty) ? '-' :<MaskPrice 
                            field ='Member Cost black'
                            getprefer={this.props.getprefer &&
                              this.props.getprefer.customerView
                              ? this.props.getprefer.customerView
                              : 'c1'
                            }
                            viewType={viewType} 
                            fieldVal={ product.cost * product.userQty } /> }
        ];
      }
        break;
        case 'ctb-checkout':
          tableCells = [
            { cellContent: product.deptName || '' },
            
            { cellContent: getDescription(product,true,null,44)},
            {
              cellContent: (
                <span  className='checkoutPointer'  
                  onClick={() => this.getAssortmentLink(product,44)}>
                  {utilities.emptyForNull(product.ctb_pog_nbr)}
                </span>
              )
            },
            { cellContent: utilities.hyphenForZeroPercentage(product.savings)},
            { cellContent:_.isEmpty(product.creditValue) || parseInt(product.creditValue) === 0 ? '-' : `$${product.creditValue}`},
            {cellContent: product.isMissingSelected? "Missing": 'All' },
            { cellContent: product.delivery? product.delivery: '-' },
            { cellContent:  <MaskPrice 
                              field ='Member Cost black'
                              getprefer={this.props.getprefer &&
                                this.props.getprefer.customerView
                                ? this.props.getprefer.customerView
                                : 'c1'
                              }
                              viewType={'Member View'} 
                              fieldVal={ product.cost * product.userQty } /> }
          ];
          break;
      case 'ra-confirmation':
        tableCells = [
          { cellContent: product.deptName || '' },
          {
            cellContent: (
              <span  className='checkoutPointer'  
                onClick={() => this.getAssortmentLink(product,4)}>
                {utilities.emptyForNull(product.model)}
              </span>
            )
          },
          { cellContent: getDescription(product,true) }]
          if(viewType === MEMBER_VIEW){
            tableCells = [ ...tableCells,
              { cellContent: getAssortmentSavings(product,products)}];
          } else {
            tableCells = [ ...tableCells]
          }
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
            tableCells = [ ...tableCells,
              {cellContent: getRAMessage(product,products,this.props.marketFlag, true, true, viewType, getPrefer)}]
          }
          tableCells = [ ...tableCells,
          { cellContent: product.delivery? product.delivery: '-' },
          { cellContent: this.getQuantity(tableType, product, index) }]
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [ ...tableCells,
          { cellContent:  utilities.isBinLabel(product.userQty) ? '-' :<MaskPrice 
                            field ='Member Cost black'
                            getprefer={this.props.getprefer &&
                              this.props.getprefer.customerView
                              ? this.props.getprefer.customerView
                              : 'c1'
                            }
                            viewType={viewType} 
                            fieldVal={ product.cost * product.userQty } /> }]
         }
        tableCells = [ ...tableCells,
          { cellContent: this.props.status || 'Success' }
        ];
      
        break;
      case 'ctb-confirmation':
        tableCells = [
          { cellContent: product.deptName || '' },          
          { cellContent: getDescription(product,true,null,44)},
          {
            cellContent: (
              <span  className='checkoutPointer'  
                onClick={() => this.getAssortmentLink(product,44)}>
                {utilities.emptyForNull(product.ctb_pog_nbr)}
              </span>
            )
          },
          { cellContent: utilities.hyphenForZeroPercentage(product.savings)},
          { cellContent:_.isEmpty(product.creditValue) || parseInt(product.creditValue) === 0 ? '-' : `$${product.creditValue}`},
          {cellContent: product.isMissingSelected? "Missing": 'All' },
          { cellContent: product.delivery? product.delivery: '-' },
          { cellContent:  <MaskPrice 
                            field ='Member Cost black'
                            getprefer={this.props.getprefer &&
                              this.props.getprefer.customerView
                              ? this.props.getprefer.customerView
                              : 'c1'
                            }
                            viewType={'Member View'} 
                            fieldVal={ product.cost * product.userQty } /> },
          { cellContent: this.props.status || 'Success' }
        ];
        break;
      case 'event-cart':
        const eventCost = product.promoCost ? product.promoCost : 0;
        tableCells = [
          { cellContent: favIcon },
          { cellContent: itemImg },
          { cellContent: this.getItemNbrLink(product, isMonthlySpecial, savingwithPer) },
          utilities.isAliasSkuRequired(this.props.multiStoreDetails) && (
           { cellContent: product.aliasSku }),
          { cellContent: product.upcNum },
          { cellContent: product.model, cellClass: 't-left' },
          {
            cellContent: isMonthlySpecial ? getDescription(product,false,false,false,true): getDescription(product),
            cellClass: 'title-column'
          },
          { cellContent: getPack(product) },
          this.state.isMaxQty && ({cellClass: 't-center', cellContent: product.maxQtyItemRestriction ? this.getMaxQty(product): ''})]
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [ ...tableCells,
          {
            cellContent:utilities.isBinLabel(product.userQty) ? 0 :<MaskPrice 
            field = 'Member Cost black'
            getprefer={this.props.getprefer &&
              this.props.getprefer.customerView
              ? this.props.getprefer.customerView
              : 'c1'
            }
            viewType={viewType} 
            fieldVal={eventCost}
              />,
            isTiered: false
          }  ]}
          tableCells = [ ...tableCells,
          ...!utilities.isEmptyOrNullString(product.ShipDate1)?
                      [{ cellContent: this.getQuantity(tableType, product, index, 0)}]:[],
          ...!utilities.isEmptyOrNullString(product.ShipDate2)?
                      [{ cellContent: this.getQuantity(tableType, product, index, 1)}]:[],
          ...!utilities.isEmptyOrNullString(product.ShipDate3)?
                      [{ cellContent: this.getQuantity(tableType, product, index, 2)}]:[],
          ...!utilities.isEmptyOrNullString(product.ShipDate4)?
                      [{ cellContent:this.getQuantity(tableType, product, index, 3)}]:[],
          { cellContent: or }]
          if(viewType !== MEMBER_VIEW && (getPrefer === "c3"  || getPrefer === "c4")){
            tableCells = [ ...tableCells]
          } else {
          tableCells = [ ...tableCells,
          {
            cellContent:utilities.isBinLabel(product.userQty) ? '-' :
            <MaskPrice 
            field = 'Member Cost black'
            getprefer={this.props.getprefer &&
              this.props.getprefer.customerView
              ? this.props.getprefer.customerView
              : 'c1'
            }
            viewType={viewType} 
            fieldVal={(product.userQty +
              product.userQty2 +
              product.userQty3 +
              product.userQty4) *
              eventCost
              }
              />} ]
            }
          
            tableCells = [ ...tableCells,
          {
            cellContent: (
              <span
                className='op-clear-label'
                onClick={() =>
                  this.removeItem(product.item_nbr,  product.rdcNum, product.month, product.model, product.vendorId, this.props.cart.TypeId, this.props.cart)
                }
              >
               <Popup
                trigger={<Button icon='trash alternate outline' />}
                content="Remove"
                size='mini'
                position='top right'
                basic
              />
              </span>
            )
          }
        ];
        break;
      default:
        break;
    }
    if (tableType === 'ra-cart' || tableType === 'ctb-cart' ) {
      tableCells.push({
        cellContent: (
          <span
            className='op-clear-label'
            onClick={() =>
              this.removeItem(tableType === 'ctb-cart' ? product.model :product.number, this.props.rdc, product.month, product.model, product.vendorId, cart.TypeId, cart)
            }
          >
            <Popup
              trigger={<Button icon='trash alternate outline' />}
              content="Remove"
              size='mini'
              position='top right'
              basic
            />
          </span>
        )
      });
    } else if (tableType === 'ra-confirmation' || tableType === 'ctb-confirmation') {
      tableCells.push({
        cellContent: <></>
      });
    }
    return tableCells;
  };

  checkInventoryAvailable = (items) => {
    //let currentCstTime = new Date(new Date().toLocaleString("en-US", {timeZone: 'America/Chicago'}));
    let currentCstTime = new Date();
    let isInventoryNotAvailable = items.some(item=> {
      return ((currentCstTime - new Date(item.lastModified)) / (1000 * 60 * 60)) > 12;
    });
    return isInventoryNotAvailable;
  }

  render() {
    const { cart ,multiStoreCartId, getMultiStoreCart } = this.props;
    const isCheckout = this.props.isCheckout ? this.props.isCheckout : false;
    const isConfirmation = this.props.isConfirmation ? this.props.isConfirmation : false;
    const products = (isCheckout || isConfirmation) ? this.props.products : cart.items;
    const isViewCart = !isCheckout && !isConfirmation;
    const isRetailAssortment = cart.TypeId === 4;
    const isCtbAssortment = cart.TypeId === 44;
    const isEvents = cart.TypeId === 9;
    const isPlanogram = cart.TypeId === 5;
    const isCloseOutCorner = cart.TypeId === 3;
    const isIM = cart.TypeId === 7;
    const isMonthlySpecial = cart.TypeId === 10;
    const isDOM = cart.TypeId === orderNames["DOM"].infoType;
    const isCartExpired = isDOM &&
                             cart.items && 
                             cart.items.length>0 && 
                             utilities.isPastDate(cart.items[0].promoExpiryDate)
    const isInventoryAvailable =  !isCloseOutCorner || !(isCloseOutCorner && this.checkInventoryAvailable(cart.items))                           
    const pageType = isCheckout?'checkout':isConfirmation?'confirmation':'view-cart';
    const tableType = isCheckout
                    ? isRetailAssortment
                      ? 'ra-checkout'
                      : isCtbAssortment
                      ? 'ctb-checkout'
                      : isEvents || isMonthlySpecial
                      ? 'event-checkout'
                      : isPlanogram
                      ? 'pl-checkout'
                      : isIM
                      ? 'im-checkout'
                      : isDOM
                      ? 'dom-checkout'
                      : 'checkout'
                    : isConfirmation
                    ? isRetailAssortment
                      ? 'ra-confirmation'
                      : isCtbAssortment
                      ? 'ctb-confirmation'
                      : isMonthlySpecial
                      ? 'ms-confirmation'
                      : isDOM
                      ? 'dom-confirmation'
                      : 'confirmation'
                    : isRetailAssortment
                    ? 'ra-cart'
                    : isCtbAssortment
                    ? 'ctb-cart'
                    : isIM
                    ? 'im-cart'
                    : isEvents || isMonthlySpecial
                    ? 'event-cart'
                    : isDOM
                    ? 'dom-cart'
                    : 'viewCart';
    const { isReunionShoppingAreaCart,flowType} = this.state;
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const headers = isReunionShoppingAreaCart?
                          getHeaders(flowType,cart,pageType,this.props.multiStoreDetails,this.props.viewType, getPrefer )
                        :this.getHeaders(tableType);
    const maskAndHideTotalCost  = shouldHideCostForPreference(this.props.viewType,this.props.getprefer.customerView); 
    const ctbShipDateChange  = products.filter(e=>e.pass_max_ship === "Y").map(function (product) {
      return product.ctb_pog_nbr;
    }) ;
     return (
      <>
        {products && (
          <>
            {this.state.isLoading && <Loader active/>}
            <div>
             {isViewCart && Object.keys(this.props?.childStoreList).length > 1 
             ? <>
             {this.state.tableType ==="viewCart" && this.props.showLoader && (
              <div class="cartFixedLoader">
                <Loader active />
              </div>
            )}
             {this.props.cart.TypeId !== 33 && this.props.cartType && this.props.newCartTypeExist &&
                <div className='t-center'>
                  <button className='ui red button checkout-btn' disabled={!isInventoryAvailable ||this.props.showLoader || (isCtbAssortment && ctbShipDateChange.length>0)} onClick={() => this.checkoutSelected(multiStoreCartId)}>
                    Checkout Selected
                  </button>
                  <button className='ui red button checkout-btn' disabled={!isInventoryAvailable ||  this.props.showLoader || (isCtbAssortment && ctbShipDateChange.length>0)} onClick={() => this.checkoutAll(multiStoreCartId)}>
                    Checkout All
                  </button>
                </div>}
                {cart?.items?.length > 0 &&
                <>
                <Responsive maxWidth='1024'>
                  {this.props.cart.TypeId !== 33 && (
                  <div className='cart-accordion t-center'>
                    <div>
                      {'Store: '}{utilities.getChildStoreDetails(this.props?.multiStoreDetails, cart.userId)}
                    </div>
                    <div className='Multi-cart-accordion-store-select'>
                    {'Select Store '} <input
                      type='checkbox'
                      name = {'storeCheck'+cart.userId}
                      onChange={() => this.handleStoreCheck(cart.storeId, multiStoreCartId)}
                    />
                    </div>
                   </div>                       
                  )}
                </Responsive>
                <Responsive minWidth='1025'>
                  <div className='cart-accordion t-center'>
                  {this.props.cart.TypeId !== 33 && (
                    <>
                      <span>
                        {'Store: '}{utilities.getChildStoreDetails(this.props?.multiStoreDetails, cart.userId)}
                      </span>
                      <span className='Multi-cart-accordion-store-select'>
                      {'Select Store '} <input
                        type='checkbox'
                        name = {'storeCheck'+cart.userId}
                        onChange={() => this.handleStoreCheck(cart.storeId, multiStoreCartId)}
                      />
                      </span>
                    </>
                    )}
                    {isReunionShoppingAreaCart ? (
                           exportExcel({
                             className: "exportCart",
                             onClick: () =>
                            cartExcelExport(
                                 [cart],
                                 this.props.getprefer,
                                 this.props.viewType,
                                 this.props?.aliasSKURetailerGrpID,
                                this.props?.marketFlag,
                                this.props.marketDate,
                                this.props?.storeId,
                                this.props.address?.storeName
                               ),
                           })
                         ) : (
                           <ExcelExport
	                             className="exportCart"
                            fileName={"cartExport"}
                             viewType={this.props.viewType}
                             status={'Cart'}
                             order={[cart]}
                             RAMarketFlag={this.props.RAMarketFlag}
                             RAMarketDate={this.props.RAMarketDate}
                             aliasSKURetailerGrpID={this.props?.aliasSKURetailerGrpID}
                             getprefer={
                              this.props.getprefer &&
                              this.props.getprefer.customerView
                              ? this.props.getprefer.customerView
                              : 'c1'
                            }
                             multiStoreDetails = {this.props?.multiStoreDetails}
                             storeId={this.props.storeId}
                             storeName={this.props.address?.storeName}
                             isPlanogram={isPlanogram}
                             favorites = {this.props.favorites}
                           />
                        )}
                  </div>
                </Responsive>
                </>
              }
             </>
            :
              null
            }
              {isConfirmation && (
                <div className="order-summary-sub">
                  {this.props.shipTo && this.props.cart.TypeId === 33 ? (
                    <>
                    <span className='order-summary'>Ship To:&ensp;</span>
                    <span className='ds_shipping ds_confirmationShipping'>
                                <div> <p>{this.props.shipTo.addressLine1}</p> <p>{this.props.shipTo.addressLine2}</p>  <p>{this.props.shipTo.City}</p><p>{this.props.shipTo.stateCode}</p>
                                  <p>{this.props.shipTo.postalCode} </p></div></span>

                    </>
                  ):(
                    <>
                               <span className='order-summary'>Ship To:&ensp;{this.props.shipTo}</span>
                  { maskAndHideTotalCost ? <>
                  {!isRetailAssortment && !isCtbAssortment && ! this.state.isReunionShoppingAreaCart && !isPlanogram && !isDOM && !isIM && !isMonthlySpecial &&
                   <span className='subTotal order-summary'>{utilities.getLabelsByType(this.props.viewType,"SUB_TOTAL", getPrefer)}:&emsp;
                      <span className='cost_red_color'>
                        {parseInt(this.props.subTotal) ===0 ? '-' :
                        <MaskPrice
                          field = 'Member Cost black'
                          getprefer={this.props.getprefer &&
                            this.props.getprefer.customerView
                            ? this.props.getprefer.customerView
                            : 'c1'
                          }
                          viewType={tableType==='dom-cart' ? 'Member View' : this.props.viewType}
                          fieldVal={this.props.subTotal}
                        />}
                      </span>
                    </span> } </>
                   
                   : "" }
                                     <br/>
                    </>
                  )}
       
                  <span className='order-summary'>
                    Shipping Method: {(isRetailAssortment || isCtbAssortment || this.state.isReunionShoppingAreaCart|| isPlanogram || isIM || isDOM || isMonthlySpecial) ?'Regular Delivery':this.props.shippingMethod}
                  </span>
                 
                </div>
              )}
              {isCheckout && cart?.items?.length > 0 && <p style={{ lineHeight: 1,fontWeight: "bold",padding: 0, textAlign: "right",marginBottom: '0.2rem'}}>{'Store: '}{utilities.getChildStoreDetails(this.props?.multiStoreDetails, this.props?.userId)}</p>}
              {((cart?.items?.length > 0 && !isConfirmation ) || (isConfirmation))  &&
             <table id={isRetailAssortment || isCtbAssortment ? 'retail_cart' : isReunionShoppingAreaCart? 'reunion_offering_cart_'+flowType :'cart_products_tbl'} className= {tableType==='viewCart' ? 'twoCol cart-products':'cart-products'} data-ctb={isCtbAssortment? "true":'false'} data-directShip={this.props.cart.TypeId === 33 ? true : false}>
                <thead id='orderConfirmation' data-isCheckout = {this.props.isCheckout && this.props.cart.TypeId === 33 ?  "true": "false"}>
                  <tr>
                    {headers.map((cell, index) => {
                      return (
                        <th key={index}>
                          <div>{cell.label}</div>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {products.map((product, index) => {
                    if (!product) return null;                    
                    const id = product.item_nbr !==0 ? product.item_nbr:product.number !==0 ? product.number  : product.model
                    let isInventoryNotAvailable =   isCloseOutCorner 
                                    && this.checkInventoryAvailable([product]);
                    const isPalletQty =  this.props.cart?.items?.length > 0 &&  this.props.cart.items.some(ele => ele.isMultiples)
                    const rows = isReunionShoppingAreaCart?
                      getRow(
                          flowType,
                          product,this.getFavIcon(product.item_nbr),
                          this.getOverrideRetailField(product),
                          this.getQuantity,
                          this.getRemoveLink(product,cart),
                          this.getItemImage(product,false,flowType==="1"),
                          index,
                          products,
                          this.props.getprefer,
                          this.props.viewType,
                          this.props.marketDate,
                          this.props.marketFlag,
                          this.getAssortmentLink,
                          this.props.status,
                          pageType,
                          this.props.multiStoreDetails,
                          isPalletQty,
                          this.props.cart
                        )
                    :this.getRows(tableType, product, index, isPlanogram, isIM, isMonthlySpecial, cart);
                    const rowSplit = Math.round(rows.length/2)  
                    const ipadCart = () => {
                      const leftCol = headers.map((ele,index)  => {
                        const fieldval = rows[index]
                        return index < rowSplit && (
                          <div>
                          <span>{ele.label}</span>
                          <span>
                            <>
                            {fieldval.cellContent}
                            {fieldval.isTiered ? (
                                        <TierIcon tierLevel={
                                          fieldval.tierLevel
                                              ? fieldval.tierLevel
                                              : null
                                          }
                                        />
                                      ): null}
                            </>
                          </span>
                        </div>)
                      })
                      const rightCol = headers.map((ele,index)  => {
                        const fieldval = rows[index]
                        return index >= rowSplit && 
                        <div>
                        <span>{ele.label}</span>
                        <span>
                          <>
                          {fieldval.cellContent}
                          {fieldval.isTiered ? (
                                      <TierIcon tierLevel={
                                        fieldval.tierLevel
                                            ? fieldval.tierLevel
                                            : null
                                        }
                                      />
                                    ): null}
                          </>
                        </span>
                      </div>
                      })
                      return (
                        <>
                         <div className="ipadRow">
                           <div className="leftCol">{leftCol}</div>
                           <div className="rightCol">{rightCol}</div>
                          </div>
                        </>
                      )
                    } 
                    return (
                      <>
                        {product && (
                          <>
                            <tr className={ isCheckout
                                    ? 'product-row'
                                    : isConfirmation
                                    ? 'order-confirm-row'
                                    : 'cart-product-row'
                                }
                            >
                              {isViewCart && utilities.isTab() ? ipadCart() :
                               rows.map(
                                cell => {
                                  const isVisible = cell?.cellContent? "visible":"hidden";
                                  return (
                                    <td style={tableType === 'viewCart' ? {visibility:isVisible}: {}} className={cell.cellClass || ''}>
                                      {cell.cellContent}
                                      {cell.isTiered ? (
                                        <TierIcon tierLevel={
                                            cell.tierLevel
                                              ? cell.tierLevel
                                              : null
                                          }
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                            {isViewCart && (
                              <tr className='even-row'>
                                <td className='t-left cart-item-view-border-bottom'  colSpan={
                                      tableType !== 'ra-cart' 
                                       ? headers.length :3} >
                                  <i className='addedBy'>
                                    {` Added By: ${product.userName ? product.userName : ""}`}
                                  </i>
                                  {this.state.visible && this.state.quickViewItem == product.item_nbr && (
                                    <div className='QuickviewDialog' style={{'marginLeft':'-4rem'}} onMouseLeave={this.closeModal}>
                                      <Dialog width='1' height='1' position='absolute'>
                                        <QuickViewDetails
                                          getprefer={
                                            this.props.getprefer &&
                                            this.props.getprefer.customerView
                                              ? this.props.getprefer.customerView
                                              : 'c1'
                                          }
                                          tableType={tableType}
                                          Item_Number={product.item_nbr}
                                          viewType={this.props.viewType}
                                          QuickCost ={product.Member_Cost}
                                          productPlp = {product}
                                        />
                                      </Dialog>
                                    </div>
                                  )}
                                  {isPlanogram && product.Restricted && 
                                    <span className='restricted-product pl-restricted'>Restricted Product</span>
                                  }
                                  {isCloseOutCorner && isInventoryNotAvailable && 
                                    <span className='restricted-product pl-restricted'>Inventory Is No Longer Available</span>
                                  }
                                  { this.state.errorList[product.item_nbr] && 
                                    <td style = {{borderBottom:"0px"}}colSpan={1000}><span style ={{color:"red"}}>{this.state.errorList[product.item_nbr]}</span></td>
                                   }
                                   {tableType !== 'ra-cart' && tableType !== 'ctb-cart' && !_.isEmpty(this.message) && this.message[id]?.length > 0 && (
                                     <>
                                     {this.message[id][this.messageIndex] === '' ? 
                                      <i className='itemErr'>{this.message[id].find(ele =>  ele !== '')}</i>
                                      :
                                      <i className='itemErr'>{this.message[id][this.messageIndex] ? this.message[id][this.messageIndex]:  this.message[id][0]}</i>
                                     }
                                     </>
                                     
                                   )}
                                  {product.message &&
                                    <i className='cartErr'>
                                      Message: {!product.message ? '---' : product.message}
                                    </i>
                                  }                                 
                                </td>
                                {(tableType === 'ra-cart' || tableType === 'ctb-cart') && 
                                <>   
                                  <td>                                  
                                    {product.restricted_items && product.restricted_items.length>0 &&
                                        <p className='restricted-items no_margin'>
                                          *Restricted Items - {product.restricted_items.join(',')}
                                        </p>
                                    }
                                  </td>  
                                  {tableType !== 'ctb-cart' && <td colSpan = {1} className='t-left raCartTd' >
                                    {getRAMessage(product,products,this.props.marketFlag, true, false)}
                                  </td>}                    
                                <td colspan="4">
                                   { !_.isEmpty(this.message) && this.message[id]?.length > 0 && (
                                      <>
                                      {this.message[id][this.messageIndex] === '' ? 
                                       <i className='itemErr'>{this.message[id].find(ele =>  ele !== '')}</i>
                                       :
                                       <i className='itemErr'>{this.message[id][this.messageIndex]}</i>
                                      }
                                      </>
                                      
                                    )}
                                </td>
                                </>    
                               }   
                               {tableType !== "viewCart" ? <td colSpan = {(tableType==='ra-cart' || tableType==='ctb-cart') ? headers.length-4:9}></td> :""}                            
                              </tr>
                            )}
                          </>
                        )}
                      </>
                    );
                  })}
                </tbody>
                <tfoot id={isRetailAssortment || isCtbAssortment?'RA-btn':''}>
                  {isCheckout ? (
                    <tr className='checkout-footer-row'>
                      <td colSpan={3} className='t-left'></td>
                      <td colSpan={headers.length - 3} className='t-right'></td>
                    </tr>
                  )
                  : isRetailAssortment || isCtbAssortment ? '' : ''}
                  {!isConfirmation && (
                    <tr className={ isCheckout ? 'checkout-footer-row' : 'footer-row'}>
                      {isCheckout ? (
                        <></>
                      ) : (
                        <>
                        {(!isRetailAssortment || !isCtbAssortment) &&
                            <td colSpan={isCtbAssortment ?headers.length-7 : cart.TypeId === 33 ? 4 :headers.length - 10} className='t-left'>
                              <div>
                              { isCtbAssortment && ctbShipDateChange.length > 0 && 
                                <p className='pogNumberHighlight'>Order Window for { ctbShipDateChange.length === 1 ? `this CTB (${ctbShipDateChange.toString()}) has` : `these CTB (${ctbShipDateChange.toString()}) have` }  expired and passed the Maximum Ship Date Available.  Please remove from cart to proceed</p>
                                }
                                {cart.TypeId !== 33 && !isCtbAssortment &&
                                <p>
                                  *If Item Quantity Available Is Red, Item is
                                  Ship Later
                                </p>
                                }
                                {this.tierLevels && this.tierLevels[1] && (
                                  <p className='no_margin'>T1 Each Unit Cost</p>
                                )}
                                {this.tierLevels && this.tierLevels[2] && (
                                  <p className='no_margin'>T2 Full Carton Cost</p>
                                )}
                                {this.tierLevels && this.tierLevels[3] && (
                                  <p className='no_margin'>T3 Volume Discount</p>
                                )}
                                {this.tierLevels && this.tierLevels[4] && (
                                  <p className='no_margin'>T4 Volume Discount</p>
                                )}
                              </div>
                            </td>
                          }
                          <td colSpan={isRetailAssortment || isCtbAssortment ?cart.TypeId === 33 ? 4 :headers.length-2:9} className='t-right' id='tabCheckout'>
                            <>
                              <div className='totalDetailDiv'>
                              <p>{this.props.cart.DisplayName} &nbsp;-&nbsp;{this.props.cart.items?.length}&nbsp;Item(s)</p>
                              <p>
                              {'Store: '}{utilities.getChildStoreDetails(this.props?.multiStoreDetails, this.props.cart.userId)}
                              </p>
                              </div>
                              <div>
                           { 
                           maskAndHideTotalCost ?  
                              <p>
                                <span style={{ paddingRight: '1rem' }}>
                                  {utilities.getLabelsByType(this.props.viewType,"TOTAL_COST", getPrefer).toUpperCase() }{' '}
                                </span>
                                <span style={{ paddingRight: '1rem' }}>
                                  {parseFloat(totalCost(cart)) === 0.00 ? '-' :this.props.multiStoreDetails.access !== 1  && 
                                  <MaskPrice
                                      field = 'Member Cost black'
                                      getprefer={this.props.getprefer &&
                                        this.props.getprefer.customerView
                                        ? this.props.getprefer.customerView
                                        : 'c1'
                                      }
                                      viewType={tableType==='dom-cart' ? 'Member View' : this.props.viewType}
                                      fieldVal={cart.TypeId === 33 ? cart.totalCost : totalCost(cart)}
                                    />}
                                </span>
                              </p> : ''}
                              {cart.items &&
                                cart.items.length > 0 &&
                                  !isCartExpired && (
                                  <button disabled={!isInventoryAvailable || this.props.showLoader || (isCtbAssortment && ctbShipDateChange.length>0) ||(cart.TypeId === 33 && cart.totalCost < cart.VendorDetails.Mov)} className='ui red button checkout-btn' onClick={this.checkout}>
                                    Checkout
                                  </button>
                              )}
                              </div>
                            </>
                          </td>
                        </>
                      )}
                    </tr>
                  )}
                </tfoot>
              </table>
              }
            </div>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const orders = state.cart.orders;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    getprefer:state.preference.listData,
    userName: state.SessionReducer.userName,
    storeId: state.SessionReducer.storeId,
    address: state.SessionReducer.address,
    mainRDCUnderEmergency : state.SessionReducer.mainRDCUnderEmergency,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    favorites: state.FavoritesReducer.favItemNums,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    rdc: state.SessionReducer.rdc,
    marketFlag: orders.marketFlagEnabled,
    marketDate: orders.eventDate,
    shipOptions:state.RaReducer && state.RaReducer.assortment && state.RaReducer.assortment.ShipOptions,
    childStoreList:state.SessionReducer?.multiStoreDetails?.childStoreList,
    showLoader: state.UtilityReducer.showLoader,
    isRetailAssortmentsEnable: orders?.isRetailAssortmentsEnable,
    isCtbAssortmentsEnable: orders?.isCtbAssortmentsEnable,
    carts: orders.allCarts,
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({
        addToFavorites,
        removeFromFavorites,
        deleteCart,
        updateQuantity,
        updateCloseOutCornerQuantity,
        updateOr,
        notifyCarts,
        checkout,
        getRaCategories,
        setCurrentCart
      }, dispatch
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CartProductsTable);