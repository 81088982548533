import React from 'react';
import classnames from 'classnames';
import { Segment, Loader, Divider } from 'semantic-ui-react';
import restUtils from '../../utils/restUtils';
import CompareCard from './CompareCard';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import { MULTIPLE_ITEMS_DETAILS } from '../../urlConstants';
import { addProduct, createNAddCart } from '../Cart/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { customerViewConstants, memberViewConstants } from '../../views/common/constants';
import utilities,{getPrefernceType,shouldHideCostForPreference,shouldHideSUGG_RETAILForPreference} from '../../utils/utilities';

class CompareProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      isLoading: true,
      serverError: false,
      toastMessage: '',
      toastError: false
    };
  }

  componentDidMount() {
    let itemIds = this.props.comparedItems.join(',');
    let aliasSKURetailerGrpID = this.props.multiStoreDetails ? this.props.multiStoreDetails.aliasSKURetailerGrpID : '';
    let url = MULTIPLE_ITEMS_DETAILS + itemIds + '&storeId=' + this.props.storeId +'&rdc=' + this.props.rdc
              + "&aliasSKURetailerGrpID=" + aliasSKURetailerGrpID;
    if (!utilities.isEmptyOrNullString(this.props.currentCartId)) {
      url = url + "&cartid=" + this.props.currentCartId;
    }
    restUtils
      .getData(url)
      .then(response => {
        const products = response.data ? response.data : [];
        this.setState({
          isLoading: false,
          products,
          serverError: false,
          quantities: Array(products.length).fill('')
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false, serverError: true });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props === nextProps) return;
    let comparedItems = nextProps?.comparedItems?.map(item => String(item));
    let products = this.state.products.filter(product => {
      return comparedItems.indexOf(product.Item_Number) > -1;
    });
    this.setState({ products });
  }

  componentDidUpdate() {
    this.matchHeights();
    this.matchCatAttrHeights();
  }

  matchHeights = () => {
    let ht = 0;
    document.getElementsByClassName('long-desc').forEach(e => (ht = e.offsetHeight < ht ? ht : e.offsetHeight));
    document.getElementsByClassName('long-desc').forEach(element => (element.style.height = ht + 'px'));
  };

  matchCatAttrHeights = () => {
    if(this.state.products && this.state.products.length > 0 
      && this.state.products[0].CategoryAttributes)
    {
      Object.keys(this.state.products[0].CategoryAttributes).forEach(function(AttName, index) {
        let ht = 0;
        document.getElementsByClassName('catatt'+index).forEach(e => (ht = e.offsetHeight < ht ? ht : e.offsetHeight));
        document.getElementsByClassName('catatt'+index).forEach(element => (element.style.height = ht + 'px'));
      })
    }
  }

  getLabelsByTypeCompare = (viewType,key,getprefer)=>{
    if(viewType === MEMBER_VIEW){
      return memberViewConstants[key];
    } else if(viewType !== MEMBER_VIEW && (getprefer==='c1'||getprefer==='c4')) {
      return this.props.onVariablePricing ? `${memberViewConstants[key]}(VR)` : memberViewConstants[key];
    }
    else return this.props.onVariablePricing ? `${customerViewConstants[key]}(VR)` : customerViewConstants[key];
  }

  render() {
    const { toastError, toastMessage } = this.state;
    const { isCustomerView } = this.props;
    const getprefer = getPrefernceType(this.props);
    const displayMemberCost = shouldHideCostForPreference(this.props.viewType,getprefer );
    const displaySRPCost = shouldHideSUGG_RETAILForPreference(this.props.viewType,getprefer);
    return (
      <Segment style={{ padding: '0.5rem', backgroundColor: '#f2f2f2' }}>
        {this.state.serverError ? (
          <InternalServerError />
        ) : (
            <>
              <div className='compareTitle'>Compare Products</div>
              <div id='page_navigation' style={{ paddingBottom: '1.2rem' }}>
                {/* breadcrumbs missing in Compare PAge need to Implement */}
                {/* <div className='ui breadcrumb'>
                <a onClick={this.props.toggleCompare} className='section'>
                  {this.props.categoryId}
                </a>
                <div className='divider'> > </div>
              </div> */}
              </div>
              {window.innerWidth < 768 && <div class="remove-all-span mob" onClick={this.props.toggleCompare}>Back to Products Page</div>}
              <div className={window.innerWidth > 768
                ? 'ui stackable sixteen column grid compare-container'
                : 'ui sixteen column grid grid-mobilecmp'
              } style={{ backgroundColor: '#f2f2f2' }}>
                {window.innerWidth > 768
                ?
                <div className='fields-div-one three wide column' >
                  <div className='compare-root d-center row1'>
                    <div>
                      <div className='remove-all-span' onClick={this.props.toggleCompare}>
                        Back to Products Page
                    </div>
                      <span>Comparison</span>
                      <div className='remove-all-span'
                        onClick={() => { this.props.removeFromCompare('*'); }}>
                        Remove All
                    </div>
                    </div>
                  </div>
                  <div className='comparison-field row2'>Item#</div>
                  <div className='comparison-field row3'>Model#</div>
                  {this.props.multiStoreDetails && !utilities.isEmptyOrNullString(this.props.multiStoreDetails.aliasSKURetailerGrpID) && 
                  <div className='comparison-field row3'>Alternate SKU#</div>}
                  <div className='comparison-field row4'>Available</div>
                  <div className='comparison-field row5'>Product Indicators</div><br /><br />
                  {(this.props.multiStoreDetails.access !== 1 || (this.props.multiStoreDetails.access === 1 && this.props.multiStoreDetails.CatMbr)) &&
                    //displayMemberCostAndLbl&& 
                    displayMemberCost &&<div className='comparison-field row6'>{utilities.getLabelsByType(this.props.viewType,"MEMBER_COST")}</div>
                  }
                  <div className='comparison-field row7'>Pack</div>
                  {
                    displaySRPCost &&<div className='comparison-field row8'>{this.getLabelsByTypeCompare(this.props.viewType,"SUGG_RETAIL",getprefer)}</div>
                   }
                   {
                    this.props.onVariablePricing && this.props.viewType === MEMBER_VIEW  && <div className='comparison-field row9'>Variable Retail</div>
                   }
                  <div className='comparison-field'>Max Qty</div>
                  <div className='comparison-field'>Quantity</div>
                  {this.props.multiStoreDetails.access !== 1 &&
                    <div className='comparison-field row10'>Override Retail</div>}
                  <div className='comparison-field row3 restricted-product'></div>
                  {this.props.multiStoreDetails.access !== 1 &&
                    <div className='comparison-field row11'></div>}
                  <div className='comparison-field long-desc'>
                    Description
                </div>

                  <div className='comparison-field row13'>Length</div>
                  <div className='comparison-field row14'>Width</div>
                  <div className='comparison-field row15'>Height</div>
                  <div className='comparison-field row16'>Weight</div>
                  {!isCustomerView &&
                    <>
                      <div className='comparison-field row17'>UPC #</div>
                      <div className='comparison-field row18'>Secondary UPC#</div>
                      <div className='comparison-field row19'>Master Pack</div>
                      <div className='comparison-field row20'>Inner Pack</div>
                      <div className='comparison-field row21'>Hazmat</div>
                      <div className='comparison-field row22'>Small Parcel</div>
                    </>
                  }
                  <div className='comparison-field row23'>Country Of Origin</div>
                  {!isCustomerView &&
                    <>
                      <div className='comparison-field row24'>Perishable</div>
                      <div className='comparison-field row25'>Freezable</div>
                    </>
                  }
                  {this.state.products && this.state.products.length > 0 
                   && this.state.products[0].CategoryAttributes && 
                   Object.keys(this.state.products[0].CategoryAttributes).map(function(AttName, index) {
                      return(
                        <>
                          {index == 0 &&  <Divider section />}
                          <div className={'comparison-field catatt'+index}>{AttName}</div>
                        </>
                      )
                  })}
                </div>
                :
                <></>
                }
                {this.state.isLoading ? (
                  <Loader active />
                ) : (
                    this.state.products.map((product) => {
                      let imgUrl =
                        'https://images.truevalue.com/getimage.asp?id=' +
                        product.Item_Number +
                        '&ImageType=2&NoImageType=2&ColorImageSize=2&LineArtImageSize=2';
                      return (
                        <CompareCard
                          toggleFavorite={this.props.toggleFavorite}
                          product={product}
                          imageUrl={imgUrl}
                          removeFromCompare={this.props.removeFromCompare}
                          isCustomerView={isCustomerView}
                          access={this.props.multiStoreDetails.access}
                          hotDeals={this.props.HotDeals}
                          displayMemberCost = {displayMemberCost}
                          displaySRPCost ={displaySRPCost}
                        />
                      );
                    })
                  )}
              </div>
            </>
          )}
        <div
          id='snackbar'
          className={classnames({ red: toastError, show: toastMessage })}>
          {toastMessage}
        </div>
      </Segment>
    );
  }
}

const mapStateToProps = state => {
  const carts = state.cart.orders.allCarts;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    carts,
    currentCartId: state.cart.orders.currentCart.cartId,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    rdc : state.SessionReducer.address ? state.SessionReducer.address.rdc : '',
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    onVariablePricing: state.SessionReducer.multiStoreDetails ? state.SessionReducer.multiStoreDetails.onVariablePricing : false,
    getprefer: state.preference.listData
  };
};

const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {
        addProduct,
        createNAddCart
      },
      dispatch
    )
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CompareProducts);
