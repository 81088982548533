import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import VendorAlphabetFilter from './component/vendor-alphabet-filter';
import './vendors.css';

import VendorHeader from './component/vendor-header';
import VendorSearch from './component/vendor-search';

class Vendors extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSearchAction = alphabet => {
    this.setState({
      alphabet:alphabet.toUpperCase()
    });
  };

  render() {
    const { alphabet = 'A' } = this.state;
    return (
      <div className='main-container'>
        {this.state.isLoading && (
          <div>
            <Loader active />
          </div>
        )}
        {this.state.serverError ? (
          <InternalServerError />
        ) : (
          <div>
            <VendorHeader />
            <VendorSearch
              displayName={'Vendor Search'}
              onSearchAction={this.onSearchAction}
            />
            <div className='fav-footer aToz-div vendorSearchWrapper'>
              <VendorAlphabetFilter alphabet={alphabet} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Vendors;
