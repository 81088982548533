import React, { Component } from 'react';
import CartProductsTable from './CartProductsTable';
import { connect } from 'react-redux';
import history from '../../history';
import { bindActionCreators } from 'redux';
import { placeOrder, deleteCart, updateShipTo, checkout, notifyCarts,getStateJson } from './actions';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Loader, Grid, Responsive, Modal } from 'semantic-ui-react';
import {utilities, shouldHideCostForPreference} from '../../utils/utilities';
import { restUtils } from '../../utils/restUtils';
import { directShipPlaceOrder, getStateJsonUrl } from '../../urlConstants';
import { MaskPrice } from '../common/MaskPrice';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';

class CheckoutDirectShip extends Component {
  constructor(props) {
    super(props);
    const checkedoutCart = props.carts.find(cart => {
      return parseInt(cart.cartId) === parseInt(props.match.params.cartName);
    });
    const {isMultiStore,multiStoreCarts} = props?.location?.state;
    this.state = {
      cartName: _.get(props, 'match.params.cartName', ''),
      cart: checkedoutCart,
      isLoading: false,
      isError: false,
      errorMsg: '',
      tstmsg: '',
      disablePlaceorderbtn: this.props.address.viewOrders,
      cartSelected:[],
      isMultiStore : isMultiStore,
      multiStoreCarts : multiStoreCarts,
      editAd: null,
      newCity:'',  newAddressLine1:'', newAddressLine2:'', newStateCode:'', newPostalCode:'' ,
      City:'',  addressLine1:'', addressLine2:'', stateCode:'', postalCode:'' ,
      editAddress:'',
      showPopup: false,
      retailerPo:'',
      getStateJson: this.props.stateJson
    };
  }
  componentDidMount(){
    if(this.props.address.viewOrders === true){
      utilities.showToast(`Please note that you do not have permission to submit an order. Please speak with your system administrator or manager if this access is required.`, true);
    }
    this.setState({
        City: this.props.address?.City ? this.props.address?.City :'',
        addressLine1: this.props.address?.addressLine1 ? this.props.address?.addressLine1 :'',
        addressLine2: this.props.address?.addressLine2 ? this.props.address?.addressLine2 :'',
        stateCode: this.props.address?.stateCode ? this.props.address?.stateCode :'',
        postalCode: this.props.address?.postalCode ? this.props.address?.postalCode :'',
        newCity: this.props.address?.City ? this.props.address?.City :'',
        newAddressLine1: this.props.address?.addressLine1 ? this.props.address?.addressLine1 :'',
        newAddressLine2: this.props.address?.addressLine2 ? this.props.address?.addressLine2 :'',
        newStateCode: this.props.address?.stateCode ? this.props.address?.stateCode :'',
        newPostalCode: this.props.address?.postalCode ? this.props.address?.postalCode :''
    })
    if(_.isEmpty(this.state.getStateJson)) {
      restUtils.getDataWithoutToken(getStateJsonUrl()).then((res)=>{
        this.setState({
          getStateJson: res.data
        })
        this.props.getStateJson(res.data)
      }).catch(err=>{
        console.log(err)
      })
    }
  }
  editAdHandler = () => {
    this.setState({
      showPopup: true
    });
  };
  
  saveAddress = () => {
    const { newCity, newAddressLine1, newAddressLine2, newStateCode, newPostalCode  } =  this.state;
    
    if( _.isEmpty(newAddressLine1) || _.isEmpty(newCity) || _.isEmpty(newPostalCode)) {
      utilities.showToast('Addres-1, City, and ZIP Code are mandatory field')
      return
    }
    this.setState({
      City: newCity,
      postalCode: newPostalCode,
      addressLine1:newAddressLine1,
      addressLine2:newAddressLine2,
      stateCode:newStateCode,
      showPopup: false
    })
  }
  placeOrderPlanograms = (multiStoreCarts = [],isMultiStore) => {
    this.setState({ isLoading: true });
    const { cart, addressLine1, addressLine2, City, stateCode, postalCode } = this.state;
    const { userName, storeId, checkDigit, fullMemberNbr, multiStoreDetails } = this.props;
    
    if(!isMultiStore){
      multiStoreCarts = [cart];
    }
    else{
      multiStoreCarts = multiStoreCarts && multiStoreCarts.filter(msDetails => msDetails?.items?.length>0);
    }
    let reqPayload = {
      "CartId":cart.cartId,
      "CartTypeID":33,
      "VendorId": parseInt(cart.VendorDetails.VendorId),
      "UserId":cart.userId,
      "UserName":cart.userName,
      "RetailerPoNumber":this.state.retailerPo,
      "PoNumber":`PO${cart.cartName}-${cart.cartId}`,
      "ShippingAddress": {
        "AddressName":this.props.storeName,
        "AddressLine1": addressLine1,
        "AddressLine2": addressLine2,
        "City": City,
        "State": stateCode,
        "ZipCode": postalCode
      },
      "BillingAddress": {
        "AddressName":'TrueValue',
        "AddressLine1": this.props.address.addressLine1,
        "AddressLine2": this.props.address.addressLine2,
        "City": this.props.address.City,
        "State": this.props.address.stateCode,
        "ZipCode": this.props.address.postalCode
      }
    }
    restUtils.postData(directShipPlaceOrder, reqPayload).then((response) => {
      if (response) {
        this.setState({ disablePlaceorderbtn: false, isLoading: false });
        if (response.error && (response.error !== null || response.error !== "")) {
          utilities.showToast(response.error, true)
        } else {
          history.push({
            pathname: `/orderConfirmation/${this.state.cartName}`,
            state: {
              cart: multiStoreCarts,
              ...this.state.cart,
              poNum: `PO${cart.cartName}-${cart.cartId}`,
              orderTotal: cart.totalCost,
              isDsCart: true,
              retailerPo: this.state.retailerPo,
              address: {
                addressLine1,
                addressLine2,
                City,
                stateCode,
                postalCode
              }
            }
          });
        }
      }
    }).catch((err) => {
      console.log(err);
      utilities.showToast(`Failed to Submit order. Please try later`, true);
      this.setState({ disablePlaceorderbtn: false, isLoading: false });
    });
  }

  cancelOrder = (cartId) => {
    history.push('/cart');
    this.props.deleteCart(cartId);
    this.props.notifyCarts();
  };
  editAdressPopup = () =>{
    this.setState({showPopup: false})
  }
  handleChangeAd = (e) => {
   const { name, value} = e.target;
   this.setState({
      [name]: value
   })
  }
  retailerPo = (e)=>{
    const { value } = e.target
    const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if(format.test(e.target.value) || _.isEmpty(value)) {
      utilities.showToast('Special characters not allowed.')
      return
    }
    this.setState({
      retailerPo: e.target.value.trim()
    })
  }
  render() {
    const { cart, isMultiStore} = this.state;
    const {City,  addressLine1, addressLine2, stateCode, postalCode } = this.state
    let {multiStoreCarts} = this.state;
    const stateOption = ()=>{
      let  opt = []
       if(!_.isEmpty(this.state.getStateJson)) {
        for (const property in this.state.getStateJson) {
          opt.push({
            name:property,
            value:this.state.getStateJson[property]
          })
        }
       }
      return opt
    }
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    if(!isMultiStore){  
      multiStoreCarts = [cart];
    }  
    const  getCheckoutPrefer = this.props.getprefer && this.props.getprefer.customerView ? this.props.getprefer.customerView
    : 'c1'
     const  hideTotalExtendCost = shouldHideCostForPreference(this.props.viewType,getCheckoutPrefer)
    return (
      <div className='page-wrap'>
        {/*  <Modal closeIcon
         className='changeStorePopup'
         onClose = {this.editAdressPopup}
         closeOnDimmerClick={false}
            open={this.state.showPopup}>
            <Modal.Content className=''>
              <div className='dsShipBtn'>
                <h3>Edit Address</h3>
                <div className='newAddressForm'>
                  <section>
                  <div>
                    <label>Address-1</label>
                    <input  name = 'newAddressLine1' value={this.state.newAddressLine1} onChange={this.handleChangeAd}/>
                  </div>
                  <div>
                    <label>Address-2</label>
                    <input   name = 'newAddressLine2'value={this.state.newAddressLine2}  onChange={this.handleChangeAd}/>
                  </div>
                  </section>
                  <section>
                  <div>
                    <label>City</label>
                    <input  name = 'newCity'value={this.state.newCity}  onChange={this.handleChangeAd}/>
                  </div>
                  <div>
                    <label>State</label>
                    <select name = 'newStateCode' value={this.state.newStateCode}  onChange={this.handleChangeAd}>
                      {stateOption().length > 0 && stateOption().map(opt=>{
                        return <option key ={opt.value} value={opt.value}>{opt.name}-{opt.value}</option>
                      })}
                    </select>
                  </div>
                  <div>
                    <label>Zip Code</label>
                    <input  name='newPostalCode' value={this.state.newPostalCode} onChange={this.handleChangeAd}/>
                  </div>
                  </section>
                </div>              
                 <div>
                 <button onClick={this.editAdressPopup}>Close</button>
                  <button className="submit" onClick={this.saveAddress}>Save</button>
                 </div>
              </div>
            </Modal.Content>
          </Modal>*/}
        <div className='page-header'>Checkout {cart ? `PO${cart.cartName}-${cart.cartId}` : ''}</div>
        { this.state.disablePlaceorderbtn && <div className='Note-Message'>Please note that you do not have permission to submit an order. Please speak with your system administrator or manager if this access is required.</div>}
        {!cart ? (
          <h3>
            Order already placed!{' '}
            <Link className='back-to-cart' to='/cart'>
              Back to Cart
            </Link>
          </h3>
        ) : this.state.isError ? (
          <div className='checkoutErrorBacttoCart'>
            <h3>
              {this.state.errorMsg ? this.state.errorMsg : 'Checkout failed!'}
            </h3>
            <Link className='back-to-cart' to='/cart'>
              Back to Cart
            </Link>
          </div>
        ) : (
              <>
                <div className='checkout-table'>
                  {this.state.isLoading && (
                    <div className="loaderWrapper">
                    <Loader active />
                    <p>Please wait while we process your order... <br/> Please don’t press the Back Button while your order is processing </p>
                    </div>
                  )}
                    <Responsive minWidth='1025' className="dsShipping_sticky">
                      <Grid columns={3} id='shippingBlock' className='ds_shippingBlock'>
                        <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin' style={{width:'35%'}}>
                        <div>
                            <span className='retailer_PO_label'>Retailer Reference PO #:</span>
                            <span className='retailer_PO'><input value={this.state.retailerPo}  onChange={this.retailerPo}  maxLength={10}/></span>
                          </div>
                          <div>
                            <span>Purchase Order #:</span>
                            <span className='Planogram_PO'>{cart && `PO${cart.cartName}-${cart.cartId}`}</span>
                          </div>
                          <div>
                            <span>VendorName:</span>
                            <span className='Vendor_Name'>{cart && cart.DisplayName}</span>
                          </div>
                          <div>
                            <span>Ship to:</span>
                              <span className='ds_shipping'>
                                <div> <p>{addressLine1}</p> <p>{addressLine2}</p>  
                                <p><span>{!_.isEmpty(City) && `${City},`}</span><span>{!_.isEmpty(stateCode) && `${stateCode},`}</span><span>{postalCode}</span> </p></div>
                             {/*<i
                              className="fa fa-edit"
                              style={{ cursor: "pointer" }}
                              onClick={this.editAdHandler}
                  />*/}
                            </span>                   
                          </div>
                          <div>
                            <span>Shipping Method: &ensp;&ensp;</span>
                            <span className='RAdelivery'>Direct Ship </span>
                          </div>
                        </Grid.Column>

                        <Grid.Column>
                        <div className='place-orderRA'>
                      <div>
                        {!isMultiStore && <Link to='/cart' className='back-to-cart'>
                          Back to Cart
                        </Link>}
                        <button className='cancel-order-btn' onClick={() => this.cancelOrder(cart.cartId)}>
                          Cancel Order
                        </button>
                        {((this.props.multiStoreDetails.trueValue || this.props.multiStoreDetails.induserve
                          || this.props.multiStoreDetails.rental || this.props.multiStoreDetails.hgShowplace
                          || this.props.multiStoreDetails.affiliate || this.props.multiStoreDetails.commercialSales
                          || this.props.multiStoreDetails.commercialSupply || this.props.multiStoreDetails.justAsk
                          || this.props.trueValueGardens || this.props.multiStoreDetails.trueValueRental
                          || this.props.multiStoreDetails.other || this.props.multiStoreDetails.partyCentral)
                          && !this.props.hq && this.props.allowedorder)
                          ? !this.props.isProspectStore ?
                           <button className='ui red button place-order-btn' onClick={this.placeOrderPlanograms} disabled={this.state.disablePlaceorderbtn}> Place Order </button>
                           :<> 
                           <button className='ui red button place-order-btn' disabled> Place Order </button>
                           <p className="disabledMessage">*Ordering restricted</p>
                           </>
                          : ''
                        }
                      </div>
                    </div>
                        </Grid.Column>
                      </Grid>
                      { hideTotalExtendCost &&
                            <div className='totalCost' style={{marginTop:'0'}}>
                              {utilities.getLabelsByType(this.props.viewType,"TOTAL_EXTENDED_COST", getPrefer)}{': '}
                              {this.props.multiStoreDetails.access !== 1 && <MaskPrice
                                getprefer={this.props.getprefer &&
                                  this.props.getprefer.customerView
                                  ? this.props.getprefer.customerView
                                  : 'c1'
                                }
                                viewType={this.props.viewType}
                                fieldVal={cart.totalCost}
                                field='cart-totals'
                              />}
                          </div>}
                    </Responsive>

                  {multiStoreCarts.map(cart => 
                  <>
                  { cart?.items?.length > 0 && 
                  <>
                  <Responsive maxWidth='1024'>
                  <Grid id='shippingBlock' className="ds_shippingBlock">
                        <Grid.Column className='displayInlineFlex checkoutPage bgColor products-margin' style={{margin:0,marginTop:'1rem'}}>
                        <div>
                            <span className='retailer_PO_label'>Retailer Reference PO #:</span>
                            <span className='retailer_PO'><input value={this.state.retailerPo} onChange={this.retailerPo}  maxLength={10}/></span>
                          </div>
                          <div>
                            <span>Purchase Order #:</span>
                            <span className='Planogram_PO'>{cart && `PO${cart.cartName}-${cart.cartId}`}</span>
                          </div>
                          <div>
                            <span>Ship to:</span>
                              <span className='ds_shipping'>
                                <div> <p>{addressLine1}</p> <p>{addressLine2}</p>  <p><span>{!_.isEmpty(City) && `${City},`}</span><span>{!_.isEmpty(stateCode) && `${stateCode},`}</span><span>{postalCode}</span> </p></div>
                             {/*<i
                              className="fa fa-edit"
                              style={{ cursor: "pointer" }}
                              onClick={this.editAdHandler}
                  />*/}
                            </span>                   
                          </div>
                          <div>
                            <span>Shipping Method: &ensp;&ensp;</span>
                            <span className='RAdelivery'>Direct Ship </span>
                          </div>
                        </Grid.Column>
                        <div className='place-orderRA'>
                        <Link to='/cart' className='back-to-cart'> Back to Cart </Link>
                          <button className='cancel-order-btn' onClick={() => this.cancelOrder(cart.cartId)}> Cancel Order </button>
                          {((this.props.multiStoreDetails.trueValue || this.props.multiStoreDetails.induserve
                            || this.props.multiStoreDetails.rental || this.props.multiStoreDetails.hgShowplace
                            || this.props.multiStoreDetails.affiliate || this.props.multiStoreDetails.commercialSales
                            || this.props.multiStoreDetails.commercialSupply || this.props.multiStoreDetails.justAsk
                            || this.props.trueValueGardens || this.props.multiStoreDetails.trueValueRental
                            || this.props.multiStoreDetails.other || this.props.multiStoreDetails.partyCentral)
                            && !this.props.hq && this.props.allowedorder)
                            ? !this.props.isProspectStore ?
                            <button className='ui red button place-order-btn' onClick={this.placeOrderPlanograms} disabled={this.state.disablePlaceorderbtn}> Place Order </button>
                            :<> 
                              <button className='ui red button place-order-btn' disabled> Place Order </button>
                                <p className="disabledMessage">*Ordering restricted</p>
                            </>
                            : ''
                          }
                        </div>
                      </Grid>
                    { hideTotalExtendCost ?
                        <div className='totalCost'>
                          {utilities.getLabelsByType(this.props.viewType,"TOTAL_EXTENDED_COST", getPrefer)}{': '}
                          {this.props.multiStoreDetails.access !== 1 && <MaskPrice
                            getprefer={this.props.getprefer &&
                              this.props.getprefer.customerView
                              ? this.props.getprefer.customerView
                              : 'c1'
                            }
                            viewType={this.props.viewType}
                            fieldVal={cart.totalCost}
                            field='cart-totals'
                          />}
                    </div> : "" }
                  </Responsive>
                   <>
                    <CartProductsTable
                      cart={cart}
                      products={cart && cart.items}
                      isCheckout={true}
                      userId = {cart.userId}
                    />
                          <div className='column t-right'>
                            <p >
                              {' '}
                                  Prices are subject to change. Items will be priced
                                  at the time the order is fulfilled, not at the
                                  time of order placement.
                                </p>
                          </div>
                          <br />
                    </>
                    </>}
                    </>
                   )}                  

                  <div id="centresnackbar" className={this.state.tstmsg && `show`} >{this.state.tstmsg}</div>
                </div>
              </>
            )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  let carts = state.cart.orders.allCarts;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    carts,
    userName: state.SessionReducer.userName,
    getprefer: state.preference.listData,
    address: state.SessionReducer.address,
    multiStoreDetails: state.SessionReducer.multiStoreDetails,
    storeId: state.SessionReducer.storeId,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    storeRDC: state.SessionReducer.rdc,
    allowedorder: state.SessionReducer.address.allowedorder,
    hq: state.SessionReducer.address.hq,
    checkDigit: state.SessionReducer.address.checkDigit,
    fullMemberNbr: state.SessionReducer.address.fullMemberNbr,
    isProspectStore : state.SessionReducer.address.isProspectStore,
    stateJson: state.cart.orders?.getStateJson,
    storeName:state.SessionReducer.address.storeName
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      { placeOrder, deleteCart, updateShipTo, checkout, notifyCarts, getStateJson },
      dispatch
    )
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDirectShip);
