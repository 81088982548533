import {ORDER_STATUS, directShipOrderStatus,ORDER_ITEM_DETAILS, productListingUrl, closeOutItemsUrl, PROMO_ITEM_DETAILS, REUNION_ITEM_LISTING,HOT_DEALS_URL,PLANOGRAM_LISTING, getFavoritesUrl, directShipItemsUrl } from '../../../../src/urlConstants';
import restUtils from '../../../utils/restUtils';
import { utilities } from '../../../utils/utilities';
import { assortmentLevels } from '../constants';
import _ from 'lodash';

export const getAllOrders = (searchNumber, searchParam, sortParam, sortDirection, appliedFilters, storeId, userId, rdcNum, aliasSKURetailerGrpID, totalPages = 100, pageSize = 500)=>{
    let searchArr = {};
    if (searchNumber) {
      searchArr = {
        name: searchParam,
        valueList: searchNumber.split(',')
      };
    }
    const sortArr = { sortParam: sortParam, sortType: sortDirection };  
    let queryJson = []; 
    if(!_.isEmpty(appliedFilters)){      
      for (let property in appliedFilters) {
        if (appliedFilters.hasOwnProperty(property)) {
          let filter = {};
          filter.name = property;
          if(property === 'Status'){
            let valueList = [];
            let values = appliedFilters[property].split(',');
            for(let key in values){
              valueList.push(values[key]);
            }
            filter.valueList = valueList;
          }          
          else filter.valueList = appliedFilters[property].split(',');
          queryJson.push(filter);
        }
      }
    }
    const url = `${ORDER_STATUS}`;
    const requests = _.range(1, totalPages+1).map((num, _)=>{
      const requestPayLoad = { "storeId": storeId, "userId": userId, "rdcNum": rdcNum, searchBy: searchArr, sortBy: sortArr, 
                            ...{ filterBy: queryJson },"pageNumber": num,aliasSKURetailerGrpID : aliasSKURetailerGrpID,
                            "orderGroupsPerPage": pageSize };

      return restUtils.postData(url, requestPayLoad);
    })
    return Promise.all(requests).then((res)=>{
        return res.reduce((acc, cur)=>{
        return acc.concat(cur.data);
      },[]);
    });
}


export const getAllDsOrders = (searchNumber, searchParam, sortParam, sortDirection, appliedFilters, storeId, userId, rdcNum, aliasSKURetailerGrpID, totalPages = 100, pageSize = 500)=>{
  let searchArr = {};
  if (searchNumber) {
    searchArr = {
      name: searchParam,
      valueList: searchNumber.split(',')
    };
  }
  const sortArr = { sortParam: sortParam, sortType: sortDirection };  
  let queryJson = []; 
  if(!_.isEmpty(appliedFilters)){      
    for (let property in appliedFilters) {
      if (appliedFilters.hasOwnProperty(property)) {
        let filter = {};
        filter.name = property;
        if(property === 'Status'){
          let valueList = [];
          let values = appliedFilters[property].split(',');
          for(let key in values){
            valueList.push(values[key]);
          }
          filter.valueList = valueList;
        }          
        else filter.valueList = appliedFilters[property].split(',');
        queryJson.push(filter);
      }
    }
  }
  const url = `${directShipOrderStatus}`;
  const requests = _.range(1, totalPages+1).map((num, _)=>{
     const requestPayLoad = {   "UserId":  userId,
      "CartTypeId": 33,
      "Page": {
          "PageNumber": num,
          "ResultsPerpage": parseInt(pageSize),
          "SortBy": sortParam,
          "SortOrder": sortDirection
      },
      "FilterBy": [],
      "SearchBy": searchArr};

    return restUtils.postData(url, requestPayLoad);
  })
  return Promise.all(requests).then((res)=>{
      return res.reduce((acc, cur)=>{
      return acc.concat(cur.data);
    },[]);
  });
}

export const getItemDetails = (sortParam,sortDirection, orderDetails, storeId, userId, page, aliasSKURetailerGrpID, totalItems, promoNbr, file ) => {

  const sortArr = { sortParam: sortParam, sortType: sortDirection };
  let requestPayLoad = '';
  let totalPages = 0;
  const pageSize = 500
  if(totalItems > 0){
    totalPages = ~~(totalItems/pageSize) + 1
  }
  const requests = _.range(1, totalPages+1).map((num, _)=>{
    orderDetails.orderNumber ?
      requestPayLoad = {
        "storeId": storeId, "userId": userId, 
        "orderNumber": orderDetails.orderNumber,
        "rdcNumber": orderDetails.rdcNumber,
        "orderType": orderDetails.orderType, "status": orderDetails.status, sortBy: sortArr,
        "pageNumber": num,
        "resultsPerPage": pageSize,
        "aliasSKURetailerGrpID": aliasSKURetailerGrpID
      } :
      requestPayLoad =
      {
        "userId": userId,
        "storeId": storeId,
        "status": orderDetails.status,
        "rdcNumber": orderDetails.rdcNumber,
        "invoiceNumber": orderDetails.invoiceNumber,
        "invoiceDateString": orderDetails.invoiceDateString,
        "memberOrderRef": orderDetails.memberOrderRef,
        "orderRegisterNumber": orderDetails.orderRegisterNumber,
        "pageNumber": num,
        "resultsPerPage": pageSize,
        "aliasSKURetailerGrpID": aliasSKURetailerGrpID,
        "orderNumber": orderDetails.orderNumber
      }
    let url = `${ORDER_ITEM_DETAILS}`

    if(file == 'PromoItemDetails'){
      requestPayLoad = 
      {
        "userId": userId,
        "storeId": storeId,
        "status": orderDetails.status,
        "rdcNbr": orderDetails.rdcNbr,
        "invoiceNbr": orderDetails.invoiceNbr,
        "invoiceDateString": orderDetails.invoiceDateString,
        "orderRegstrNbr": orderDetails.orderRegstrNbr,
        "pageNumber": num,
        "resultPerPage": pageSize,
        "aliasSKURetailerGrpID": aliasSKURetailerGrpID,
        "orderNbr": orderDetails.orderNbr,
        "promoNbr" : orderDetails.relay_nbr,
        "sortBy": sortArr
      }
      url = `${PROMO_ITEM_DETAILS}`
    }
    return restUtils.postData(url, requestPayLoad)
  })
  return Promise.all(requests).then((res)=>{
      return res.reduce((acc, cur)=>{
      return acc.concat(cur.data);
    },[]);
  });
  }


export const getDirectShipItems = (userId,totalItems, sortParam,sortDirection,vendorId,filterString) =>{
  const pageSize = 500
  let totalPages = 0
  if(totalItems > 0){
    totalPages = ~~(totalItems/pageSize) + 1
  }
  let postObj = { }     
  const requests = _.range(1, totalPages+1).map((num, _)=>{
    postObj = {
      UserId: userId,
      page: num.toString(),
      resultPerPage: pageSize.toString(),
      sortby: sortParam === '' ? 'item_nbr' : sortParam,
      sortorder: sortDirection,
      VendorId: vendorId,
      ...filterString
    }     
    return restUtils.postData(`${directShipItemsUrl}/GetDsVendorItems`, postObj);
  })
  return Promise.all(requests).then((res)=>{
      return res.reduce((acc, cur)=>{
      return acc.concat(cur.data);
    },[]);
  });
}
export const getWarehouseItems= (rdc, storeID, affiliate, primaryWarehouseNum, groupUrlId, easyAskDic, catPath, category, subCategory, cartid, totalItems, vendorName, vendorId , fileName, description, descriptionValue, filterString, isShowAllWarehouse, sortDir, sortBy)=>{
    const pageSize = 500
    let totalPages = 0
    if(totalItems > 0){
      totalPages = ~~(totalItems/pageSize) + 1
    }
    const requests = _.range(1, totalPages+1).map((num, _)=>{
      let url = `${productListingUrl}?rdc=${rdc}&storeID=${storeID}&affiliate=${affiliate}&primaryWarehouseNum=${primaryWarehouseNum}&GroupURLId=${groupUrlId}&aliasSKURetailerGrpID=&easyAskDic=${easyAskDic}&CatPath=${catPath}&Category=${category}&SubCategory=${subCategory}&Page=${num}&ResultsPerPage=${pageSize}&cartid=${cartid}`
      
      if( fileName === 'Vendor Products'){
       url = `${productListingUrl}?rdc=${rdc}&storeID=${storeID}&affiliate=${affiliate}&primaryWarehouseNum=${primaryWarehouseNum}&GroupURLId=${groupUrlId}&aliasSKURetailerGrpID=&easyAskDic=${easyAskDic}&CatPath=${catPath}&Category=${category}&SubCategory=${subCategory}&Page=${num}&ResultsPerPage=${pageSize}&cartid=${cartid}&Vendor=${vendorName}&VendorId=${vendorId}`
      }

      if(descriptionValue && description === 'Description'){
        url = url + `&Description=${descriptionValue}`
      }

      if(isShowAllWarehouse){
        url = url + '&allwarehouse=1'
      }

      if(sortBy && sortBy !== ''){
        url = url + `&SortParam=${sortBy}&SortType=${sortDir ? sortDir : 'asc'}`
      }

      if(filterString && filterString !== ''){
        url = url + filterString
      }

      return restUtils.getData(url);
    })
    return Promise.all(requests).then((res)=>{
        return res.reduce((acc, cur)=>{
        return acc.concat(cur.data);
      },[]);
    });
}

export const getCloseOutItems= (aliasSKURetailerGrpID, cartid, storeID, rdc, totalItems, sortDir,sortBy, filterString, isShowAllWarehouse)=>{
  const pageSize = 500
  let totalPages = 0
  if(totalItems > 0){
    totalPages = ~~(totalItems/pageSize) + 1
  }
  const url = `${closeOutItemsUrl}`;
  const requests = _.range(1, totalPages+1).map((num, _)=>{
    const requestPayLoad = { 
      "aliasSKURetailerGrpID": aliasSKURetailerGrpID, 
      "allwarehouse": isShowAllWarehouse ? 1 : 0, 
      "cartid": cartid, 
      'memberNbr': storeID, 
      'page': num, 
      'rdc': rdc,
      'resultPerPage' : 500,
      "sortorder": sortDir,
      'sortby': sortBy,
      'filterBy': filterString?.filterBy,
    };

    return restUtils.postData(url, requestPayLoad);
  })
  return Promise.all(requests).then((res)=>{
      return res.reduce((acc, cur)=>{
      return acc.concat(cur.data);
    },[]);
  });
}

export const getMomentumItems= (sortBy, sortOrder, sortDepartment, storeId, userId, aliasSKURetailerGrpID, currentCartId, multiStoreDetails, rdc, totalItems)=>{
  const pageSize = 500
  let totalPages = 0
  if(totalItems > 0){
    totalPages = ~~(totalItems/pageSize) + 1
  }
  const url = `${getFavoritesUrl}`;
  const requests = _.range(1, totalPages+1).map((num, _)=>{
    let requestPayLoad = {
      "filterBy": [{name: "Department", valueList: sortDepartment}],
      "userId":userId,
      "rdc": rdc,
      "resultPerPage": pageSize,
      "page": num,
      "sortby":sortBy,
      "sortorder":sortOrder,
      "cartid" : utilities.emptyForNull(parseInt(currentCartId)),
      "storeId" : storeId,
      "aliasSKURetailerGrpID" : aliasSKURetailerGrpID,
      "momentum": '1'
    }
    if(utilities.msExist(multiStoreDetails) > 1 && currentCartId ){
      requestPayLoad = {...requestPayLoad, multiStoreCartId: utilities.emptyForNull(parseInt(currentCartId))}
    }
    return restUtils.postData(url, requestPayLoad);
  })
  return Promise.all(requests).then((res)=>{
      return res.reduce((acc, cur)=>{
      return acc.concat(cur.data);
    },[]);
  });
}

export const getExportItems= (id, rdc, storeID, affiliate, primaryWarehouseNum, groupUrlId, easyAskDic, cartid, totalItems, filterString, isShowAllWarehouse, sortDir, sortBy, multiStoreDetails)=>{
  const pageSize = 500
  let totalPages = 0
  if(totalItems > 0){
    totalPages = ~~(totalItems/pageSize) + 1
  }
  let url = ''
  const requests = _.range(1, totalPages+1).map((num, _)=>{
    if(id == 'discontinuedItem'){
      url = `${productListingUrl}?rdc=${rdc}&storeID=${storeID}&affiliate=${affiliate}&primaryWarehouseNum=${primaryWarehouseNum}&GroupURLId=${groupUrlId}&Discontinued=1&aliasSKURetailerGrpID=&easyAskDic=${easyAskDic}&Page=${num}&ResultsPerPage=${pageSize}&cartid=${cartid}`
    } else if(id == 'newItems'){
      url = `${productListingUrl}?rdc=${rdc}&storeID=${storeID}&affiliate=${affiliate}&primaryWarehouseNum=${primaryWarehouseNum}&GroupURLId=${groupUrlId}&NewItems=NewItemsVal&aliasSKURetailerGrpID=&easyAskDic=${easyAskDic}&Page=${num}&ResultsPerPage=${pageSize}&cartid=${cartid}`
    }
    if(filterString && filterString !== ''){
      url = url + filterString
    }
    if(isShowAllWarehouse){
      url = url + '&allwarehouse=1'
    }
    if(sortBy && sortBy !== ''){
      url = url + `&SortParam=${sortBy}&SortType=${sortDir ? sortDir : 'asc'}`
    }
    if(id == 'newItems' && utilities.msExist(multiStoreDetails) > 1 && cartid){
      url = url + `&multiStoreCartId=${cartid}`
    }
    return restUtils.getData(url);
  })
  return Promise.all(requests).then((res)=>{
      return res.reduce((acc, cur)=>{
      return acc.concat(cur.data);
    },[]);
  });
}

export const excelProductIdentifiers = (product) =>{
  const allList = []

  if(product && (product.MadeInUSAInd && product.MadeInUSAInd !== 'N') || (product.madInUSAInd && product.madInUSAInd !== 'N')){
    allList.push('Made In USA')
  }
  if(product && product.prop65 === 'Y' || product.prop65indicator  === 'Y' || product.prop65Indicator  === 'Y'){
    allList.push('Prop65')
  }
  if(product.Newitem === '1' || product.Newitem === 'True'){
    allList.push('New')
  }
  if(product.DtvInd === '1' || product.DtvInd === 'True'){
    allList.push('Core Item')
  }
  if(product.HazMat === 'Y'){
    allList.push('Hazmat Item')
  }
  if(product.DisconInd === 'D'){
    allList.push('DISCONTINUED')
  }
  if(product.DisconInd === 'T'){
    allList.push('TO BE DISCONTINUED')
  }
  if(product.GreenerOptionInd === '1' || product.GreenerOptionInd === 'True'){
    allList.push('GREENER OPTIONS')
  }
  if(product.bom_ind === 'BOM'){
    allList.push('BOM')
  }
  if(product.AssortmentLevel && product.AssortmentLevel.trim() !== ''){
    const level = `${product.AssortmentLevel.replace(/\D/g,'')}-${assortmentLevels[parseInt(product.AssortmentLevel.replace(/\D/g,''))]}`
    allList.push(level)
  }
  if(product.adv_code){
    allList.push(product.adv_code)
  }
  if(product.import_cd === 'True'){
    allList.push('IM')
  }
  if(product.fom_ind=== '1'){
    allList.push('FOM')
  }
  if(product.wsl_ind === '1'){
    allList.push('WSL')
  }
  if(product.nr_ind === 'True'){
    allList.push('NR')
  }
  if(product.Rebate_IND === 'True'){
    allList.push('REB')
  }

  const productIdentifier  = allList.length > 0  ? allList.join('/ ') : ''

  return productIdentifier

}

export const getReuionItems= (payload, totalItems)=>{
  const pageSize = 500
  let totalPages = 0
  if(totalItems > 0){
    totalPages = ~~(totalItems/pageSize) + 1
  }
  const url = `${REUNION_ITEM_LISTING}`;
  const requests = _.range(1, totalPages+1).map((num, _)=>{
    let requestPayLoad = {...payload};
    requestPayLoad['resultPerPage'] = pageSize
    requestPayLoad['page'] = num

    return restUtils.postData(url, requestPayLoad);
  })
  return Promise.all(requests).then((res)=>{
      return res.reduce((acc, cur)=>{
      return acc.concat(cur.data);
    },[]);
  });
}

export const getOrderStatusItemDetails = (sortParam,sortDirection, orderDetails, storeId, userId, page, aliasSKURetailerGrpID, totalItems, promoNbr, file ) => {

  const sortArr = { sortParam: sortParam, sortType: sortDirection };
  let requestPayLoad = '';
  let totalPages = 1;
  const pageSize = totalItems;
  const requests = 
    orderDetails.orderNumber ?
      requestPayLoad = {
        "storeId": storeId, "userId": userId, 
        "orderNumber": orderDetails.orderNumber,
        "rdcNumber": orderDetails.rdcNumber,
        "orderType": orderDetails.orderType, "status": orderDetails.status, sortBy: sortArr,
        "pageNumber": totalPages,
        "resultsPerPage": pageSize,
        "aliasSKURetailerGrpID": aliasSKURetailerGrpID
      } :
      requestPayLoad =
      {
        "userId": userId,
        "storeId": storeId,
        "status": orderDetails.status,
        "rdcNumber": orderDetails.rdcNumber,
        "invoiceNumber": orderDetails.invoiceNumber,
        "invoiceDateString": orderDetails.invoiceDateString,
        "memberOrderRef": orderDetails.memberOrderRef,
        "orderRegisterNumber": orderDetails.orderRegisterNumber,
        "pageNumber": totalPages,
        "resultsPerPage": pageSize,
        "aliasSKURetailerGrpID": aliasSKURetailerGrpID,
        "orderNumber": orderDetails.orderNumber
      }
    let url = `${ORDER_ITEM_DETAILS}`;

   
    return restUtils.postData(url, requests);
 
  }

  export const getHotDealsItems= (aliasSKURetailerGrpID, cartid, storeID, rdc, totalItems, sortDir,sortBy, filterString)=>{
    const pageSize = 500
    let totalPages = 0
    if(totalItems > 0){
      totalPages = ~~(totalItems/pageSize) + 1
    }
    const url = `${HOT_DEALS_URL}`;
    const requests = _.range(1, totalPages+1).map((num, _)=>{
      const requestPayLoad = { 
        "aliasSKURetailerGrpID": aliasSKURetailerGrpID, 
        "cartid": cartid, 
        'memberNbr': storeID, 
        'page': num, 
        'rdcNum': rdc,
        'resultPerPage' : pageSize.toString(),
        "sortorder": sortDir,
        'sortby': sortBy?sortBy:"Qty",
        'filterBy': filterString?.filterBy? filterString?.filterBy:[],
      };
  
      return restUtils.postData(url, requestPayLoad);
    })
    return Promise.all(requests).then((res)=>{
        return res.reduce((acc, cur)=>{
        return acc.concat(cur.data);
      },[]);
    });
  }

  export const getPlanogramListItems= (storeId, planogramNumber, deptName, categoryName, rdcNo,userId,aliasSKURetailerGrpID,totalItems, cartid)=>{
    const pageSize = 500
    let totalPages = 0
    if(totalItems > 0){
      totalPages = ~~(totalItems/pageSize) + 1
    }    
    const requests = _.range(1, totalPages+1).map((num, _)=>{
      let url = `${PLANOGRAM_LISTING}rdcNbr=${rdcNo}&storeId=${storeId}&planogramNumber=${planogramNumber}&deptName=${deptName}&categoryName=${categoryName}&userId=${userId}&aliasSKURetailerGrpID=${aliasSKURetailerGrpID}&Page=${num}&ResultsPerPage=${pageSize}&cartid=${cartid}`
       return restUtils.getData(url);
    })
    return Promise.all(requests).then((res)=>{
        return res.reduce((acc, cur)=>{
        return acc.concat(cur.data);
      },[]);
    });
  }