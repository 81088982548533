import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchVendors } from '../actions';
import { Accordion, Icon, Grid, Popup, Loader, List } from 'semantic-ui-react';
import { getVendorsFetching, getVendors } from '../reducer';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import utilities from '../../../utils/utilities';
import Filter from '../../common/Filter';
import VendorDetailsPopup from './vendor-details-popup';
import '../vendors.css';
const prepareAlphabets = () => {
  let result = [];
  result.push(
    '0-9'
  );

  for (let i = 65; i < 91; i++) {
    const char = String.fromCharCode(i);
    result.push(
      char
    );
  }

  return result;
};
const filterJson = [
  {
      "name": "Vendor Type",
      "valueList": ['Warehouse', 'DirectShip (Vendor Direct)', 'DirectShip (Call Directly)']
  },
    
  {
     "name": 'Alphabetical',
     "valueList":prepareAlphabets()
  }
]
export class AccordianItem extends React.Component {
  render() {
    const { item, content, appliedFilters } = this.props;
    const { attributeValue = '', productCount, sameVendorProductCount = '' } = item;
    return (
      <p style={{display:'flex'}}>
        <Popup
          wide='very'
          position='bottom center'
          hoverable
          content={content}
          trigger={<i className='far fa-address-card'></i>}
        />
        {parseInt(appliedFilters) === 3 ?
        <a className='linkStyle'>{attributeValue.toUpperCase()} [{productCount}]</a>
        :
        <Link
          to={{
            pathname: `/${parseInt(appliedFilters) === 1 ? 'vendor':'directship/DirectShipItems'}/${attributeValue.replace(/\//g, '%2F')}`+'/'+item.ID+'/'+productCount,
            state: { vendorExists: true, vendorId: item.ID,vendorCount : productCount }
          }}
        >
          {attributeValue.toUpperCase()} [{productCount}]
        </Link>
      }
      </p>
    );
  }
}

class VendorAlphabetFilter extends React.Component {
  state = {
    searchInput: '',
    alphabet: this.props.alphabetical || null,
    vendorsList: [],
    clearFilters: false,
    appliedFilters: {
      'Alphabetical': 'A',
      'Vendor Type': '1'
    },
    orderType: this.props.orderType || 1,
    dsAlphabet : this.props.dsAlphabet || null,
    filters: this.props.filter,
    showFilters:(this.props.getprefer && this.props.getprefer.filterView) 
                    ? this.props.getprefer.filterView === 'E' ? true : false : window.innerWidth > 768 ? true :false,
  };

  componentDidMount() {
    this.props.fetchVendors(1, 'A');
  }
  componentWillReceiveProps(nextProps) {
    const { alphabetical } = nextProps;
    if (alphabetical !== this.props.alphabetical) {
      this.setState({
        alphabet:alphabetical
      });
      //this.props.fetchVendors(this.state.orderType, alphabetical)
    }
    if(this.props.filter !== nextProps.filter) {
      this.setState({
        filters: nextProps.filter
      })
    }
    if(this.props.orderType !== nextProps.orderType) {
      this.setState({
        appliedFilters: {
          'Alphabetical':nextProps.orderType === 1 ? 'A': alphabetical,
          'Vendor Type':nextProps.orderType,
        }
      })
    }
    if(!_.isEmpty(nextProps.vendors)) {
      if (!this.state.showFilters) {
        this.setState({
          showFilters: true
        }, () => {
          for (let key in this.state.appliedFilters) {
            if( document.getElementById('fh-' + key)) {
             document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
            }
          }
          this.setState({
            showFilters: false
          });
        });
      } else {
        for (let key in this.state.appliedFilters) {
          if( document.getElementById('fh-' + key)) {
            document.getElementById('fh-' + key).className = 'filter-head applied-filter-head';
          }
          
        }
      }
    }
  }
  toggleShowFilter = () => {
    this.setState(prevState => {
      return { showFilters: !prevState.showFilters };
    });
  };
  filterCallback = (isAdd, filterKey, filterValue) => {
    if(filterKey === 'Vendor Type') {
      this.setState({
        orderType: parseInt(filterValue),
        appliedFilters: {
          ...this.state.appliedFilters,
          'Vendor Type':filterValue,
          
        }
      })
    }
    else {
      this.setState({
        alphabet: filterValue,
        appliedFilters: {
          ...this.state.appliedFilters,
          'Alphabetical':filterValue          
        }
        
      })
    }
    this.setState({
      clearFilters: false
    },() => {
      if(filterKey === 'Vendor Type') {
        this.props.fetchVendors(this.state.orderType, parseInt(filterValue) === 1 ? 'A': '')
      }
      else {
        this.props.fetchVendors(this.state.orderType, this.state.alphabet)
      }
      
    });
  };

  getAccordionContent = alphabetText => {
    const { vendors, loading } = this.props;
    const selectedAlphabetText = alphabetText === "0-9" ? alphabetText :alphabetText.split('')[0].toUpperCase();
    let vendorList = vendors[selectedAlphabetText];
    let { appliedFilters} = this.state
    if (!vendorList) return null;
    const { length } = vendorList;
    const chunkSize = length >= 3 ? Math.floor(vendorList.length / 3) : 1;
    vendorList = _.chunk(vendorList, chunkSize);
   /* if (loading === 1) {
      return (
        <div className='vendor-loader'>
          <Loader active />
        </div>
      );
    }*/
    return (
      vendorList.length > 0 ?
      <Grid
        className={
          window.innerWidth > 767
            ? ' very relaxed three column'
            : 'very relaxed one column mobileDiv'
        }
      >
        {vendorList.map((items, i) => (
          <Grid.Column key={i}>
            {items.map((item, j) => (
              <AccordianItem
                key={j}
                content={<VendorDetailsPopup {...item} />}
                item={item}
                appliedFilters = {appliedFilters['Vendor Type']}
              />
            ))}
          </Grid.Column>
        ))}
      </Grid>
      : <h2 style={{textAlign:'center'}}>No vendors to Display!</h2>
    );
  };

  elementContainsSearchString = (searchInput, element) =>
    searchInput
      ? element.name.toLowerCase().includes(searchInput.toLowerCase())
      : false;
  filterItems = itemList => {
    let result = [];
    const { searchInput, alphabet } = this.state;
    if (itemList && (searchInput || alphabet)) {
      result = itemList.filter(
        element =>
          element.name.charAt(0).toLowerCase() === alphabet.toLowerCase() ||
          this.elementContainsSearchString(searchInput, element)
      );
    } else {
      result = itemList || [];
    }

    return result;
  };

  showAlphabets = () => {
    this.setState(prevState => {
      return {
        show: !prevState.show
      };
    });
  };

  render() {
    return (
      <>
      <div className='filterorderstatus' style = {{flex: this.state.showFilters? '0 0 20%': '0 0 0'}}>
            <Filter
              clearFilters={this.state.clearFilters}
              filtersApplied={!_.isEmpty(this.state.appliedFilters)}
              appliedFilters={this.state.appliedFilters}
              toggleShowFilter={this.toggleShowFilter}
              showFilters={this.state.showFilters}
              filters={this.state.filters}
              callback={this.filterCallback}
              //isNoProducts={this.state.isNoProducts}
              screenName={'vendorsPlp'}
              //loading={this.state.loading}
            />
          </div>
      <div className='alphabet-filter-wrapper'>
        <div className='alphabet-accordion'>
          <h3 className='letterHeading'>{this.state.alphabet}</h3>
        {this.state.alphabet && this.getAccordionContent(this.state.alphabet)}  
        </div>
      </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: getVendorsFetching(state.vendors),
    vendors: getVendors(state.vendors),
    dsFilter: getVendors(state.vendors)?.dsFilter ? getVendors(state.vendors)?.dsFilter: null,
    filter: getVendors(state.vendors).filter,
    alphabetical: getVendors(state.vendors).alphabetical,
    orderType: getVendors(state.vendors).orderType
  }

};

function mapDispatchToProps(dispatch) {
  return Object.assign(
    { dispatch },
    bindActionCreators(
      {
        fetchVendors
      },
      dispatch
    )
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorAlphabetFilter);
