import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
// import ExcelExport from '../../views/common/ExcelExport';
import './styles.css';
import BreadcrumbComp from '../../components/BreadcrumbComp';
import Categories from '../Categories';
import utilities ,{shouldHideCreditOnRAPage, shouldHideCostForPreference} from '../../utils/utilities';
import { connect } from 'react-redux';
import { CUSTOMER_VIEW, MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { MaskPrice } from '../common/MaskPrice';
import NotFound from '../ErrorPages/404_error_page';
import {saveRegCost} from './actions';
import {bindActionCreators} from 'redux';
import _ from 'lodash';
import history from "../../history";


class AssortmentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regCost : '',
      restrictedItems : [],
      totalMemberCost : [],
      fixtures: [],
	    isRestrictedCart: false,
      pogCartStatus:"",
      PogSubmittedDate:"",
      missingCreditAmount:'',
      allCreditAmount:'',
      memberMissingCost:'',
      allMissingCost:'',
      missingItemCount:'',
      allItemCount:'',
      isMissing:this.props?.location && this.props?.location?.state?.missing ? this.props?.location?.state?.missing: localStorage.getItem("isMissingLocal"),
      isCtbAssortment:this.props.RaStore?.assortment?.isCtbAssortment ? this.props.RaStore?.assortment?.isCtbAssortment : false,
      fromScanning:this.props.RaStore?.assortment?.fromScanning ? this.props.RaStore?.assortment?.fromScanning : false,
      EnforceMissingAll: this.props.RaStore?.assortment?.EnforceMissingAll ? this.props.RaStore?.assortment?.EnforceMissingAll : false
    }
  }
  componentDidMount() {
    console.log(utilities.isMobileTabDevice())
  }
  handleMemberCost = (val) => {
    if(!_.isEmpty(val)) {
      const sumTotal = val.map((item)=>item.orderQty * item.memberCost).reduce((a,b) => a+b)
      this.setState({totalMemberCost: sumTotal})
    }
  }
  handleCost = (missingCreditAmt, allCreditAmt,memberMissingCost,allMissingCost,missingCount,allCount) => {
  this.setState({missingCreditAmount:missingCreditAmt,
                allCreditAmount: allCreditAmt,
                memberMissingCost:memberMissingCost,
                allMissingCost:allMissingCost,
                missingItemCount:missingCount,
                allItemCount:allCount
                })
 }
  getRegCost = (regCost) =>{
    this.setState({regCost : regCost});
    this.props.saveRegCost(regCost);
  }

  getRestrictedItems = (restrictedItems) => {
    this.setState({restrictedItems : restrictedItems})
  }

  getRestrictedCartFlag = (isRestrictedCart = false,PogCartStatus,PogSubmittedDate) => {
    this.setState({ isRestrictedCart:isRestrictedCart, pogCartStatus:PogCartStatus,PogSubmittedDate: PogSubmittedDate  });
  };

  getFixtures = (fixtures = []) => {
     this.setState({ fixtures });
    };

  missingAllOnChange = (e) => {
    const fieldVal = e.target.value
    let val = 1
    if(e.target.value === "missing") {
      val = 0
    } else {
      val = 1
    }
    this.setState({isMissing:val}) 
  }
  goToPreviousPage = () => {
    history.replace({
      pathname: '/Scan/ctb|'+this.props.RaStore.assortment?.subCat,
    })
  };
  render() {
    const isCtbAssortment = this.state.isCtbAssortment
    const { assortment={}} = this.props.RaStore;
    const endDate = assortment.RAMarketEndDate;
    const isFlashsales = assortment.isFlashsales;
    const isReunionAssortment = assortment.isReunionAssortment;
    const seleDept= assortment.department 
                      ? assortment.department.name 
                          ? assortment.department.name 
                          : assortment.department
                      : '';
    const raModel=  assortment.modelNbr;
    let retailAssortments = assortment;
    let AssortmentLinkBack = assortment.backPath ? assortment.backPath 
                                                  : isFlashsales 
                                                    ? '/flashSales'
                                                      :isReunionAssortment
                                                       ?`/reunionAssortments/${seleDept.trim()}`
                                                        : seleDept
                                                          ? isCtbAssortment ? `/ctbAssortments/${seleDept.trim()}` :`/retailAssortments/${seleDept.trim()}`
                                                            : '#';
    let AssortmentLinklast = `/assortment/${retailAssortments.number}`
    const breadcrumbLinks = isCtbAssortment ? [{ link: '/home', text: 'HOME' },
      { link: '/ctbAssortments', text: 'CTB Assortments' }, 
      { link: AssortmentLinkBack, text: seleDept.trim() }, 
      { link: AssortmentLinklast, text: retailAssortments.description + ' ' + raModel }     
    ] : [{ link: '/home', text: 'HOME' },
      { link: AssortmentLinkBack, text: seleDept.trim() }, 
      { link: AssortmentLinklast, text: retailAssortments.description + ' ' + raModel }];
    const breadcrumbLinksReunion = [{ link: '/home', text: 'HOME' },
      { link: '/reunionAssortment', text: 'Reunion Retail Assortments' }, 
      { link: AssortmentLinkBack, text: seleDept.trim() }, 
      { link: AssortmentLinklast, text: retailAssortments.description + ' ' + raModel }]
  
      Number.prototype.roundUsing = function(func, prec){
        var temp = this * Math.pow(10, prec)
        temp = func(temp);
        return temp / Math.pow(10, prec)
      }
      localStorage.setItem("isMissingLocal",this.state.isMissing)
    return (
      <div>
          {!retailAssortments ?
            <NotFound/>
            :
            <Segment id='productDetailInfo' >
                <div className='page-header'>
                  {utilities.checkFlashsaleorAssortment(isFlashsales, isReunionAssortment, seleDept, isCtbAssortment)}
                </div>
                <div id='page_navigation'>
                  {isReunionAssortment ?(<BreadcrumbComp path={breadcrumbLinksReunion}/>
                        ):
                       (<BreadcrumbComp path={breadcrumbLinks}/>)
                    }
                </div>
                <br/>
                {this.state.isRestrictedCart && this.state.isCtbAssortment && this.state.pogCartStatus === "1" && (
                    <p style={{ color: "red", marginBottom: "0px" }}>
                        Not eligible for purchase. This CTB/Category is already in your cart.<br/>                        
                        <small style={{fontStyle: 'italic'}}>If you want a different CTB from this category instead of the one in your cart, remove the existing CTB from cart , and then select the new desired CTB planogram.</small>
                    </p>            
	              )}
                {this.state.isRestrictedCart && this.state.isCtbAssortment && (this.state.pogCartStatus === "2" || this.state.pogCartStatus === "99") && (
                    <p style={{ color: "red", marginBottom: "0px" }}>
                        Not eligible for purchase. This CTB/Category has been previously purchased on {utilities.getmonthYearFromTimeStampShip(this.state.PogSubmittedDate)}.
                    </p>            
	              )}
                <div className='info-text'>
                    <span>{retailAssortments.description}</span>
                    {!this.state.isCtbAssortment && <span className='assortment_itemname'>{raModel}</span>}
                    <span className="assortment_block">
                       <span className='assortmentreunionRight' >POG: &ensp;
                           {assortment.planoNum ? assortment.planoNum :'N/A'}
                           </span>
                           <span >
                           <span>View Assortment: </span>
                           {assortment.planoNum ? 
                           <i className='file pdf icon large planogramexportie' id='export_btns'
                           onClick={() => utilities.openInNewTab(assortment.planoNum?parseInt(assortment.planoNum.length)>=9?
                           utilities.getplanogramPdf(assortment.planoNum):
                           utilities.getImpulsePdf(assortment.planoNum)
                           :'')} />
                           :'N/A'}
                       </span>
                     </span>
                     {this.state.isCtbAssortment && (
                      <>
                      <div className="selectMissingOrAll">
                      <div className="displayFlex">
                        <div className="radiofield">
                          <span>Select:&ensp;</span>
                          {[0, 2].includes(
                          parseInt(assortment?.EnforceMissingAll)
                        ) && (
                          <>
                            <input
                              type="radio"
                              id="missing"
                              defaultChecked={[0, 2].includes(
                                parseInt(this.state.isMissing)
                              )}
                              onChange={this.missingAllOnChange}
                              name="1"
                              value="missing"
                            />
                            <label for="missing">Missing Items Only</label>
                          </>
                        )}                        
                        {parseInt(assortment?.EnforceMissingAll) !== 2 && (
                          <>
                            <input
                              type="radio"
                              onChange={this.missingAllOnChange}
                              defaultChecked={
                                parseInt(this.state.isMissing) === 1 ||
                                parseInt(assortment?.EnforceMissingAll) === 1
                              }
                              id="all"
                              name="1"
                              value="all"
                            />
                            <label for="all">All Items</label>
                          </>
                        )}
                        </div>
                        </div>
                        </div>
                      </>
                     )}                     
                    {window.innerWidth > 768 && (
                      this.state?.fromScanning && !_.isEmpty(this.props.RaStore.assortment?.subCat) ? (
                        <span
                          className="assortments_link"
                          onClick={this.goToPreviousPage}
                        >
                          <u>Back to Assortment Listing</u>
                        </span>
                      ):(
                      <span>
                      <Link to={AssortmentLinkBack} className='assortments_link'>
                        <u>Back to Assortment Listing</u>
                      </Link>
                    </span>
                      )
                    )
                    
                    }
                    {/* <span><ExcelExport className='exportPDF exportPdfAssortments'fileName='Assortments'/></span> */}
                </div>
                <div className='card_fields'>
                  <AssortmentDetails 
                    isFlashsales={isFlashsales}
                    regCost = {this.state.regCost || (this.props.RaStore && this.props.RaStore.regCostAss)}
                    multiStoreDetails={this.props.multiStoreDetails} 
                    endDate = {endDate}
                    assortment={retailAssortments} 
                    viewType={this.props.viewType}  
                    getprefer={this.props.getprefer && this.props.getprefer.customerView 
                                  ? this.props.getprefer.customerView 
                                  : 'c1'
                              } 
                    totalMemberCost = {this.state.totalMemberCost}
                    isCtbAssortment = {this.state.isCtbAssortment}
                    missingCreditAmount = {this.state.missingCreditAmount}
                    allCreditAmount= {this.state.allCreditAmount}
                    memberMissingCost = {this.state.memberMissingCost}
                    allMissingCost = {this.state.allMissingCost}
                    missingItemCount = {this.state.missingItemCount}
                    allItemCount = {this.state.allItemCount}
                    missing = {parseInt(this.state.isMissing)}
                    propsMissingValue = {this.props.RaStore?.assortment?.EnforceMissingAll}
                    racking={
                      this.state.fixtures.length > 0 ? (
                        <Link
                          style={{ paddingLeft: "15px", float: "none" }}
                          to={{
                            pathname: "/fixtures",
                            state: {
                              fixtures: this.state.fixtures,
                              pogNumber: assortment?.planoNum
                                ? assortment.planoNum
                                : "N/A",
                              pogDescription: retailAssortments?.description || "",
                              backLocation: window.location.pathname,
                              missing: String(this.state.isMissing)
                            },
                          }}
                          className="assortments_link"
                        >
                          <u>Racking and POP Details</u>
                        </Link>
                      ) :  <></>
                    }
                  />
                </div>
                {window.innerWidth <= 768 &&(
                   this.state?.fromScanning && !_.isEmpty(this.props.RaStore.assortment?.subCat)  ? (
                    <span
                      className="raitem-assortlink"
                      onClick={this.goToPreviousPage}
                    >
                          <u>Back to Assortment Listing</u>

                    </span>
                  ):(
                    <span className='raitem-assortlink'>
                    <Link to={AssortmentLinkBack} className='assortments_link'>
                      <u>Back to Assortment Listing</u>
                    </Link>
                  </span>
                  )
                )
                   
                }
                <br/>
                {this.state.restrictedItems.length > 0 && 
                  <>
                    <div className='detail_text restricted_message content-wrap'>
                    <p className='no_margin'>
                      <span className='restricted-product'>
                        * The following item(s) are restricted in your county and/or state: 
                      </span> 
                      {this.state.restrictedItems.map((itemNum, index) => {
                       return (           
                         <>              
                          {index > 0 && ','} &nbsp;
                          <span className='restricted_Item'>
                          <Link to={'/product/' + itemNum}>
                            {itemNum}
                          </Link>   
                         </span>
                         </>
                        )
                      })}
                      </p>
                      <span className='restricted-product'>
                      (Your assortment cost will adjust accordingly since you will not be receiving these item(s).)
                      </span>
                    </div>
                    <br /> 
                  </>
                }
                <Categories 
                  getRestrictedCartFlag={this.getRestrictedCartFlag}
                  getFixtures={this.getFixtures}
                  setRegCost = {this.getRegCost}
                  pathName={this.props.location.pathname}
                  assortmentType='RetailAssortments' 
                  assortmentId={retailAssortments.number} 
                  seleDept={seleDept} 
                  seleCatg={retailAssortments.categoryName}
                  isAssortmentRestricted = {retailAssortments.itemRestricted}
                  modelnumber={raModel}
                  RAcost={ this.props.viewType !== CUSTOMER_VIEW ?
                    retailAssortments.RAMarketFlag === 'Y' ? retailAssortments.regCost : this.state.regCost:
                    retailAssortments.srp_cost}
                  RAMarketFlag={retailAssortments.RAMarketFlag}
                  shipOptions={retailAssortments.ShipOptions}
                  setRestrictedItems={this.getRestrictedItems}
                  isFlashsales = {isFlashsales}
                  screenName ={'RetailAssortments'}
                  handleMemberCost = {this.handleMemberCost}
                  handleCost = {this.handleCost}
                  isCtbAssortment = {this.state.isCtbAssortment}
                  planoNum={retailAssortments.planoNum}
                  assortmentName = {retailAssortments.description}
                  isMissing = {parseInt(this.state.isMissing)}
                  serialNumber = {retailAssortments.modelNbr}
                />
                <br/>
                 <div className='detail_text' style={{position:'relative', textAlign:'center'}}>
                  {isFlashsales ? 'Credits will appear as a credit on a future statement.' : 
                    <>*The credit and/or dating will only be applied to the first assortment ordered per  
                     {this.state.isCtbAssortment? ' CTB':' Retail'} Assortment Category. Credits will appear as a credit on a future statement.
                    <br></br>(If you placed an assortment order at the Last {this.state.isCtbAssortment? ' reunion':' market'} with deals and dating, you are not eligible 
                      for additional deals through this ordering site in the particular category you ordered.)</>}
                    {
                      retailAssortments.itemRestricted &&
                        <span className='red_text_details'>
                          <br/><br/>
                          This assortment is restricted. 
                          {retailAssortments.itemRestrictedAlternateNbr!== 0 &&
                            <>&ensp;{retailAssortments.itemRestrictedAlternateNbr} is an alternate assortment.</>
                          }
                        </span>
                    }
                </div>
                <br />
            </Segment>
          }
      </div>
    );
  }
}

const AssortmentDetails = ({isFlashsales, assortment ,viewType,multiStoreDetails,getprefer,regCost, endDate, totalMemberCost,
  isCtbAssortment,missingCreditAmount,allCreditAmount, memberMissingCost,allMissingCost,missingItemCount,allItemCount,missing,propsMissingValue, racking}) => {
  const showCreditAmount = shouldHideCreditOnRAPage(viewType,getprefer);
  const displayMemberCostAndLbl =  shouldHideCostForPreference(viewType,getprefer);
  const savingPercentage = assortment && assortment.savings ? assortment.savings.split('%')[0] : ''
  const creditAmount = savingPercentage && totalMemberCost ? ( (totalMemberCost * parseFloat(savingPercentage)) / 100).toFixed(2) : ''
  const markDownCreditAmount = [0, 2].includes(missing) ? missingCreditAmount: allCreditAmount
  const finalCost = [0, 2].includes(missing) ?  memberMissingCost : regCost
  if(isCtbAssortment) {
    return (
      <>
        <div className='pl-specs ctbCostDisplayBox'>
          <div>
            {(multiStoreDetails.access !== 1  || (multiStoreDetails.viewPOG && multiStoreDetails.PogMbr )) &&
            <>
            { [0, 2].includes(parseInt(propsMissingValue)) && displayMemberCostAndLbl && (
              <span> 
            <span className={`${viewType===MEMBER_VIEW?'pl-card-name':'pl-card-name-customerview'} ${[0, 2].includes(missing) && 'highlight-bg-color'}`}>{utilities.getLabelsByType(viewType,"COST_MISSING_ITEMS")}:</span>
            <span className={`pl-card-field marketCreditField cost_red_color ${[0, 2].includes(missing) && 'highlight-bg-color'}`} id={[0, 2].includes(missing) ?'colorRedText':'colorBlackText'} style={{minWidth: '9.8rem'}}>&ensp;
            <MaskPrice
              getprefer={getprefer}
              viewType={viewType}
              fieldVal ={memberMissingCost}
              field ="Credit Amount"
              />
                &ensp;  
                <span className='itemCount' style={{fontSize:'0.9rem',whiteSpace:'nowrap'}}>({missingItemCount} Items)</span>
             </span> 
             
             </span>  )}
               {displayMemberCostAndLbl && <><span className="ralist-cost">
               {parseInt(propsMissingValue) !== 2 && (<>
                <span className={`pl-card-retailcustomerview ${[1].includes(missing) && 'highlight-bg-color'}`}>{isCtbAssortment ?utilities.getLabelsByType(viewType,"COST_ALL_ITEMS"): utilities.getLabelsByType(viewType,"MEMBER_COST")}:</span>
                <span className={`pl-card-field Assortmentfield ${[1].includes(missing) && 'highlight-bg-color'}`} id={isCtbAssortment && missing===1 ?'colorRedText':'colorBlackText'} style={{minWidth:!isCtbAssortment? '10.7rem':null}}>&ensp; 
                  <MaskPrice
                  getprefer={getprefer}
                  viewType={viewType}
                  fieldVal ={allMissingCost}
                  field='Member Cost'
                  />
                &ensp;{isCtbAssortment && <span className='itemCount'style={{fontSize:'0.9rem',whiteSpace:'nowrap'}}>({allItemCount} Items)</span>}
                </span>  
              </>)}          
              </span>
          </>
            }
            </>
            }
           
          </div>   
          {(multiStoreDetails.access !== 1  || (multiStoreDetails.viewPOG && multiStoreDetails.PogMbr )) && displayMemberCostAndLbl && (
          <div>
          <span>
          <span className={viewType===MEMBER_VIEW?'pl-card-name':'pl-card-name-customerview'}>{utilities.getLabelsByType(viewType,"MARKDOWN_CREDIT_AMOUNT")}:</span>
            <span className={viewType===MEMBER_VIEW?'cost_red_color pl-card-field marketCreditField':'cost_red_color pl-card-field marketCreditField pl-card-valuecustomerview_1'}>&ensp;
            <MaskPrice
              getprefer={getprefer}
              viewType={viewType}
              fieldVal ={markDownCreditAmount}
              field ="Credit Amount"
              />
                &ensp;  
            </span> 
          </span>
          <span className="highlight-bg-color">
          <span className={viewType===MEMBER_VIEW?'pl-card-name':'pl-card-name-customerview'}>{utilities.getLabelsByType(viewType,"FINAL_COST")}:</span>
            <span className={viewType===MEMBER_VIEW?'cost_red_color pl-card-field marketCreditField':'cost_red_color pl-card-field marketCreditField pl-card-valuecustomerview_1'}>&ensp;
            <MaskPrice
              getprefer={getprefer}
              viewType={viewType}
              fieldVal ={finalCost- markDownCreditAmount}
              field ="Credit Amount"
              />
                &ensp;  
            </span> 
          </span>
          {racking}
          </div>
          )}
          <div>
          <span className="ralist-savings">
              {viewType===MEMBER_VIEW && <span>
              <span className='pl-right-card-name SavingsPadding'>Savings:</span>
              <span className='pl-card-field Assortmentfield red_color ' style={{minWidth:'17rem'}}>&ensp;
              {parseInt(assortment.savings) === 0 ? '-': assortment.savings.toString().includes('%')? assortment.savings: assortment.savings+'%'}
                    </span></span>}
            </span>
            <span className="ralist-dating">
              <span className='pl-card-name SavingsPadding'>Dating:</span>
              {(!utilities.isEmptyOrNullString(assortment.orderedDate) || (assortment.overridedAssortmentFromCart > 0  
                && assortment.overridedAssortmentFromCart !== assortment.number)) 
                ? <span className={showCreditAmount ?'pl-card-field Assortmentfield':'pl-card-field-customerview-credithide'}>&ensp;{'N/A'}</span> 
                : <span className={showCreditAmount ?'pl-card-field Assortmentfield':'pl-card-field-customerview-credithide'}>&ensp;
                    {` ${utilities.hyphenForNull(assortment.billingDateTerms)}`} Days
                  </span>
              }
            </span>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className='pl-specs'>
        <div>
          {(multiStoreDetails.access !== 1  || (multiStoreDetails.viewPOG && multiStoreDetails.PogMbr )) &&
          <>
             {displayMemberCostAndLbl && <><span className="ralist-cost">
           <span className='pl-card-retailcustomerview'>{utilities.getLabelsByType(viewType,"MEMBER_COST")}:</span>
              <span className='pl-card-field Assortmentfield'>&ensp; 
                <MaskPrice
                  getprefer={getprefer}
                  viewType={viewType}
                  fieldVal={assortment.RAMarketFlag === 'Y' 
                        ? utilities.emptyStringForZero(assortment.regCost) 
                        : utilities.emptyStringForZero(regCost)
                  }
                  field='Member Cost'
                />
            </span>
            </span></>
          }
          </>
          }
          {viewType === MEMBER_VIEW && <span className="ralist-savings">
            <span className='pl-right-card-name SavingsPadding'>Savings:</span>
            {
            isFlashsales?
            <span className='pl-card-field Assortmentfield red_color'>&ensp;{utilities.hyphenForNull(assortment.savings.toString().includes('%')? assortment.savings: assortment.savings+'%')}</span>
            :
            (!utilities.isEmptyOrNullString(assortment.orderedDate) || (assortment.overridedAssortmentFromCart > 0  
              && assortment.overridedAssortmentFromCart !== assortment.number)) 
                ? <span className='pl-card-field Assortmentfield red_color'>&ensp;{'N/A'}</span> 
                : <span className='pl-card-field Assortmentfield red_color'>&ensp;
                    {utilities.hyphenForNull(assortment.savings.toString().includes('%')? assortment.savings: assortment.savings+'%')}
                  </span>
            }
          </span>}
          <span className="ralist-delivery">
            {assortment.RAMarketFlag !== 'Y' 
              ? <>
                  <span className='pl-card-name'>Delivery:</span>
                  <span className='pl-card-field Assortmentfield'>&ensp;
                    {utilities.hyphenForNull(assortment.delivery)}
                  </span>
                </>
              : ''
            } 
          </span>         
        </div>
        <div>
          <span className="ralist-creditamount">
          {showCreditAmount && 
          <span className={viewType===MEMBER_VIEW?'pl-card-name':'pl-card-name-customerview'}>{utilities.getLabelsByType(viewType,"CREDIT_AMOUNT")}:</span>
            }
          {!utilities.isEmptyOrNullString(assortment.orderedModelNbr) 
            ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                {'Not Eligible for Credit, Previously Purchased ' + assortment.orderedModelNbr + '.'}
              </span> 
            : !utilities.isEmptyOrNullString(assortment.orderedDate) 
              ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                  {'Credit Redeemed '+ assortment.orderedDate + '('+ creditAmount +')'}
                </span>
              : assortment.userQty > 0 
                ? <span className='pl-card-field Assortmentfield credit_msg_color'>&ensp;
                    {'Credit and dating will only apply  to one assortment per retail assortment category.'}
                  </span>
                : viewType === MEMBER_VIEW ?<span className='pl-card-field Assortmentfield'>&ensp;
                    {'$' + utilities.hyphenForNull(creditAmount)}
                  </span>:
                  showCreditAmount && <span className='pl-card-field-customerview Assortmentfield'>&ensp;
                  <MaskPrice
                  getprefer ='c1'
                  fieldVal ={creditAmount}
                  field ="Credit Amount"
                  viewType/>
                   &ensp;  
                  </span>       }
          </span>
          <span className="ralist-dating">
            <span className='pl-card-name SavingsPadding'>Dating:</span>
            {(!utilities.isEmptyOrNullString(assortment.orderedDate) || (assortment.overridedAssortmentFromCart > 0  
              && assortment.overridedAssortmentFromCart !== assortment.number)) 
              ? <span className={showCreditAmount ?'pl-card-field Assortmentfield':'pl-card-field-customerview-credithide'}>&ensp;{'N/A'}</span> 
              : <span className={showCreditAmount ?'pl-card-field Assortmentfield':'pl-card-field-customerview-credithide'}>&ensp;
                  {` ${utilities.hyphenForNull(assortment.billingDateTerms)}`} Days
                </span>
            }
          </span>
          { assortment.categoryName !== "Retail Assortments" ?
          <span className="ralist-offerends">
            <span className='pl-card-name SavingsPadding'>Offer Ends: </span>
              {utilities.isEmptyOrNullString(assortment.exp_dt) && assortment.RAInd === 'Y' 
                ? <span className='pl-card-field Assortmentfield'>&ensp;on Going</span> 
                : utilities.isEmptyOrNullString(assortment.exp_dt) && (assortment.RAInd === 'N') 
                  ? <span className='pl-card-field Assortmentfield'>&ensp;{endDate}</span> 
                  : <span className='pl-card-field Assortmentfield'>&ensp;{assortment.exp_dt}</span>
              }
          </span>
          : 
          <></>
          }
        </div>
      </div>
    </>
  );
  
};

const mapStateToProps = state => {
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    multiStoreDetails : state.SessionReducer.multiStoreDetails,
    getprefer: state.preference.listData,
    RaStore:state.RaReducer
  }
}

const mapDispatchToProps = (dispatch) => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ saveRegCost }, dispatch));
}

export default connect(mapStateToProps, mapDispatchToProps)(AssortmentListing);
