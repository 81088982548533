import React, { Component } from "react";
import "../../common/filters.css";
import { filterValues } from "../../common/constants";
import utilities from "../../../utils/utilities";
import _, { isArray } from "lodash";
import { connect } from "react-redux";

class Filter extends Component {
  constructor(props) {
    super(props);
    let { filters } = props;
    this.state = {
      contentDivVis: filters && Array(filters.length).fill(false),
      filterHeads: this.populateFilterHeads(),
      showFilters: this?.props?.getprefer?.filterView
        ? this.props.getprefer.filterView === "E"
        : window.innerWidth > 768,
    };
  }
  populateFilterHeads = (newProps) => {
    let { filters, appliedFilters, defaultChecked, clearFilters } = newProps
      ? newProps
      : this.props;
    let selectedHeads = Object.keys(appliedFilters);
    return filters.map((filter) => {
      let checkboxes = [];
      if(isArray(filter.valueList)){
        checkboxes = filter.valueList.map(value=>{
          const isDefaultCb =
          defaultChecked &&
          defaultChecked.name === filter.name &&
          defaultChecked.value === value[0];
          const isAppliedCb = appliedFilters?.[filter.name]?.includes(value[0]);
          return {
            key: `${filter.name}-${value[0]}`,
            count: value[1],
            value: value[0],
            disabled: isDefaultCb,
            checked: isDefaultCb || (!clearFilters && isAppliedCb),
          };
        })
      }else{
        let values = Object.keys(filter.valueList);
        checkboxes = values.map((value) => {
          const isDefaultCb =
            defaultChecked &&
            defaultChecked.name === filter.name &&
            defaultChecked.value === value;
          const isAppliedCb = appliedFilters?.[filter.name]?.includes(value);
          return {
            key: `${filter.name}-${value}`,
            count: filter.valueList[value],
            value,
            disabled: isDefaultCb,
            checked: isDefaultCb || (!clearFilters && isAppliedCb),
          };
        });
      }
      return {
        name: filter.name,
        checked:
          (defaultChecked && filter.name === defaultChecked.name) ||
          (!clearFilters && selectedHeads.includes(filter.name)),
        filterCbs: checkboxes,
      };
    });
  };
  getFilterHeads = (filterHeads, contentDivVis) => {
    return (
      <>
        {filterHeads &&
          filterHeads.map((category, index) => {
            let { filterCbs } = category;
            if (_.isEmpty(filterCbs)) return;
            filterCbs = !Array.isArray(filterCbs) ? [filterCbs] : filterCbs;
            return (
              <div
                className="filter-section"
                key={category.name}
                id={"fs-" + category.name.replace(" ", "_")}
              >
                <span
                  className={
                    category.checked
                      ? "filter-head applied-filter-head"
                      : "filter-head"
                  }
                  id={"fh-" + category.name}
                >
                  {category.name}
                  <i
                    className={
                      contentDivVis[index]
                        ? "minus icon toggleIcon"
                        : "plus icon toggleIcon"
                    }
                    data-div-index={index}
                    onClick={this.toggleDiv}
                  ></i>
                </span>
                {contentDivVis[index] && (
                  <div id={"contents-" + index}>
                    {this.getCheckboxes(filterCbs, category)}
                  </div>
                )}
              </div>
            );
          })}
      </>
    );
  };
  getCheckboxes = (filterVals, category) => {
    return (
      <>
        {filterVals &&
          filterVals.map((item) => {
            const checkboxId = `${category.name}_${item.value}`;
            return (
              !utilities.isEmptyOrNullString(item) && (
                <div className="ui checkbox filter-cb-wrap" key={item}>
                  <input
                    type="checkbox"
                    className="filter-checkbox"
                    key={item.value}
                    data-filter-head={category.name}
                    data-filter-value={item.value}
                    name={category.name}
                    onChange={this.handleFilterAction}
                    disabled={item.disabled}
                    value={item.value}
                    checked={!utilities.isUndefinedOrNull(item.checked)}
                    id={checkboxId}
                  />
                  <label>{item.count ? " " + item.count : ""}</label>
                </div>
              )
            );
          })}
      </>
    );
  };
  handleFilterAction = (e) => {
    if (this.props.isLoading) {
      return false;
    }
    let { filterHeads } = this.state;
    const { filterValue, filterHead } = e.target.dataset;
    if (e.target.checked) {
      filterHeads.find(({ name }) => name === filterHead).checked = true;
    } else {
      const checkboxes = filterHeads.find(({ name }) => name === filterHead)
        .filterCbs;
      let checkedCbs = checkboxes.filter((cb) => cb.checked);
      if (checkedCbs.length === 1 && checkedCbs[0].value === filterValue) {
        filterHeads.find(({ name }) => name === filterHead).checked = false;
      }
    }
    this.props.filterCallback(e.target.checked, filterHead, filterValue);
  };
  componentWillReceiveProps(newProps) {
    this.setState({
      filterHeads: this.populateFilterHeads(newProps),
    });
    if (newProps.clearFilters) {
      this.setState({
        contentDivVis: Array(newProps.filters.length).fill(false),
      });
    }
  }

  toggleShowFilter = () => {
    this.setState((prevState) => {
      return { showFilters: !prevState.showFilters };
    });
  };

  toggleDiv = (e) => {
    const index = e.target.dataset.divIndex;
    let { contentDivVis } = this.state;
    contentDivVis[index] = !contentDivVis[index];
    this.setState({ contentDivVis });
  };
  render() {
    const { defaultChecked, appliedFilters, isNoProducts } = this.props;
    const { showFilters, filterHeads, contentDivVis } = this.state;
    let filterStyle =
      isNoProducts && showFilters
        ? appliedFilters
          ? { display: "block" }
          : { display: "none" }
        : isNoProducts
        ? appliedFilters
          ? { display: "block" }
          : { display: "none" }
        : { display: "block" };
    return (
      <>
        <div style={filterStyle}>
          {!showFilters && (
            <i
              className={
                !_.isEmpty(appliedFilters) || !_.isEmpty(defaultChecked)
                  ? "filter icon applied-filter"
                  : "filter icon"
              }
              id="filter_symbol"
              onClick={this.toggleShowFilter}
            >
              <span className="expand-arrow">»</span>
            </i>
          )}
          {showFilters && (
            <div className="assortmentFilters filters">
              <div className="filter-disclaimer">
                *Filter item counts are not changed when filters are applied
              </div>
              <span className="filter-head">
                Refine Search
                <span
                  id="filter_icon"
                  className={
                    !_.isEmpty(appliedFilters) || !_.isEmpty(defaultChecked)
                      ? "applied-filter"
                      : ""
                  }
                  onClick={this.toggleShowFilter}
                >
                  <i className="filter icon" />
                  <span className="shrink-arrow">«</span>
                </span>
              </span>
              {this.getFilterHeads(filterHeads, contentDivVis)}
            </div>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    getprefer: state.preference.listData,
  };
};
export default connect(mapStateToProps)(Filter);
