import React from 'react';
import restUtils from '../../utils/restUtils';
import { Loader, Responsive, Pagination } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import "./AllOrders.css";
import utilities, { shouldHideSUGG_RETAILForPreference, shouldHideCostForPreference }  from '../../utils/utilities';
import ExcelExport from '../common/ExcelExport';
import renderImages from '../../views/common/RenderImages';
import { Link } from 'react-router-dom';
import { directShipViewAllCarts  } from '../../../src/urlConstants';
import InternalServerError from '../../views/ErrorPages/500_error_page';
import history from '../../history';
import ReactToPrint from 'react-to-print';
import { MaskPrice } from '../common/MaskPrice';
import { MEMBER_VIEW } from '../../components/NavBar/menuConstants';
import { getTableRow, getSelect, getTable } from '../common/ComponentUtilities';
import { showItemCount, getPagination } from '../common/CustomComponents';
import  {TierIcon} from '../common/TierIcon';
import _ from 'lodash';

class DirectShipOderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      itemDetails: [],
      loading: false,
      serverError: false,
      poNum: '',
      isMultiPo: false,
      img_txt_to_display:(this.props.getprefer && this.props.getprefer.thumbnailImage && this.props.getprefer.thumbnailImage !=='Y') ? 'Show Images': 'Hide Images',
      orderDetails: this.props.location.state ? this.props.location.state.orderDetails : null,
      firstItem: 0,
      lastItem: 0,
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      isHovered : false,
      isChecked: false
    }
    this.page = 1;
    this.pageSize = 50;
    this.sortParam = 'itemNbr';
    this.sortDirection = 'asc';
    this.orderStatusPath = window.history?.state?.state?.pushUrl || '';
    this.scrollPosition = 0;
    this.handleChecked = this.handleChecked.bind(this);
  }

  componentDidMount() {
    let queryParam = window.location.href.split('?');
    this.pageSize = this.props.getprefer && this.props.getprefer.resultPerPage ? 
    this.props.getprefer.resultPerPage : '50';
    if(queryParam[1]) {
      this.getUrlFilters(queryParam);
    }
    else{
      this.getItemDetails();
    }
  }

  roundNull = (data, field) => {
    const getPrefer = this.props.getprefer &&
    this.props.getprefer.customerView
    ? this.props.getprefer.customerView
    : 'c1';
    if(data){
      return <MaskPrice
        field = {field}
        getprefer={getPrefer}
        viewType={this.props.viewType}
        fieldVal={Number(data).toFixed(2)}
      />
    } else return "-"; 
  }

  showHideImages = evt => {
    let img_txt_to_display =
      evt.target.innerHTML === 'Show Images' ? 'Hide Images' : 'Show Images';
    this.setState({ img_txt_to_display: img_txt_to_display });
  };

  changeSortParam = e => {
    this.page = 1;
    this.sortParam = e.currentTarget.value;
    this.scrollPosition = 0;
    this.getItemDetails();
  };

  changeSortDirection = e => {
    this.page = 1;
    this.sortDirection = e.currentTarget.value;
    this.scrollPosition = 0;
    this.getItemDetails();
  };

  handleTrackingMouseOver = trackingNums => {
    return <ul className={"mouseHover"}>
        {trackingNums && trackingNums.map((num, index)=> {
        return <li className={"mouseHoverText"} key={index}>{num}</li>
      })}
    </ul>
  }

  setScrollPosition = () => {
    this.scrollPosition = window.pageYOffset;
    this.setBackButtonPath();
  }

  getUrlFilters = (queryParam) => {
    let getParams = queryParam[1].split('&');    
    this.sortParam = queryParam[1].includes('&SortParam=') ? queryParam[1].split('SortParam=')[1].split('&')[0] : '';
    this.sortDirection = queryParam[1].includes('SortType=asc') ? 'asc' : 
                          queryParam[1].includes('SortType=desc') ? 'desc' : '';   
    this.scrollPosition = queryParam[1].split('scrollPosition=')[1].split('&')[0];
   let scrollPosition = this.scrollPosition;
    this.setState({
      scrollPosition : scrollPosition
    },() => {
      this.getItemDetails();
    });
  }

  setBackButtonPath = () => {
    let pathName = ''; 
    this.scrollPosition = window.pageYOffset;
    pathName = 'scrollPosition='+this.scrollPosition;
    if (this.sortParam && this.sortParam.length > 0) {
      pathName = pathName + '&SortParam='+this.sortParam;
      if (this.sortDirection && this.sortDirection.length > 0) {
        pathName = pathName + '&SortType='+this.sortDirection;
      } else {
        pathName = pathName + '&SortType=asc';
      }
    }
    let pushUrl  = '?'+pathName;
    window.history.pushState(window.history.state, "", pushUrl);
  }

  goToPreviousPage= () => {
    if(this.orderStatusPath){
      history.push(this.orderStatusPath)
    } else {
      history.goBack();
    }
   }




  showLess = (itemNumber) => {
    let itemDetails = this.state.itemDetails;
    this.state.itemDetails.forEach((order, index) => {     
       if(order.itemNbr === itemNumber)
       itemDetails[index].showLess = true ;        
    });
    this.setState({ itemDetails});
  }

  showMore = (itemNumber) => {
    let itemDetails = this.state.itemDetails;
    this.state.itemDetails.forEach((order, index) => {     
       if(order.itemNbr === itemNumber)
       itemDetails[index].showLess = false ;        
    });
    this.setState({ itemDetails});
  }
  handleChecked(e){
    this.setState(prevState => {
      return { isChecked: !prevState.isChecked };
    });
    this.getItemDetails(!this.state.isChecked);
  }

  getItemDetails = (checked = this.state.isChecked ) => {
    this.setState({loading: true})
    restUtils.getData(`${directShipViewAllCarts}?CartId=${this.state.orderDetails.OrderReferenceCartId}`).then(response=>{
        this.setState({ 
            itemDetails: response.data?.CartDetails?.items,
            loading: false, 
            serverError: false, 
            vendorDetails:  response.data?.VendorDetails
           });
            window.scrollTo(0,this.scrollPosition);
            this.setBackButtonPath();
    }).catch(error=>{
        console.log(error);
        this.setState({ loading: false, serverError: true });
    })
  }

  checkFillRateAndQtyCanShow = () => {
    const orderDetails = this.state.orderDetails;
    return ((orderDetails.displayedStatus === 'CANCELED' && utilities.isEmptyOrNullString(orderDetails.orderNumber)) ||
    orderDetails.displayedStatus === 'ORDER CREATED' || orderDetails.displayedStatus === 'ORDER FILLING'
    || orderDetails.displayedStatus === 'DC ORDER COMPLETE');
  }

  getWarehouseImage = (imageUrl, img_style) => {
    return renderImages.getImage({ src: imageUrl, className: 'orderImage', style: { display: img_style } })
  }

  getWareHouseRows = (rowProps, img_style, order) => {
    const warehouseCols = this.getWareHouseColumns(order, img_style);
    return (
      <>
      {getTableRow(rowProps, warehouseCols)}
      </>
    )
  }

  getWarehouseHeaders = () => {
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const tableHeader = [
      {props:{className:'allOdrHeader', id:'no_border'}, text: ''},
      {props:{className:'OderItemHeader', id:'no_border'}, text: 'Item #'}
    ];    
    tableHeader.push(
      // {props:{className:'allOdrHeader', id:'no_border'}, text: 'ADV Code #'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'UPC #'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Model #'},
      {props:{className:'OderItemHeader', id:'no_border'}, text: 'Item Description'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'DS Cost'},
      {props:{className:'allOdrHeader', id:'no_border'}, text: utilities.getLabelsByType(this.props.viewType,"SUGG_RETAIL", getPrefer)},
      {props:{className:'allOdrHeader', id:'no_border'}, text: 'Ord Qty'}
   
    );
    return(getTableRow({},tableHeader,true))
  }
 

  getWareHouseColumns = (order, img_style) => {
    const tableDataCols = [
      {columnProps:{ className: 't-center', id: 'field_width' }, columnText: this.getWarehouseImage(order.item_nbr, img_style)},
      {columnProps:{ className: 't-left', id: 'field_width' }, columnText:  order?.IsStocked ? order.item_nbr : '-'},
    ];
    tableDataCols.push(
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.Upc) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.Model) },
      { columnProps: { className: 't-left', id: 'field_Desc_width' }, columnText: utilities.hyphenForNull(order.ShortDescription) },
      //{ columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.noteProgram) },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: <MaskPrice
      field = 'Member Cost black'
      getprefer={this.props.getprefer &&
        this.props.getprefer.customerView
        ? this.props.getprefer.customerView
        : 'c1'
      }
      viewType={this.props.viewType}
      fieldVal={order.DsCost }
    /> },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: <MaskPrice
      field = 'Member Cost black'
      getprefer={this.props.getprefer &&
        this.props.getprefer.customerView
        ? this.props.getprefer.customerView
        : 'c1'
      }
      viewType={this.props.viewType}
      fieldVal={order.SrpCost }
    /> },
      { columnProps: { className: 't-center', id: 'field_width' }, columnText: utilities.hyphenForNull(order.itemQty) },

    );
    return tableDataCols;    
  }
 
  
 
  render() {
    const orderDetails = this.state.orderDetails;
    const itemDetails = this.state.itemDetails;
    const isChecked = this.state.isChecked;
    const multiPoSelectMsg = isChecked ? 'Show All Items for the PO' : 'Show All Items for the Invoice';
    const img_style = this.state.img_txt_to_display === 'Hide Images' ? 'block' : 'none';
    const getPrefer = this.props.getprefer &&
      this.props.getprefer.customerView
      ? this.props.getprefer.customerView
      : 'c1';
    const showMemberCostAndLbl =  shouldHideCostForPreference(this.props.viewType, getPrefer);
    return (
      this.state.serverError ? <InternalServerError /> :
        <div className='page-wrap'  ref={node => (this.componentRef = node)}>

          {this.state.loading &&
            <div>
              <Loader active />
            </div>
          }
          
          <div className={window.innerWidth > 1024 ? "displayFlex allOrdersTable orderstatusMargin dsOrderDetailLg" : "allOrdersTableItem"}>
            <Responsive minWidth='1025'> 
            <>
            <div className='editOrderHeaderTwo'>
          <div className='font-style'>
            <p>Direct Ship Order Details</p>
                        <p className='order_count allorderCount ds_allorderCount'>
              <span onClick={this.showHideImages} id='hideShowImages'>{this.state.img_txt_to_display}</span>
              <span id='back_button' onClick={this.goToPreviousPage}>Back to Orders</span>
              <span className='order_count'>
                <ExcelExport 
                  className='excel_left excelHide' 
                  fileName={'DirectShip Order Item Details'}
                  sortParam = {this.sortParam}
                  sortDirection = {this.sortDirection}
                  orderDetails = {this.state.orderDetails}
                  storeId = {this.props.storeId}
                  userId = {this.props.userId}
                  viewType={this.props.viewType}
                  getprefer={
                    this.props.getprefer &&
                    this.props.getprefer.customerView
                    ? this.props.getprefer.customerView
                    : 'c1'
                  }
                  page = {this.page}
                  orderResults={itemDetails} 
                  aliasSKURetailerGrpID={this.props.aliasSKURetailerGrpID}
                  onVariablePricing={this.props.onVariablePricing}
                  checkFillRateAndQtyCanShow={this.checkFillRateAndQtyCanShow}/>
                  <span id='print_img'>
                    <ReactToPrint
                    pageStyle='@page{size:landscape;}'
                    trigger={() => (
                    <i className='print icon ds_print' id='printicon'></i>
                    )}
                    content={() => this.componentRef}
                  /> 
              </span>
            </span>
            </p>
          </div>
          <div className='font-style ds_orderDetails'>
          <p className='itemDetails'>Vendor Name:<span className='order_details'>{utilities.hyphenForNull(orderDetails.VendorName)}</span></p>
            <p className='itemDetails'>PO #:<span className='order_details'>{utilities.naForNull(orderDetails.PoNumber)}</span></p>
            <p className='itemDetails'>Invoice #:<span className='order_details'>{utilities.hyphenForNull(orderDetails.InvoiceNumber)}</span></p>
            <p className='itemDetails'>{utilities.getLabelsByType(this.props.viewType,"ORDER_TOTAL", getPrefer)}:<span className='order_details'>{this.roundNull(orderDetails.OrderValue, 'Member Cost black')}</span></p>
            <p className='itemDetails'>Order Status:<span className='order_details'>{utilities.hyphenForNull(orderDetails.Status)}</span></p>
            <p className='itemDetails'>Order Created Date:<span className='order_details'>{utilities.hyphenForNull(orderDetails.OrderCreatedDate)}</span></p>
             
          </div>
          </div> 
              <div class="eighteen wide column">
              <div style={{position:'sticky', top:'11.7rem', background:'#f2f2f2',paddingBottom:'10px'}}>
                <div className='order-pagination pagination-padding'>
                  <span className='OrderTotalitem'>
                    {/* {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.totalItems} orders */}
                  </span>
                  {/* <span className='paginationAllorders'>        
                  {this.state.totalPages > 0 && (     
                    getPagination(0, this.state.currentPage, this.state.totalPages, this.onPageChange, null, 1)    
                  )}
                  </span> */}
                 
                  <div className='order_count'>          
                    <span>Showing {orderDetails.ItemsCount} Lines</span>                    
                </div>
                </div>
                </div>
                {itemDetails && itemDetails.length > 0 && getTable({class:"ui celled table all-wh-table stickyTableOrderLevelDetails", id:'font-size-orders'},this.getWarehouseHeaders(), itemDetails, this.getWareHouseRows, {className:'wh-row'}, img_style)}                
              </div>
              </>
            </Responsive>
            <Responsive maxWidth='1024'>
            <div className='font-style'>
            <p>Direct Ship Order Details</p>
            <p>Vendor Name:<span className='order_details'>{utilities.naForNull(orderDetails.VendorName)}</span></p>
            <p>PO #:<span className='order_details'>{utilities.naForNull(orderDetails.PoNumber)}</span></p>
            <p>Invoice #:<span className='order_details'>{utilities.hyphenForNull(orderDetails.InvoiceNumber)}</span></p>
            <p>{utilities.getLabelsByType(this.props.viewType,"ORDER_TOTAL", getPrefer)}:<span className='order_details'>{this.roundNull(orderDetails.OrderValue, 'Member Cost black')}</span></p>
            <p>Order Created Date:<span className='order_details'>{utilities.hyphenForNull(orderDetails.OrderCreatedDate)}</span></p>
            <p className='order_count allorderCount'>
              <span onClick={this.showHideImages} id='hideShowImages'>{this.state.img_txt_to_display}</span>
              <span id='back_button' onClick={this.goToPreviousPage}>Back to Orders</span>
            </p>
          </div>
          <div className='portrait-pagination'>
                <div className='OrderTotalitem'>
                  {this.state.firstItem}-{this.state.lastItem} of{' '} {this.state.totalItems} orders
                </div>
                {/* <div className='paginationAllorders'>                
                {this.state.totalPages > 0 && ( 
                  getPagination(0, this.state.currentPage, this.state.totalPages, this.onPageChange, null, 1)                  
                )}
                </div> */}
                <div className='order_count'>Showing {itemDetails && itemDetails.length} Lines
                </div>
            </div>
              {itemDetails && itemDetails.length > 0 && itemDetails.map(order => {
                return <OrderCard 
                        order={order} 
                        img_style={this.state.img_txt_to_display === 'Hide Images' ? 'block' : 'none'}
                        showLess={this.showLess}
                        showMore={this.showMore}
                        checkFillRateAndQtyCanShow = {this.checkFillRateAndQtyCanShow}
                        aliasSKURetailerGrpID = {this.props.aliasSKURetailerGrpID} 
                        getPrefer={this.props.getprefer &&
                          this.props.getprefer.customerView
                          ? this.props.getprefer.customerView
                          : 'c1'}
                        viewType={this.props.viewType}
                        getWarehouseImage = {this.getWarehouseImage}/>
              })}
            </Responsive>
          </div>
        </div>
    )
  }
}

const OrderCard = ({ order, img_style, showLess, showMore, checkFillRateAndQtyCanShow, aliasSKURetailerGrpID, getPrefer, viewType, getWarehouseImage }) => {
  
  return (
    <div className='all-orders-card'>
      <table id='order-card-table'>
        <td className='t-center' id='field_width'>{getWarehouseImage(order.item_nbr, img_style)}</td>
        <tr>
          <td className='order-card-field padding'>Item #</td>
          <td>{utilities.hyphenForNull(order.itemNbr)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>UPC #</td>
          <td>{utilities.hyphenForNull(order.Upc)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Model #</td>
          <td>{utilities.hyphenForNull(order.Model)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Item Description</td>
          <td >{utilities.hyphenForNull(order.ShortDescription)}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>DS Cost</td>
          <td >{<MaskPrice
                field = 'Member Cost black'
                getprefer={getPrefer}
                viewType={viewType}
                fieldVal={order.DsCost }
                />}</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>{utilities.getLabelsByType(viewType,"SUGG_RETAIL", getPrefer)}</td>
          <td >{<MaskPrice
                field = 'Member Cost black'
                getprefer={getPrefer}
                viewType={viewType}
                fieldVal={order.SrpCost }
                /> }</td>
        </tr>
        <tr>
          <td className='order-card-field padding'>Ord Qty</td>
          <td >{utilities.hyphenForNull(order.itemQty)}</td>
        </tr>
              </table>
    </div>
  )
}

const mapStateToProps = state => {
  const storeId = state.SessionReducer.storeId;
  const userName = state.SessionReducer.userName;
  const userId = state.SessionReducer.UserId;
  const viewTypeStorage = localStorage.getItem('viewType');
  return {
    storeId, userName, userId, getprefer:state.preference.listData,
    viewType: viewTypeStorage ? viewTypeStorage : MEMBER_VIEW,
    getprefer:state.preference.listData,
    aliasSKURetailerGrpID:state.SessionReducer.address ? state.SessionReducer.address.aliasSKURetailerGrpID : '',
    onVariablePricing: state.SessionReducer.multiStoreDetails ? state.SessionReducer.multiStoreDetails.onVariablePricing : false,
  };
};

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({}, dispatch)
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DirectShipOderDetails));
