import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import restUtils from '../../utils/restUtils';
import './freight.css';
import _ from 'lodash';
import { Loader,Form } from 'semantic-ui-react';
import utilities from "../../utils/utilities";
import ReactToPrint from 'react-to-print';
import {TRANSPLACE_QUICK_QUOTES} from '../../urlConstants'
class Freight extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            originCode:'',
            destinationCode:'',
            shipmentWeight:'',
            date: utilities.getCurrDateYearFirst(),
            estimateDays:'',
            expectedCost:'',
            isLoading:false
        }
        
    }
    execute = () => {
    this.setState({isLoading: true})
    const payLoad = {
        "quoteRequests": [
            {
                "charges": [],
                "date": this.state.date,
                "lineItems": [
                    {
                        "weight": this.state.shipmentWeight,
                        "weightUOM": "LB",
                        "ltlClass": 77.5
                    }
                ],
                "locations": [
                    {
                        "postalCode": this.state.originCode,
                    },
                    {
                        "postalCode":  this.state.destinationCode,
                    }
                ],
                "mode": "LTL",
                "serviceLevel": "STANDARD"
            }
        ],
        "options": {
            "getBenchmark": false,
            "getLCP": true,
            "returnRateDetail": true,
            "returnRateTotals": true,
            "topCarrierOnly": false,
            "useSRG": true
        }
    }
    restUtils.postData(TRANSPLACE_QUICK_QUOTES, payLoad)
        .then(response => {
        let res = response?.data
        if(res?.data) {
            const num = res.data[0].quoteCost.amount;
            const amount = ((num) => {
                return num.toFixed(Math.max(((num+'').split(".")[1]||"").length, 2));
             })
            this.setState({
                expectedCost:amount(num),
                estimateDays:res.data[0].transit.transitDays,
                isLoading: false
            })
         return 
        }else {
            this.setState({
                expectedCost:'',
                estimateDays:'',
                isLoading: false
            })
            utilities.showToast(`Entered zip codes are invalid`,false);
        }
        })
        .catch(err => {
        console.error(err);
        this.setState({
            expectedCost:'',
            estimateDays:'',
            isLoading: false
        })
        utilities.showToast(`Freight estimator is currently unavailable, please try again later`,false);
        });
    }
    
    handleChangeVal = (event)=>{
        const { dataset, value } = event.target;
        const { field, state } = dataset;
        if (value !== '') {
            if (field === 'weight') {
                const shipWeightExpression = /^\d*\d*$/;
               // const shipWeightExpression = /^\d{0,4}$/;
                if (parseInt(value) === 0 || !shipWeightExpression.test(value)) {
                utilities.showToast(`Shipment Weight must be whole integer`,false);
                return;
                }
                else if(parseInt(value) > 9999) {
                    utilities.showToast(`Max allowed weight is 9999 lbs`,false);
                    return
                }
            }
            else if(field ==='code')  {
                const zipCodeExpression = /^\d{0,5}$/;
                if(!zipCodeExpression.test(value)) { 
                    if(state === 'originCode') {
                        utilities.showToast(`Origin Zip Code must be numeric and 5 digits`,false);
                    }
                    else {
                        utilities.showToast(`Destination must be numeric and 5 digits`,false);
                    }             
                    return;
                }
                event.target.style='' 
                document.getElementById(state).innerHTML=''
            }
        }
          this.setState({
            [state]: value
        })
    }
    handleRequest = () =>{
        const zipCodeExpression = /^\d{5}$/;
        const { originCode, destinationCode, shipmentWeight } = this.state
        if(originCode === '' || destinationCode ==='' || shipmentWeight ==='' ) {
            utilities.showToast(`Enter value in all mandatory fields`,false); 
            return
        }
        else if(!zipCodeExpression.test(originCode)) { 
            utilities.showToast(`Origin Zip Code must be 5 digits`,false);              
            return;
        } else if (!zipCodeExpression.test(destinationCode)) {
            utilities.showToast(`Destination Zip Code must be 5 digits`,false);              
            return;
        }
        this.execute()
    }
    qtyChange =(event) => {
        const { dataset, value } = event.target;
        const { state } = dataset;
        if (value !== '') {
            const zipCodeExpression = /^\d{5}$/;
             if(!zipCodeExpression.test(value)) { 
                event.target.style = 'border: 1px solid red;color:red' 
                if(state === 'originCode') {
                    document.getElementById(state).innerHTML = 'Origin Zip Code must be 5 digits'             
                }else {
                    document.getElementById(state).innerHTML = 'Destination Zip Code must be 5 digits'              
                }                
                return;
            }
            else {
                event.target.style=''
                document.getElementById(state).innerHTML='' 
            }
          }
    }
    resetForm = () => {
        this.setState({
            originCode:'',
            destinationCode:'',
            shipmentWeight:'',
            expectedCost:'',
            estimateDays:''
        })
    }
    render() {
        const { originCode, destinationCode, shipmentWeight,date,estimateDays, expectedCost } = this.state
        return (
            <>
            <div className='freightContainer' ref={node => (this.componentRef = node)}>
            {this.state.isLoading && <Loader active />}
                <h2>LTL Freight Estimator</h2>
                
                <Form className='freightForm'>
                <h4><span className='red_color'>NOTE</span> :  LTL freight estimates are not available for offshore and International shipments/retailers.</h4>
                    <div className='formSetRow_1'>                    
                        <Form.Field>
                            <div>
                                <label>Date</label>
                                <input value={date} disabled/>
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <div>
                            <label><span>*</span>Origin Zip Code</label>
                            <input value={originCode|| ''}  name="orgincode" data-state = 'originCode' onBlur={this.qtyChange} onChange={this.handleChangeVal}  data-field='code'/>
                            </div>
                            <p id="originCode" className='frightError'></p>
                        </Form.Field>
                        <Form.Field>
                            <div>
                            <label><span>*</span>Destination Zip Code</label>
                            <input value={destinationCode|| ''} name="destinationCode"  data-state = 'destinationCode'onBlur={this.qtyChange} onChange={this.handleChangeVal} data-field='code'/>
                            </div>
                            <p id="destinationCode" className='frightError'></p>
                        </Form.Field>
                        <Form.Field>
                            <div>
                        <label style={{marginLeft:'-1.85rem'}}><span>*</span>Shipment Weight</label>
                        <div className='shipWeightWrap'><input  style = {{textAlign:'right'}} onChange={this.handleChangeVal} data-field='weight' data-state = 'shipmentWeight' value={shipmentWeight|| ''}/> <span style={{paddingLeft:'0.2rem',fontWeight:'bold', lineHeight:1}}> lbs.</span></div>
                        </div>
                        </Form.Field>
                    <Form.Field>
                        <div>
                        <label>Freight Class</label>
                        <input value={77.5} disabled/>
                        </div>
                    </Form.Field>
                    </div>
                    <div className='formSetRow_2'>
                        <Form.Field>
                            <label>Estimated Freight Cost*:</label>
                            <span style={{color:'#0e0ebc',paddingLeft:'0.35rem'}}>{expectedCost !== '' ? <><i class="fas fa-dollar-sign" style={{color:'#0e0ebc'}}></i>{expectedCost}</> :'-'}</span>                            
                        </Form.Field>
                        <Form.Field>
                            <label>Estimated Day(s) in Transit:</label>
                            <span style={{color:'#0e0ebc',paddingLeft:'0.35rem'}}>{estimateDays !== '' ? estimateDays : '-'}</span>
                        </Form.Field>
                        <p><span>*</span>This is a cost estimate, and does not form the basis of any contract or commitment. Actual cost is subject to change based on actual freight class used, carrier selected at time of shipping, and/or quantity of product being shipped. </p>
                    </div>
                    <div className='actionBtnWrap'>
                        <button onClick={this.resetForm}>Clear</button>
                        <button onClick={this.handleRequest}>Request</button>
                        <ReactToPrint
                        pageStyle='@page{size:landscape;}'
                        trigger={() => (
                            <button>Print to PDF</button>
                        )}
                        content={() => this.componentRef} />
                       {/* <button onClick={()=>window.print()}>Print to PDF</button> */}
                    </div>
                </Form>
            </div>
         </>
        )
       
    }
}
const mapDispatchToProps = (dispatch)=>{ 
    return Object.assign({dispatch},
      bindActionCreators({},dispatch)) 
  } 
  const mapStateToProps = (state) => { 
    return {

    }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Freight);